import { objectToCamelCase, objectToSnakeCase } from './caseHelpers';
import { handleApiError } from './handleApiError';
import axios from 'axios';

/**
 * A simple Axios wrapper that takes care of
 * 1. Converting params from camelCase to snake_case when making a request
 * 2. Converting response from snake_case attributes to camelCase
 * 3. Gracefully handling arrors and returning a `status` attributes so that
 *    errors can be handled in consisteny way.
 */
export async function apiRequest({ path, params = {}, body = {}, method = 'get', blobRespone = false }) {
  const pathNoSlash = path.endsWith('/') ? path.slice(0, -1) : path;
  if (!['get', 'post', 'put', 'delete'].includes(method)) {
    throw `Invalid HTTP verb "${method}" in "${path}" HTTP request`;
  }
  try {
    let apiResponse;
    if (body instanceof FormData) {
      apiResponse = await axios[method](pathNoSlash, body);
    } else {
      const requestOptions = body;
      requestOptions['params'] = params;
      const snakeRequestOptions = objectToSnakeCase(requestOptions);
      if (blobRespone) snakeRequestOptions['responseType'] = 'blob';
      apiResponse = await axios[method](pathNoSlash, snakeRequestOptions);
    }
    if (blobRespone) return apiResponse;
    return objectToCamelCase(apiResponse);
  } catch (error) {
    return handleApiError(error);
  }
}
