<template>
  <div>
    <b-card class="mb-4">
      <b-card-body>
        <b-row class="mb-3">
          <b-col>
            <h6 class="mb-1">ID</h6>
            {{ user.id }}
          </b-col>
          <b-col>
            <h6 class="mb-1">Username</h6>
            {{ user.username }}
          </b-col>
          <b-col>
            <h6 class="mb-1">Daily Limit</h6>
            {{ user.dailyLimit }}
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <h6 class="mb-1">Name</h6>
            {{ user.name }}
          </b-col>
          <b-col>
            <h6 class="mb-1">Email</h6>
            {{ user.email }}
          </b-col>
          <b-col>
            <h6 class="mb-1">Phone</h6>
            {{ user.phone }}
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <h6 class="mb-1">RC Username</h6>
            {{ user.rcUsername }}
          </b-col>
          <b-col>
            <h6 class="mb-1">RC Password</h6>
            {{ user.rcPassword }}
          </b-col>
          <b-col>
            <h6 class="mb-1">RC Extension</h6>
            {{ user.rcExtension }}
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col md="4">
            <h6 class="mb-1">Updated At</h6>
            {{ user.updatedAt | formatDateTime }}
          </b-col>
          <b-col md="4">
            <h6 class="mb-1">Created At</h6>
            {{ user.createdAt | formatDateTime }}
          </b-col>
          <b-col md="4">
            <h6 class="mb-1">Roles</h6>
            {{ user.roles.map(x => x.name).join(', ') }}
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col md="4">
            <h6 class="mb-1">Country</h6>
            {{ user.country }}
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card class="mb-4" title="Source Permissions" v-if="$store.getters['auth/tokenCan']('admin')">
      <b-card-body>
        <table class="table" v-if="user.sources.length">
          <thead>
            <tr>
              <th>Source</th>
              <th>Assigned at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in user.sources" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.pivot.createdAt | formatDateTime }}</td>
              <td><b-btn size="xs" variant="danger" @click.prevent="remove(item.id)">Remove</b-btn></td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-warning" v-else>No source permissions has been granted yet</div>

        <form @submit.prevent="submit()">
          <b-row class="mb-3">
            <b-col>
              <multiselect
                v-model="userSources"
                label="label"
                track-by="value"
                placeholder="Select source"
                :options="sources"
                :allow-empty="true"
                :multiple="true"
                :searchable="false"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
              >
                <span slot="noResult">No sources found</span>
              </multiselect>
            </b-col>
            <b-col>
              <button class="btn btn-primary" type="submit">Add source</button>
            </b-col>
          </b-row>
        </form>
      </b-card-body>
    </b-card>

    <b-card class="mb-4" title="Extra Permissions" v-if="$store.getters['auth/tokenCan']('admin')">
      <b-card-body>
        <table class="table" v-if="user.extraPermissions.length">
          <thead>
            <tr>
              <th>Permission</th>
              <th>Description</th>
              <th>Assigned at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in user.extraPermissions" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.pivot.createdAt | formatDateTime }}</td>
              <td><b-btn size="xs" variant="danger" @click.prevent="removePermission(item.id)">Remove</b-btn></td>
            </tr>
          </tbody>
        </table>
        <div class="alert alert-warning" v-else>No extra permissions has been granted yet</div>

        <form @submit.prevent="submitPermission()">
          <b-row class="mb-3">
            <b-col>
              <multiselect
                v-model="userPermissions"
                label="label"
                track-by="value"
                placeholder="Select permission"
                :options="permissions"
                :allow-empty="true"
                :multiple="true"
                :searchable="false"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
              >
                <span slot="noResult">No permissions found</span>
              </multiselect>
            </b-col>
            <b-col>
              <button class="btn btn-primary" type="submit">Add permission</button>
            </b-col>
          </b-row>
        </form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';

export default {
  name: 'UserDetails',
  components: {
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      sources: [],
      userSources: [],
      permissions: [],
      userPermissions: [],
    };
  },
  methods: {
    async fetchSources() {
      const response = await ApiClient.sources.get({});
      if (response.status == 200) {
        this.sources = response.data.data.map(u => ({ value: u.id, label: u.name }));
      }
    },
    async fetchPermissions() {
      const response = await ApiClient.permissions.get({});
      if (response.status == 200) {
        this.permissions = response.data.data.map(u => ({ value: u.id, label: u.description }));
      }
    },    
    async submit() {
      for (let item of this.userSources) {
        let data = { source_id: item.value };
        await ApiClient.users.sources.post({ userId: this.user.id, body: data });
      }
      this.userSources = [];
      this.$emit('refresh');
    },
    async submitPermission() {
      for (let item of this.userPermissions) {
        let data = { permission_id: item.value };
        await ApiClient.users.permissions.post({ userId: this.user.id, body: data });
      }
      this.userPermissions = [];
      this.$emit('refresh');
    },
    async remove(id) {
      await ApiClient.users.sources.delete({ userId: this.user.id, id:id });
      this.$emit('refresh');
    },
    async removePermission(id) {
      await ApiClient.users.permissions.delete({ userId: this.user.id, id:id });
      this.$emit('refresh');
    },
  },
  async mounted() {
    this.fetchSources();
    this.fetchPermissions();
    //console.log(this.form);
  },
};
</script>

<style lang="scss" scoped>
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.pre {
  white-space: pre-wrap;
}
</style>
