<template>
  <div>
    <h5>Followups Report</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit">
        <b-form-radio v-model="periodType" name="periodType" value="list">Time period</b-form-radio>
        <b-select class="custom-select ml-3" v-if="periodType == 'list'" v-model="timePeriod" :options="timeOptions" />
        <span class="ml-3">OR</span>
        <b-form-radio v-model="periodType" name="periodType" value="custom" class="ml-3">Custom date range</b-form-radio>
        <flat-pickr class="form-control ml-3" v-if="periodType != 'list'" v-model="selectedDates" :config="fltConfig" />


        <button class="btn btn-primary ml-3">Show Report</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <span v-if="empty">No records found</span>

    <b-card class="mb-4" v-if="!loading && items.length">
      <b-table striped hover :items="items" :fields="fields" caption-top>
        <template #table-caption>
          <span v-if="form.createdStart">Created from {{ form.createdStart }} till {{ form.createdEnd }}</span>
        </template>
        <template #cell(userId)="data">
          <b-link v-if="data.value" :to="userLink(data.value)" target="_blank">{{ data.item.user ? data.item.user.name : '' }}</b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';

export default {
  name: 'ReportFollowups',
  data() {
    return {
      loading: false,
      empty: false,
      items: [],
      totals: {},
      periodType: 'list',
      timePeriod: 1,
      selectedDates: null,
      form: {
        createdStart: null,
        createdEnd: null,
      },
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
      },
      timeOptions: [
        { text: 'Current month', value: 1 },
        { text: 'Last month', value: 2 },
        { text: 'Current quarter', value: 3 },
        { text: 'Last quarter', value: 4 },
        { text: 'Current year', value: 5 },
        { text: 'All time', value: 6 },
      ],
      fields: [
        {
          key: 'userId',
          label: 'User',
          sortable: false,
        },
        {
          key: 'leadsDone',
          label: 'Leads FU done',
          sortable: true,
        },
        {
          key: 'leadsExpired',
          label: 'Leads FU expired',
          sortable: true,
        },
        {
          key: 'leadsTotal',
          label: 'Leads FU total',
          sortable: true,
        },
        {
          key: 'quotesDone',
          label: 'Quotes FU done',
          sortable: true,
        },
        {
          key: 'quotesExpired',
          label: 'Quotes FU expired',
          sortable: true,
        },
        {
          key: 'quotesTotal',
          label: 'Quotes FU total',
          sortable: true,
        },
        {
          key: 'ordersDone',
          label: 'Orders FU done',
          sortable: true,
        },
        {
          key: 'ordersExpired',
          label: 'Orders FU expired',
          sortable: true,
        },
        {
          key: 'ordersTotal',
          label: 'Orders FU total',
          sortable: true,
        },
        {
          key: 'totalDone',
          label: 'Total FU done',
          sortable: true,
        },
        {
          key: 'totalExpired',
          label: 'Total FU expired',
          sortable: true,
        },
        {
          key: 'totalTotal',
          label: 'Total',
          sortable: true,
        },
      ],
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
    flatPickr,
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.fetchFollowupsReport();
    },
    
    async fetchFollowupsReport() {
      this.prepareDates();
      this.loading = true;
      const response = await ApiClient.reports.leadsFollowups({ params: this.form });
      this.loading = false;
      this.items = response.data.data;
      this.empty = !this.items.length;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    prepareDates() {
      let parts = [];
      if (this.periodType == 'list') {
        if (this.timePeriod == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDates) return;
        parts = this.selectedDates.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }

      this.form.createdStart = parts[0];
      this.form.createdEnd = parts[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger a {
  color: inherit;
}
</style>
