export const balancePaymentMethods = [
  { value: 1, label: 'Cash' },
  { value: 2, label: 'Certified Funds' },
  { value: 3, label: 'Company Check' },
  { value: 4, label: 'Comchek' },
  { value: 5, label: 'ACH' },
];

export default {
  data() {
    return {
      balancePaymentMethods,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      return balancePaymentMethods.find(pt => pt.value == value).label;
    },
  },
};
