<template>
  <div>
    <div v-if="customer.id">
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ customer.contact ? 'Customer #' + customer.id + ' - ' + customer.contact.firstName : 'Customer #' + customer.id }}</h1>
      </div>

      <ul class="nav nav-pills mb-4">
        <TabLink label="View" route-name="CustomerDetails" :id="customer.id" />
        <TabLink label="Edit" route-name="CustomerEdit" :id="customer.id" />
        <TabLink label="Orders" route-name="CustomerOrders" :id="customer.id" />
        <TabLink label="History" route-name="CustomerHistory" :id="customer.id" />
      </ul>

      <router-view :customer="customer" @save="updateLocalState" />
    </div>
    <LoadingIndicator v-else centered />
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import TabLink from '@/components/shared/TabLink.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'Customer',
  components: { LoadingIndicator, TabLink },
  mixins: [permissionsMixin],
  data() {
    return {
      deleting: null,
      customer: {},
    };
  },
  methods: {
    updateLocalState(customer) {
      this.customer = customer;
    },
    async fetch() {
      const { id } = this.$route.params;
      const apiResponse = await ApiClient.customers.get({ id });
      this.updateLocalState(apiResponse.data.data);
    },
  },
  async mounted() {
    await this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
