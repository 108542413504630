<template>
  <div class="card mb-4">
    <h5 class="card-header">Orders</h5>
    <div class="card-body">
      <LoadingIndicator v-if="ordersLoading" centered />

      <table class="table" v-if="!ordersLoading && items.length">
        <thead class="table">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Pickup Date</th>
            <th scope="col">Customer</th>
            <th scope="col">Vehicles</th>
            <th scope="col">Route</th>
            <th scope="col">Customer Pay</th>
            <th scope="col">User</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lqo in items" :key="lqo.id">
            <td class="px-1">
              <router-link :to="{ name: 'OrderDetails', params: { id: lqo.id } }">#{{ lqo.id }}</router-link>
            </td>
            <td>{{ lqo.pickupDate | formatDate }}</td>
            <td>
              <div v-if="lqo.customer.contact.firstName || lqo.customer.contact.lastName">{{ lqo.customer.contact.firstName }} {{ lqo.customer.contact.lastName }}</div>
              <div v-if="lqo.customer.contact.company">{{ lqo.customer.contact.company }}</div>
            </td>
            <td>
              <div v-for="vehicle in lqo.vehicles" :key="vehicle.id">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</div>
            </td>
            <td>
              <LeadlikeTableLocation :location="lqo.pickupLocation" />
              <span class="mr-1 fas fa-arrow-right"></span>
              <LeadlikeTableLocation :location="lqo.deliveryLocation" />
            </td>
            <td>{{ lqo.customerPay }}</td>
            <td>{{ lqo.user ? lqo.user.name : '-' }}</td>
            <td>{{ lqo.leadType.title }}</td>
            <td>{{ lqo.leadStatus.title }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import LeadlikeTableLocation from '@/components/shared/LeadlikeTableLocation';

export default {
  name: 'CustomerOrders',
  data() {
    return {
      ordersLoading: false,
      items: [],
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
    LeadlikeTableLocation,
  },
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    this.fetchCustomerOrders();
  },
  methods: {
    async fetchCustomerOrders() {
      this.ordersLoading = true;
      const response = await ApiClient.customers.orders({ id: this.customer.id });
      this.items = response.data.data;
      this.ordersLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
