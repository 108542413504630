<template>
  <div class="form-group" v-if="isHidden == false">
    <label :for="id">{{ label + (rules ? '*' : '') || id }}</label>
    <ValidationProvider :name="label" :rules="rules" v-slot="{ errors }">
      <select :value="value" @input="$emit('input', $event.target.value)" :class="{ 'form-control': true, 'is-invalid': errors[0] }">
        <option v-if="emptyOption" value=""></option>
        <option v-for="option in options" :value="option.value" :key="option.key || option.value">
          {{ option.label }}
        </option>
      </select>
      <span>{{ errors[0] }}</span>
    </ValidationProvider>
    <span v-if="hasErrors" class="invalid-feedback">
      <div v-for="error in errors" :key="error">{{ error }}</div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    emptyOption: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasErrors() {
      return !!this.errors.length;
    },
  },
};
</script>

<style lang="scss" scoped></style>
