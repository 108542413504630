<template>
  <div>
    <div class="nav-responsive-lg mb-2">
      <b-nav tabs>
        <b-nav-item :active="getTabActive('/preferences/settings')" to="/preferences/settings"> <i class="fas fa-cog mr-2"></i> Settings </b-nav-item>
        <b-nav-item :active="getTabActive('/preferences/account-info')" to="/preferences/account-info"> <i class="fas fa-info mr-2"></i> Account Info </b-nav-item>
        <b-nav-item :active="getTabActive('/preferences/external-forms')" to="/preferences/external-forms">
          <i class="fas fa-external-link-alt mr-2"></i> External Forms
        </b-nav-item>
        <b-nav-item :active="getTabActive('/preferences/automated-emails')" to="/preferences/automated-emails"> <i class="fas fa-envelope mr-2"></i> Automated Emails </b-nav-item>
        <b-nav-item :active="getTabActive('/preferences/automated-forms')" to="/preferences/automated-forms"> <i class="fas fa-scroll mr-2"></i> Automated Forms </b-nav-item>
      </b-nav>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { tabActive } from '@/mixins/methods';

export default {
  name: 'PreferencesTabs',
  mixins: [tabActive],
};
</script>

<style></style>
