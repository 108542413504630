import LayoutCentered from '@/layout/LayoutCentered';
import CustomerList from '@/components/pages/customers/CustomerList';
import Customer from '@/components/pages/customers/Customer';
//import CustomerNew from '@/components/pages/customers/CustomerNew';
import CustomerEdit from '@/components/pages/customers/CustomerEdit';
import CustomerDetails from '@/components/pages/customers/CustomerDetails';
import CustomerHistory from '@/components/pages/customers/CustomerHistory';
import CustomerOrders from '@/components/pages/customers/CustomerOrders';

export default [
  {
    path: '/customers',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'CustomersList',
        component: CustomerList,
      },
      //{ path: 'new', name: 'CustomerNew', component: CustomerNew },
      {
        path: 'view/:id',
        component: Customer,
        children: [
          {
            path: '',
            name: 'CustomerDetails',
            component: CustomerDetails,
          },
          {
            path: 'edit',
            name: 'CustomerEdit',
            component: CustomerEdit,
          },
          {
            path: 'orders',
            name: 'CustomerOrders',
            component: CustomerOrders,
          },
          {
            path: 'history',
            name: 'CustomerHistory',
            component: CustomerHistory,
          },
        ],
      },
    ],
  },
];
