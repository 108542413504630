<template>
  <div>
    <div class="card mb-4">
      <h5 class="card-header">Attachments</h5>
      <div class="card-body">
        <AttachmentsList :leadlike-id="leadQuoteOrder.id" />
      </div>
    </div>
  </div>
</template>

<script>
import AttachmentsList from '@/components/shared/AttachmentsList';

export default {
  name: 'OrderAttachments',
  components: {
    AttachmentsList,
  },
  props: {
    leadQuoteOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
    };
  },
};
</script>
