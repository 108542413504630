<template>
  <a :href="`${googleSearchLinkBase}${vehicle.year}%20${vehicle.make}%20${vehicle.model}`" target="_blank">
    <span class="mr-1">
      <b>{{ vehicle.year }}</b> {{ capitalize(vehicle.make) }} {{ capitalize(vehicle.model) }}
    </span>
    <span>
      <b> ({{ capitalize(vehicleType) }} - {{ capitalize(vehicleCondition) }})</b>
    </span>
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LeadsCardVehicleMake',
  computed: {
    ...mapState({
      googleSearchLinkBase: state => state.leads.googleSearchLinkBase,
    }),
    vehicleType() {
      return this.vehicle.vehicleType?.name;
    },
    vehicleCondition() {
      return this.vehicle.isInoperable ? 'inoperable' : 'operable';
    },
  },
  props: {
    vehicle: {
      type: Object,
      default: () => ({
        year: '-',
        make: '-',
        model: '-',
        vehicleType: { name: '-' },
        isInoperable: 1,
      }),
    },
  },
  methods: {
    capitalize(str) {
      return str ? str.replace(/(^|\s)\S/g, l => l.toUpperCase()) : '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
