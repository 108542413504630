import { format, parseISO } from 'date-fns';

function parseAndFormatDate(date, pattern) {
  if (date) {
    return format(parseISO(date), pattern);
  } else {
    return 'Not set';
  }
}

export default {
  install(Vue) {
    Vue.filter('capitalize', text => {
      return text
        .split(' ')
        .map(item => `${item.charAt(0).toUpperCase() + item.slice(1)}`)
        .join(' ');
    });
    Vue.filter('formatDate', date => {
      return parseAndFormatDate(date, 'do MMM yyyy');
    });
    Vue.filter('formatDateTime', date => {
      return parseAndFormatDate(date, "MMM do yyyy 'at' HH:mm");
    });
    Vue.filter('formatMoney', value => {
      return value ? '$' + value : '-';
    });
    Vue.filter('formatPhone', value => {
      let m = value.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (m) {
        return (m[1] ? '+1 ' : '') + '(' + m[2] + ') ' + m[3] + '-' + m[4];
      }
      return value;
    });
  },
};
