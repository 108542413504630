export default {
  data() {
    return {
      validationErrors: {
        required: '{{name}} is a required.',
        numeric: '{{name}} must have only numeric characters.',
        sameAs: '{{name}} must be identical.',
        email: 'Email must be true format.',
        minLength: '{{name}} must have at least {{param}} letters.',
        maxLength: '{{name}} must have at greatest {{param}} letters.',
        minValue: '{{name}} should be greater than {{param}}.',
        maxValue: '{{name}} should be less than {{param}}.',
        integer: '{{name}} should be a number.',
        decimal: '{{name}} should be a number or decimal.',
        //custom
        onlyDecimal: '{{name}} have at greatest {{param}} digits and 2 decimal digits.',
      },
    };
  },
  methods: {
    getValidationErrorMessage(key, name, param) {
      const { validationErrors } = this;
      const errorMessage = validationErrors[key].replace('{{name}}', name).replace('{{param}}', param);

      return errorMessage;
    },
  },
};
