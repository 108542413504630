<template>
  <div class="tasks-list">
    <LoadingIndicator v-if="loading" centered />
    <div v-if="!loading">
      <div class="d-flex">
        <button class="btn btn-secondary btn-sm mb-2" v-if="hasDeleted" @click="showDeleted = !showDeleted">
          {{ showDeleted ? 'Hide deleted' : 'Show deleted' }}
        </button>
      </div>
      <table v-if="tasks.length > 0" class="table">
        <thead>
          <tr>
            <th>Done</th>
            <th>Task</th>
            <th>Created</th>
            <th>Due</th>
            <th>Done at</th>
            <th>Last activity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in visibleTasks" :key="item.id" :class="className(item)">
            <td><b-form-checkbox @change="toggleDone(item)" :checked="item.doneAt ? true : false" :disabled="!!task.deletedAt" /></td>
            <td>
              <span class="note">{{ item.note }}</span>
            </td>
            <td nowrap>{{ item.createdAt | formatDateTime }}</td>
            <td nowrap>{{ item.dueTime | formatDateTime }}</td>
            <td nowrap>{{ item.doneAt | formatDateTime }}</td>
            <td nowrap>{{ item.user ? item.user.name : 'System' }}</td>
            <td>
              <i v-if="!item.deletedAt" @click="editTask(item)" class="action-button far fa-edit mr-2"></i>
              <i v-if="!item.deletedAt" @click="deleteTask(item)" class="action-button far fa-trash-alt"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-info">No tasks have been added yet.</div>
    </div>
    <b-form class="mt-4">
      <b-form-group label="When task is due?">
        <flat-pickr class="form-control" v-model="task.dueTime" :config="fltConfig" />
      </b-form-group>
      <b-form-group label="Task">
        <b-textarea v-model="task.note" :rows="2" :max-rows="3" :disabled="saving" class="form-control" />
      </b-form-group>

      <LoadingIndicator v-if="saving" /><b-btn variant="primary" :disabled="saving" @click="saveTask">{{ task.id ? 'Update' : 'Create' }} task</b-btn>
    </b-form>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import flatPickr from 'vue-flatpickr-component';

export default {
  props: {
    leadlikeId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    LoadingIndicator,
    flatPickr,
  },
  computed: {
    visibleTasks() {
      return this.tasks.filter(task => this.showDeleted || !task.deletedAt);
    },
    hasDeleted() {
      return this.tasks.some(task => task.deletedAt);
    },
    tasksDue() {
      return this.tasks.filter(task => task.isPastDue && !task.doneAt && !task.deletedAt);
    },
  },
  data() {
    return {
      tasks: [],
      showDeleted: false,
      task: {
        id: null,
        isDone: 0,
        dueTime: null,
        note: null,
      },
      fltConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        allowInput: true,
      },
      loading: false,
      saving: false,
      deleting: false,
    };
  },
  methods: {
    className(task) {
      if (task.isPastDue && !task.doneAt) return 'text-danger';
      if (task.doneAt) return 'task-done';
      return '';
    },
    async toggleDone(task) {
      const response = await ApiClient.tasks.toggleDone({ id: task.id });
      this.updateTask(response.data.data);
    },
    resetTask() {
      this.task = {
        id: null,
        isDone: 0,
        dueTime: null,
        note: null,
      };
    },
    updateTask(task) {
      this.tasks = this.tasks.map(n => (n.id == task.id ? Object.assign({}, n, task) : n));
      this.$emit('tasksDue', this.tasksDue);
    },
    editTask(task) {
      this.task = Object.assign({}, task);
    },
    async addTask() {
      this.saving = true;
      const response = await ApiClient.leads.tasks.post({
        leadId: this.leadlikeId,
        body: this.task,
      });
      this.saving = false;
      if (response.status === 200) {
        const newTask = response.data.data;
        this.tasks.push(newTask);
        this.resetTask();
      }
    },
    async saveTask() {
      if (!this.task.id) {
        this.addTask();
        return;
      }
      this.saving = true;
      const response = await ApiClient.tasks.put({
        id: this.task.id,
        body: this.task,
      });
      this.saving = false;
      if (response.status === 200) {
        const newTask = response.data.data;
        this.updateTask(newTask);
        this.resetTask();
      }
    },
    async deleteTask(task) {
      this.deleting = true;
      await ApiClient.tasks.delete({
        id: task.id,
      });
      this.deleting = false;
      task.deletedAt = new Date();
      this.updateTask(task);
    },
  },
  watch: {
    leadlikeId: {
      immediate: true,
      async handler(leadId) {
        this.loading = true;
        const response = await ApiClient.leads.tasks.get({ leadId });
        this.loading = false;
        this.tasks = response.data.data;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  white-space: pre-wrap;
}
.task-done {
  color: #a3a4a6;
}
.task-done .note {
  text-decoration: line-through;
}
</style>
