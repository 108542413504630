<template>
  <div>
    <ul class="nav nav-pills mb-4">
      <li class="nav-item"><b-link :to="{ name: 'MarketRates'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRates' }">Market Rates</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'ActiveRoutes'}" :class="{ 'nav-link': true, active: this.$route.name === 'ActiveRoutes' }">Active routes</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'MarketRatesMap'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRatesMap' }">Location map</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'Calculator'}" :class="{ 'nav-link': true, active: this.$route.name === 'Calculator' }">Calculator</b-link></li>
    </ul>

    <div class="card mb-4">
      <b-form inline @submit.prevent="submit" class="px-4 pt-3">
        Pickup: <LocationPicker v-model="pickupLocation" class="ml-1 mr-4" />
        Delivery: <LocationPicker v-model="deliveryLocation" class="ml-1 mr-4" />
        Vehicle Type: <b-select class="mr-4 ml-1" size="sm" v-model="vehicleTypeId" :options="vehicleTypes" /> 
        <button class="btn btn-primary ml-3 btn-sm">Calculate</button>
      </b-form>

      <div class="card-body">
        <LoadingIndicator v-if="loading2" centered />
        <div v-if="result">
          <p>Distance: <b>{{result.miles}}</b> miles</p>
          <table class="table table-bordered">
            <thead>
              <tr><th>Standard</th><th>Expedited</th><th>Enclosed</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>{{result.prices.standard}}</td>
                <td>{{result.prices.expedited }}</td>
                <td>{{result.prices.standard + parseInt(result.transportType.enclosed)}}</td>
              </tr>
            </tbody>
          </table>

          <div v-if="$store.getters['auth/tokenCan']('admin')" class="mt-3">
            <h4>Quote Breakdown:</h4>
            <div v-for="(arr, propertyName) in result.debug" :key="propertyName">
              <b>{{propertyName}}</b>
              <ul class="mb-1">
                <li v-for="item in arr" :key="item[0]">
                  {{item[0]}}: {{item[2] || null}} <span>({{item[1]}})</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$store.getters['auth/tokenCan']('admin')" class="card-body">
        <LoadingIndicator v-if="loading" centered />
        <div v-if="result">
          <p>Distance: <b>{{result2.miles}}</b> miles</p>
          <table class="table table-bordered">
            <thead>
              <tr><th>Standard</th><th>Expedited</th><th>Enclosed</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>{{result2.prices.standard}}</td>
                <td>{{result2.prices.expedited }}</td>
                <td>{{result2.prices.standard + parseInt(result2.transportType.enclosed)}}</td>
              </tr>
            </tbody>
          </table>

          <div v-if="$store.getters['auth/tokenCan']('admin')" class="mt-3">
            <h4>Quote Breakdown:</h4>
            <div v-for="(arr, propertyName) in result2.debug" :key="propertyName">
              <b>{{propertyName}}</b>
              <ul class="mb-1">
                <li v-for="item in arr" :key="item[0]">
                  {{item[0]}}: {{item[2] || null}} <span>({{item[1]}})</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import LocationPicker from '@/components/shared/LocationPicker';

export default {
  name: 'Calculator',
  data() {
    return {
      loading: false,
      loading2: false,
      result: null,
      result2: null,
      pickupLocation: '',
      deliveryLocation: '',
      vehicleTypeId: null,
      vehicleTypes: [
        { text: 'Small Car', value: 3 },
        { text: 'Small SUV', value: 21 },
        { text: 'Medium Pickup', value: 8 },
        { text: 'Mini-van', value: 29 },
        { text: 'Midsize Car', value: 2 },
        { text: 'Midsize SUV', value: 1 },
        { text: 'Large Pickup', value: 9 },
        { text: 'Hypercar', value: 25 },
        { text: 'Motorcycle', value: 18 },
        { text: 'Large Car', value: 4 },
        { text: 'Large SUV', value: 22 },
        { text: 'Dually Pickup', value: 12 },
        { text: 'Fullsize van', value: 11 },
        { text: 'Sports Car', value: 20 },
        { text: 'Travel Trailer', value: 24 },
        { text: 'Boat Trailer', value: 23 },
        { text: 'Golf Cart', value: 19 },
      ],
    };
  },
  components: {
    LoadingIndicator,
    LocationPicker,
  },
  methods: {
    async submit(e) {
      e.preventDefault();      
      this.fetch();
    },
    async fetch() {
      if (!this.pickupLocation || !this.deliveryLocation) return;
      this.loading = true;
      const response = await ApiClient.calculations.get({
        params: { pickupLocation: this.pickupLocation, deliveryLocation: this.deliveryLocation, vehicleTypeId: this.vehicleTypeId },
      });
      this.result = response.data.data.result;
      console.log(this.result);
      this.loading = false;

      this.loading2 = true;
      try {
        const response2 = await ApiClient.calculations.v2({
          params: { pickupLocation: this.pickupLocation, deliveryLocation: this.deliveryLocation, vehicleTypeId: this.vehicleTypeId },
        });
        this.result2 = response2.data.data.result;
      }
      catch (error) {
        console.error(error);
      }
      this.loading2 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.zip {
  width: 60px;
}
</style>