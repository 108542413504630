export const tabActive = {
  methods: {
    getTabActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },
  },
};

export const tableFields = {
  methods: {
    getTableFields(fields) {
      return fields.map(item => {
        const isActions = item.key === 'actions';
        return {
          sortable: isActions ? false : true,
          tdClass: isActions ? 'text-nowrap align-middle text-center' : 'align-middle',
          label: isActions ? '' : undefined,
          ...item,
        };
      });
    },
  },
};

export const confirmModal = {
  methods: {
    handleOpenConfirmModal(
      desc = 'Are you sure ?',
      title = 'Confirm',
      okVariant = 'primary',
      okTitle = 'YES',
      cancelTitle = 'NO',
      size = 'md',
      buttonSize = 'md',
      headerClass = '',
      footerClass = '',
      hideHeaderClose = true,
      centered = true
    ) {
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(desc, {
            title,
            size,
            buttonSize,
            okVariant,
            okTitle,
            cancelTitle,
            headerClass,
            footerClass,
            hideHeaderClose,
            centered,
          })
          .then(value => {
            resolve(value);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};
