<template>
  <div>
    <b>{{ pickupTitle }}</b>
    <div>{{ pickupDate }}</div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    first: {
      type: String,
      default: null,
    },
    last: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    pickupTitle() {
      return this.last ? 'Pickup Window:' : 'Single Day Pickup:';
    },
    pickupDate() {
      if (this.last == null) {
        if (moment(this.first).format('YYYY') == moment().year()) {
          return moment(this.first).format('MMM Do');
        }
        return moment(this.first).format('MMM Do, YYYY');
      } else {
        if (moment(this.last).format('YYYY') == moment().format('YYYY')) {
          if (moment(this.first).format('MM/YYYY') == moment(this.last).format('MM/YYYY')) {
            return moment(this.first).format('MMM DD') + ' - ' + moment(this.last).format('Do');
          } else {
            return moment(this.first).format('MMM DD') + ' - ' + moment(this.last).format('MMM Do');
          }
        } else {
          if (moment(this.first).format('MM/YYYY') == moment(this.last).format('MM/YYYY')) {
            return moment(this.first).format('MMM DD') + ' - ' + moment(this.last).format('Do, YYYY');
          } else {
            return moment(this.first).format('MMM DD') + ' - ' + moment(this.last).format('MMM Do, YYYY');
          }
        }
      }
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
  },
};
</script>

<style lang="scss" scoped></style>
