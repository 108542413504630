<template>
  <div>
    <div v-if="lead.carrierId" class="callout callout-danger mb-3">
      You are editing an order that has been dispatched! Making significant changes to the dispatch sheet without approval of the Carrier will invalidate your contract.
    </div>
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit()">
        <FormFieldsLead
          :form-fields="lead"
          :errors="errors"
          @input="updateLocalStateLead"
          render-pricing-form
          render-pickup-contact-form
          render-delivery-contact-form
          render-shipping-form
          :show-customer-search="true"
        />
        <button :disabled="submitDisabled" class="btn btn-primary" type="submit" v-if="!lead.deletedAt">Save</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FormFieldsLead from '@/components/shared/formFields/FormFieldsLead';
import { ApiClient } from '@/api/ApiClient';

export default {
  name: 'LeadlikeEdit',
  data() {
    return {
      lead: {},
      errors: {},
      submitDisabled: false,
      allowLeave: false,
    };
  },
  props: {
    initialLeadQuoteOrder: {
      type: Object,
      required: true,
    },
    leadlikeType: {
      type: String,
      default: 'Order',
    },
  },
  components: { FormFieldsLead },
  computed: {
    isQuote() {
      return this.lead.leadTypeId == 2;
    },
    isOrder() {
      return this.lead.leadTypeId == 3;
    },
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.updateLeadOnApi();
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    async updateLeadOnApi() {
      const requestLeadParams = this.lead;
      if (this.submitDisabled) return;
      this.submitDisabled = true;
      const response = await ApiClient.leads.put({ id: this.lead.id, body: requestLeadParams });
      this.submitDisabled = false;
      if (response.status === 200) {
        this.errors = {};
        const updatedLead = response.data.data;
        this.$emit('save', updatedLead);
        this.allowLeave = true;
        if (this.isQuote) {
          this.$router.push({ name: 'QuoteDetails', params: { id: this.lead.id } });
        } else if (this.isOrder) {
          this.$router.push({ name: 'OrderDetails', params: { id: this.lead.id } });
        }
      } else if (response.status === 422) {
        this.errors = response.data.errors;
      }
    },
    updateLocalStateLead(lead) {
      this.lead = lead;
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      });
    },
  },
  async mounted() {
    this.lead = this.initialLeadQuoteOrder;
  },
  beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.initialLeadQuoteOrder) !== JSON.stringify(this.lead) && !this.allowLeave) {
      const answer = window.confirm('Do you really want to leave? There might be unsaved changes!');
      if (!answer) return false;
    }
    next();
  },
};
</script>
