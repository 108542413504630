<template>
  <div>
    <b-btn
      variant="primary"
      class="mb-4"
      pill
      :to="{
        path: '/accounts/create',
        query: { type: $route.path === '/accounts/carriers' ? 'carriers' : 'customers' },
      }"
    >
      <span class="mr-1 fas fa-plus"></span>
      {{ $route.path === '/accounts/carriers' ? 'New Carrier' : 'New Shipper' }}
    </b-btn>

    <div class="nav-responsive-lg mb-2">
      <b-nav tabs>
        <b-nav-item :active="getTabActive('/accounts/shippers')" to="/accounts/shippers"> <i class="fas fa-users mr-2"></i> Shippers </b-nav-item>
        <b-nav-item :active="getTabActive('/accounts/carriers')" to="/accounts/carriers"> <i class="fas fa-dolly mr-2"></i> Carriers </b-nav-item>
      </b-nav>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { tabActive } from '@/mixins/methods';

export default {
  name: 'AccountsTabs',
  mixins: [tabActive],
};
</script>

<style></style>
