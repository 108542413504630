<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="d-flex align-items-center mb-1">
          <div class="search-wrapper mr-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fas fa-search"></span>
                </div>
              </div>
              <input class="form-control" v-model="search" @input="startSearch" placeholder="Search..." />
            </div>
          </div>
          <b-form-checkbox class="mb-0" v-if="search" v-model="searchArchived" @input="startSearch" :unchecked-value="0" :value="1">Include archived</b-form-checkbox>
          <b-button variant="outline-secondary" size="sm" class="ml-2" @click="showFilters = !showFilters"><span class="fas fa-filter"></span></b-button>
        </div>

        <div v-if="showFilters" class="mb-3 p-3">
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label>Created After</label>
                <input class="form-control" v-model="createdAfterFilter" type="date" />
              </div>
              <div class="mb-3">
                <label>Created Before</label>
                <input class="form-control" v-model="createdBeforeFilter" type="date" />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label>Move Date After</label>
                <input class="form-control" v-model="moveDateAfterFilter" type="date" />
              </div>
              <div class="mb-3">
                <label>Move Date Before</label>
                <input class="form-control" v-model="moveDateBeforeFilter" type="date" />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label>State From</label>
                <b-form-select v-model="stateFromFilter" :options="stateOptions"></b-form-select>
              </div>
              <div class="mb-3">
                <label>State To</label>
                <b-form-select v-model="stateToFilter" :options="stateOptions"></b-form-select>
              </div>
            </div>
          </div>
          <b-button variant="secondary" size="sm" class="mx-2" @click="clearFilters">Clear</b-button>
          <b-button variant="secondary" size="sm" class="mx-2" @click="startFilter">Filter</b-button>
        </div>

        <b-nav tabs class="tabs-alt mb-4" v-if="searchStats">
          <b-nav-item active
            >Leads <b-badge variant="primary">{{ searchStats.countLeads }}</b-badge></b-nav-item
          >
          <b-nav-item :to="{ name: 'QuoteList', query: { search: search, searchArchived: searchArchived } }"
            >Quotes <b-badge variant="outline-success">{{ searchStats.countQuotes }}</b-badge>
          </b-nav-item>
          <b-nav-item :to="{ name: 'OrderList', query: { search: search, searchArchived: searchArchived } }"
            >Orders <b-badge variant="outline-success">{{ searchStats.countOrders }}</b-badge>
          </b-nav-item>
        </b-nav>
      </div>

      <div class="d-flex align-items-start">
        <div class="mr-3" v-if="$route.query.hasFollowup">
          Filter by Date:
          <b-select class="custom-select mr-3" size="sm" v-model="followupFilter" @input="setFollowupFilter" :options="followupFilterOptions" />
        </div>
        <div class="d-flex flex-column">
          <div class="priorities">
            <div><span class="mr-1">Priority Filter:</span> {{ priorityFilter }}</div>
            <b-button v-b-tooltip.hover title="All leads" class="all btn-prior" @click="setPriorityFilter(null)"></b-button>
            <b-button v-b-tooltip.hover title="No Priority" class="nopriority btn-prior" @click="setPriorityFilter(0)"></b-button>
            <b-button v-b-tooltip.hover title="Hot" class="hot btn-prior" @click="setPriorityFilter(1)"></b-button>
            <b-button v-b-tooltip.hover title="Future" class="future btn-prior" @click="setPriorityFilter(2)"></b-button>
            <b-button v-b-tooltip.hover title="Booked" class="booked btn-prior" @click="setPriorityFilter(3)"></b-button>
            <b-button v-b-tooltip.hover title="Bad" class="bad btn-prior" @click="setPriorityFilter(4)"></b-button>
          </div>
          <div class="filters text-left w-100 mt-3">
            <b-dropdown id="dropdown-form" size="sm" ref="dropdown" class="mt-1">
              <template #button-content>
                Filter:
                <i class="ml-1 fa fa-star" v-if="filters.includes('g')"></i>
                <i class="ml-1 fa fa-bold" v-if="filters.includes('b')"></i>
                <i class="ml-1 fa fa-fire" v-if="filters.includes('h')"></i>
                <i class="ml-1 fa fa-truck" v-if="filters.includes('t')"></i>
                <span v-if="!filters.length">None</span>
              </template>
              <b-dropdown-form>
                <div class="mb-3 custom-control custom-checkbox">
                  <input @click="setFilter('g')" class="custom-control-input" id="filter-google" type="checkbox" :checked="getFilter('g')" />
                  <label for="filter-google" class="custom-control-label">
                    <div><i class="fa fa-star"></i> Google</div>
                  </label>
                </div>
                <div class="mb-3 custom-control custom-checkbox">
                  <input @click="setFilter('b')" class="custom-control-input" id="filter-bing" type="checkbox" :checked="getFilter('b')" />
                  <label for="filter-bing" class="custom-control-label">
                    <div><i class="fa fa-bold"></i> Bing</div>
                  </label>
                </div>
                <div class="mb-3 custom-control custom-checkbox">
                  <input @click="setFilter('h')" class="custom-control-input" id="filter-hot" type="checkbox" :checked="getFilter('h')" />
                  <label for="filter-hot" class="custom-control-label">
                    <div><i class="fa fa-fire"></i> Hot Route</div>
                  </label>
                </div>
                <div class="custom-control custom-checkbox">
                  <input @click="setFilter('t')" class="custom-control-input" id="filter-truck" type="checkbox" :checked="getFilter('t')" />
                  <label for="filter-truck" class="custom-control-label">
                    <div><i class="fa fa-truck"></i> Trucker added</div>
                  </label>
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="handlePageChange" :is-disabled="isLoading" class="mb-2" />
    <LoadingSkeleton :count="3" :loaded="!isLoading">
      <div class="custom-card-list">
        <span v-if="leads && leads.length > 0">
          <LeadsCard
            v-for="lead in leads"
            :can-edit="canEdit(lead)"
            :key="lead.id"
            :lead="lead"
            :email-templates="emailTemplatesBtns"
            :sms-templates="smsTemplatesBtns"
            @handleBookedClick="handleBookedClick($event)"
            @handleBadClick="handleBadClick($event)"
            @edit="editLeadId = lead.id"
            @sms="type => ((smsType = type), (smsLead = lead))"
            @email="type => ((emailType = type), (emailLead = lead))"
            @followup="type => handleFollowup(lead.id, type)"
            @route="handleRouteClicked(lead)"
          />
        </span>
        <EmptyState v-if="!leads || !leads.length" message="No leads matching search criteria" />

        <LeadsCardEdit v-if="editLeadId" :lead-id="editLeadId" :force-convert="forceCovertLead" @close="editLeadId = null" />
        <LeadsCardEmail v-if="emailLead" :preselected="emailType" :lead="emailLead" @close="(emailLead = null), (emailType = null)" />
        <LeadsCardSms v-if="smsLead" :preselected="smsType" :lead="smsLead" @close="(smsLead = null), (smsType = null)" />
        <DatePickerModal v-if="followupLeadId" @close="followupLeadId = null" @input="val => setFollowup(followupLeadId, val)" />
        <RouteMapModal id="cardRouteModal" :route="routeInfo" />

        <ConfirmationModal v-if="bookedClick" @confirm="convertToOrder" @cancel="bookedClick = false" label="Do you want to convert this lead to order?" />
        <WhyRedModal v-if="badClick" :leadId="badClick" :on-close="() => (badClick = false)" />
      </div>
    </LoadingSkeleton>
    <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="handlePageChange" :is-disabled="isLoading" class="mt-2" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RouteMapModal from '@/components/shared/route/RouteMapModal';
import EmptyState from '@/components/shared/EmptyState';
import LeadsCardEdit from './LeadsCardEdit';
import LeadsCardEmail from './LeadsCardEmail';
import DatePickerModal from '@/components/shared/DatePickerModal';
import LeadsCardSms from './LeadsCardSms';
import LeadsCard from './LeadsCard';
import moment from 'moment';
import { ApiClient } from '@/api/ApiClient';
import LoadingSkeleton from '@/components/shared/LoadingSkeleton';
import PageLinks from '@/components/shared/pagination/PageLinks';
import { debounce } from 'debounce';
import { objectToCamelCase } from '@/api/caseHelpers';
import ConfirmationModal from '@/components/shared/ConfirmationModal';
import WhyRedModal from '@/components/shared/WhyRedModal';
import statesMixin from '@/mixins/states';
import permissionsMixin from '/src/mixins/permissions';

const sounds = {
  blop: new Audio(`${process.env.VUE_APP_NEXUS_URL}/audio/blop.mp3`),
  woosh: new Audio(`${process.env.VUE_APP_NEXUS_URL}/audio/woosh.mp3`),
};

const updateSearchUrlParamDebounced = debounce(function (router, search) {
  router.push({ query: search });
}, 800);

export default {
  name: 'Leads',
  metaInfo: {
    title: 'Leads',
  },
  components: {
    LoadingSkeleton,
    PageLinks,
    RouteMapModal,
    LeadsCardEdit,
    LeadsCardEmail,
    LeadsCardSms,
    LeadsCard,
    EmptyState,
    DatePickerModal,
    ConfirmationModal,
    WhyRedModal,
  },
  mixins: [permissionsMixin,statesMixin],
  data() {
    return {
      search: '',
      searchArchived: false,
      editLeadId: null,
      emailLead: null,
      smsLead: null,
      smsType: null,
      emailType: null,
      followupLeadId: null,
      bookedClick: false,
      badClick: false,
      forceCovertLead: false,
      routeInfo: { id: null, origin: null, destination: null, infoHtml: null },
      followupFilter: '1',
      followupFilterOptions: [
        { text: 'All', value: '1' },
        { text: 'Future', value: '2' },
        { text: 'Today', value: '3' },
        { text: 'Expired', value: '4' },
      ],
      mentionableUsers: [],
      showFilters : false,
      moveDateBeforeFilter: null,
      moveDateAfterFilter: null,
      createdBeforeFilter: null,
      createdAfterFilter: null,
      stateFromFilter: null,
      stateToFilter: null,
    };
  },
  computed: {
    ...mapState({
      leads: state => state.leads.items,
      hasNextPage: state => state.leads.hasNextPage,
      isLoading: state => state.leads.isLoading,
      searchStats: state => state.leads.searchStats,
      perPage: state => state.app.perPage,
      user: state => state.auth.user,
    }),
    currentPage() {
      const { page = 1 } = this.$route.query;
      return parseInt(page);
    },
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
    }),
    emailTemplates() {
      return this.$store.getters['leads/getEmailTemplates'](1);
    },
    emailTemplatesBtns() {
      return this.emailTemplates.filter(x => !['lead.email.price', 'lead.email.custom'].some(y => y == x.name));
    },
    smsTemplates() {
      return this.$store.getters['leads/getSmsTemplates'](1);
    },
    smsTemplatesBtns() {
      return this.smsTemplates.filter(x => !['lead.text.price', 'lead.text.followup', 'lead.text.custom'].some(y => y == x.name));
    },
    channelName() {
      return this.user.dashboard ? 'leads.live' : 'leads';
    },
    priorityFilter() {
      let priority = this.$route.query.priority;
      if (priority === undefined) return 'All leads';
      if (priority === 0) return 'No Priority';
      if (priority === 1) return 'Hot';
      if (priority === 2) return 'Future';
      if (priority === 3) return 'Booked';
      if (priority === 4) return 'Bad';
      return 'All leads';
    },
    filters() {
      return this.$route.query.filter ? this.$route.query.filter.split(',') : [];
    },
    showUnclaimed() {
      const { userId } = this.$route.query;
      return userId === '' ? 1 : 0;
    },
    stateOptions() {
      return this.states.map(x =>{return {text: x.label, value: x.value}});
    },
  },
  watch: {
    perPage: {
      handler: function () {
        const { page } = this.$route.query;
        if (page != 1) {
          this.$router.push({ query: { page: 1 } });
        } else {
          this.loadLeads();
        }
      },
    },
    $route: {
      immediate: true,
      handler: function () {
        const { search, searchArchived, hasFollowup, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
        this.search = search;
        this.searchArchived = searchArchived ? 1 : 0;
        this.followupFilter = hasFollowup;
        this.moveDateAfterFilter = moveDateAfter || null;
        this.moveDateBeforeFilter = moveDateBefore || null;
        this.createdAfterFilter = createdAfter || null;
        this.createdBeforeFilter = createdBefore || null;
        this.stateFromFilter = stateFrom || null;
        this.stateToFilter = stateTo || null;

        if (this.$route.name == 'LeadsArchived') {
          this.searchArchived = 1;
        }
        this.loadLeads();
      },
    },
  },
  methods: {
    canEdit(lead) {
      return this.$store.getters['auth/isMyUser'](lead.userId) || this.$store.getters['auth/tokenCan']('leads:edit') || (lead.leadReclaimsCount > 0);
    },
    setScroll() {
      var scroll = document.getElementsByClassName('leadcard')[0].scrollHeight;
      window.scroll({
        top: window.scrollY + scroll + 28,
      });
    },
    convertToOrder() {
      this.forceCovertLead = true;
      this.editLeadId = this.bookedClick;
      this.bookedClick = false;
    },
    startSearch() {
      updateSearchUrlParamDebounced(this.$router, { search: this.search, searchArchived: this.searchArchived });
    },
    setFollowupFilter() {
      let query = Object.assign({}, this.$route.query, { hasFollowup: this.followupFilter });
      this.$router.push({ query });
    },
    setPriorityFilter(priority) {
      let query = Object.assign({}, this.$route.query, { priority });
      if (priority === null) delete query.priority;
      this.$router.push({ query });
    },
    setFilter(filter) {
      let currFilters = this.filters;
      if (currFilters.includes(filter)) {
        var index = currFilters.indexOf(filter);
        if (index !== -1) {
          currFilters.splice(index, 1);
        }
      } else {
        currFilters.push(filter);
      }

      let query = Object.assign({}, this.$route.query, { filter: currFilters.toString() });
      if (!currFilters.length) delete query.filter;
      this.$router.push({ query });
    },
    getFilter(filter) {
      if (this.filters.includes(filter)) {
        return filter;
      }
      return false;
    },
    handlePageChange(page) {
      let query = Object.assign({}, this.$route.query, { page });
      this.$router.push({ query });
    },
    loadLeads() {
      const { page = 1, search, searchArchived, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
      let opts = {
        page,
        perPage: this.perPage,
        search,
        searchArchived,
        leadTypeId: 1,
        moveDateBefore, 
        moveDateAfter,
        createdBefore,
        createdAfter,
        stateFrom,
        stateTo,
      };

      if (this.$route.name == 'LeadsArchived') opts.isArchived = 1;
      if (this.$route.query.userId !== undefined) opts.userId = this.$route.query.userId;
      if (this.$route.query.priority !== undefined) opts.priority = this.$route.query.priority;
      if (this.$route.query.filter !== undefined) opts.filter = this.$route.query.filter;
      if (this.$route.query.leadList) opts.leadList = this.$route.query.leadList;
      if (this.$route.query.hasFollowup !== undefined) {
        opts.hasFollowup = this.followupFilter;
        opts.sort = 'followup_at';
      }
      if (this.$route.params.id) opts.leadList = this.$route.params.id;

      this.$store.dispatch('leads/fetch', opts);
    },

    handleRouteClicked(lead) {
      const { id, pickupLocation, deliveryLocation } = lead;
      this.routeInfo = {
        id,
        origin: `${pickupLocation.city}, ${pickupLocation.state}`,
        destination: `${deliveryLocation.city}, ${deliveryLocation.state}`,
        infoHtml: `(A) ${pickupLocation.city}, ${pickupLocation.state.toUpperCase()}, ${pickupLocation.zip}<i class="mx-1 fa-lg fas fa-shipping-fast"></i>(B) ${
          deliveryLocation.city
        }, ${deliveryLocation.state.toUpperCase()}, ${deliveryLocation.zip}`,
      };
      this.$bvModal.show('cardRouteModal');
    },
    handleFollowup(leadId, type) {
      if (type == 'remove') {
        this.removeFollowup(leadId);
        return;
      }
      if (type == 'custom') {
        this.followupLeadId = leadId;
        return;
      }

      let time = null;
      if (type == '15m') time = moment().add(15, 'm').toISOString();
      if (type == '1h') time = moment().add(1, 'h').toISOString();
      if (type == '1d') time = moment().add(1, 'd').toISOString();

      this.setFollowup(leadId, time);
    },
    async removeFollowup(leadId) {
      let repsonse = await ApiClient.leads.removeFollowup({ id: leadId });
      let leadIndex = this.leads.findIndex(l => l.id === leadId);
      Object.assign(this.leads[leadIndex], repsonse.data.data);
    },
    async setFollowup(leadId, time) {
      if (time) {
        let repsonse = await ApiClient.leads.followup({
          id: leadId,
          body: { followupAt: time },
        });
        let humanDate = moment(time).format('MMM Do, YYYY, hh:mm A');
        this.$bvToast.toast('Follow Up set for ' + humanDate, { title: 'Info', variant: 'info', solid: true });
        let leadIndex = this.leads.findIndex(l => l.id === leadId);
        Object.assign(this.leads[leadIndex], repsonse.data.data);
      } else {
        let repsonse = await ApiClient.leads.removeFollowup({ id: leadId });
        let leadIndex = this.leads.findIndex(l => l.id === leadId);
        Object.assign(this.leads[leadIndex], repsonse.data.data);
      }
      this.followupLeadId = null;
    },
    handleBookedClick(leadId) {
      this.bookedClick = leadId;
    },
    handleBadClick(leadId) {
      this.badClick = leadId;
    },
    async fetchUsers() {
      const response = await ApiClient.users.dropdown({});
      if (response.status == 200) {
        this.mentionableUsers = response.data.data.map(u => ({ value: u.name + '; ', label: u.name}));
      }
    },
    startFilter() {
      let obj = { 
        moveDateBefore: this.moveDateBeforeFilter, 
        moveDateAfter: this.moveDateAfterFilter,
        createdBefore: this.createdBeforeFilter,
        createdAfter: this.createdAfterFilter,
        stateFrom: this.stateFromFilter,
        stateTo: this.stateToFilter,
      };
      Object.keys(obj).forEach(key => (obj[key] === undefined || obj[key] === null) && delete obj[key]);
      this.$router.push({ query: obj });
    },
    clearFilters() {
      this.moveDateBeforeFilter= null;
      this.moveDateAfterFilter= null;
      this.createdBeforeFilter= null;
      this.createdAfterFilter= null;
      this.stateFromFilter= null;
      this.stateToFilter= null;
      this.startFilter();
    },    
  },
  created() {

    //this.loadLeads(); //$route wather already loads leads
    if (!this.$store.state.leads.emailTemplates.length && !this.$store.state.leads.smsTemplates.length) {
      this.$store.dispatch('leads/fetchTemplates');
    }
    if (!this.$store.state.users.loadingMentionableUsers && !this.$store.state.users.mentionableUsers.length) {
      this.$store.dispatch('users/fetchMentionableUsers');
    }

    this.$echo.private(this.channelName).listen('.LeadCreated', e => {
      if (
        this.$route.query.priority ||
        this.$route.query.userId !== undefined ||
        this.$route.name == 'LeadsArchived' ||
        this.$route.name == 'LeadDetails' ||
        this.$route.query.search ||
        this.$route.query.page ||
        this.$route.query.leadList ||
        this.$route.query.hasFollowup
      )
        return;
      this.$store.commit('leads/createLead', objectToCamelCase(e.lead));
      this.setScroll();
      sounds.woosh.play();
    });
    this.$echo.private('leads.updates').listen('.LeadUpdated', e => {
      this.$store.commit('leads/updateLead', { leadId: e.lead.id, attributes: objectToCamelCase(e.lead) });
      //sounds.blop.play();
    });

    this.$echo
      .join('users.online')
      .here(users => {
        console.log(users);
      })
      .joining(user => {
        console.log(user.name);
      })
      .leaving(user => {
        console.log(user.name);
      })
      .error(error => {
        console.error(error);
      });
  },
  mounted() {
    const { search, searchArchived, hasFollowup, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
    this.search = search;
    this.searchArchived = searchArchived ? 1 : 0;
    this.followupFilter = hasFollowup;
    this.moveDateAfterFilter = moveDateAfter || null;
    this.moveDateBeforeFilter = moveDateBefore || null;
    this.createdAfterFilter = createdAfter || null;
    this.createdBeforeFilter = createdBefore || null;
    this.stateFromFilter = stateFrom || null;
    this.stateToFilter = stateTo || null;
  },
  beforeDestroy() {
    this.$echo.leave('users.online');
    this.$echo.leave('leads.updates');
    this.$echo.leave(this.channelName);
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  max-width: 300px;
}
.prior-box {
  min-width: 16px;
  min-height: 16px;
  border-radius: 2px;
}

.filters {
  .dropdown-menu {
    min-width: 11rem !important;
  }
  .b-dropdown-form {
    padding-left: 15px !important;
    padding-right: 15px !important;
    &:focus {
      outline: unset !important;
    }
  }
}

.uppercase {
  text-transform: capitalize;
}

.priorities {
  white-space: nowrap;
  .btn-prior {
    padding: 12px;
  }
  .all {
    background-color: #fff;
    border-color: black;
  }
  .nopriority {
    background-color: #dadada;
    border-color: black;
  }
  .hot {
    background-color: #95e7b8;
    border-color: black;
  }
  .future {
    background-color: #f8e287;
    border-color: black;
  }
  .booked {
    background-color: #78daff;
    border-color: black;
  }
  .bad {
    background-color: #f3a69e;
    border-color: black;
  }
}
</style>
<style lang="scss">
.filters {
  .dropdown-menu {
    min-width: 11rem !important;
  }
}
</style>
