<template>
  <div>
    <h5>Agent Source Sales Report</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit">
        <b-form-radio v-model="periodType" name="periodType" value="list">Time period</b-form-radio>
        <b-select class="custom-select ml-3" v-if="periodType == 'list'" v-model="timePeriod" :options="timeOptions" />
        <span class="ml-3">OR</span>
        <b-form-radio v-model="periodType" name="periodType" value="custom" class="ml-3">Custom date range</b-form-radio>
        <flat-pickr class="form-control ml-3" v-if="periodType != 'list'" v-model="selectedDates" :config="fltConfig" />

        <div class="ml-3">
          <multiselect
                  v-model="sourcesFilter"
                  label="label"
                  track-by="value"
                  placeholder="Select source"
                  :options="sources"
                  :allow-empty="true"
                  :multiple="true"
                  :searchable="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                >
                  <span slot="noResult">All sources</span>
          </multiselect>
        </div>

        <button class="btn btn-primary ml-3">Show Report</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <span v-if="empty">No records found</span>

    <b-card class="mb-4" v-if="!loading && items.length">
      <b-table striped hover :items="items" :fields="topFields" caption-top>
        <template #table-caption>
          <span v-if="form.dispatchedStart">Dispatched from {{ form.dispatchedStart }} till {{ form.dispatchedEnd }}</span>
          <span v-if="form.createdStart">Created from {{ form.createdStart }} till {{ form.createdEnd }}</span>
        </template>

        <template #cell(user)="data">
          {{ data.value ? data.value.name : '-' }}
        </template>
        
        <template #cell(rows)="data">
          <b-table v-if="data.item.rows.length" striped hover :items="data.item.rows" :fields="fields" class="mb-0">
            <template #cell(sourceId)="data">
              {{ data.item.source ? data.item.source.name : '' }}
            </template>

            <template #cell(leadsCount)="row">
              <b-link :to="{ name: 'LeadList', query: { leadList: row.item.leadsList } }" target="_blank">
                {{ row.value }}
              </b-link>
            </template>
            <template #cell(quotesCount)="row">
              <b-link :to="{ name: 'QuoteList', query: { leadList: row.item.quotesList } }" target="_blank">
                {{ row.value }}
              </b-link>
            </template>
            <template #cell(ordersCount)="row">
              <b-link :to="{ name: 'OrderList', query: { leadList: row.item.ordersList } }" target="_blank">
                {{ row.value }}
              </b-link>
            </template>

          </b-table>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';

export default {
  name: 'ReportAgentSourceSales',
  data() {
    return {
      loading: false,
      empty: false,
      items: [],
      totals: {},
      sources: [],
      sourcesFilter: [],
      periodType: 'list',
      filterType: 1,
      timePeriod: 1,
      selectedDates: null,
      form: {
        createdStart: null,
        createdEnd: null,
        dispatchedStart: null,
        dispatchedEnd: null,
        sources: null,
      },
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
      },
      timeOptions: [
        { text: 'Current month', value: 1 },
        { text: 'Last month', value: 2 },
        { text: 'Current quarter', value: 3 },
        { text: 'Last quarter', value: 4 },
        { text: 'Current year', value: 5 },
        { text: 'All time', value: 6 },
      ],
      dataOptions: [
        { text: 'Created orders - during time frame', value: 1 },
        { text: 'Dispatched orders - during time frame', value: 2 },
      ],
      topFields: [
        {
          key: 'user',
          label: 'Agent',
          sortable: true,
        },
        {
          key: 'rows',
          label: '',
          sortable: false,
        },
      ],
      fields: [
        {
          key: 'sourceId',
          label: 'Source',
          sortable: false,
        },
        {
          key: 'leadsCount',
          label: 'Leads',
          sortable: true,
        },
        {
          key: 'quotesCount',
          label: 'Quotes',
          sortable: true,
        },
        {
          key: 'ordersCount',
          label: 'Orders',
          sortable: true,
        },
        {
          key: 'totalCount',
          label: 'Total',
          sortable: true,
        },
        {
          key: 'canceledCount',
          label: 'Canceled',
          sortable: true,
        },
        {
          key: 'issuesCount',
          label: 'Issues',
          sortable: true,
        },
        {
          key: 'dispatchedCount',
          label: 'Dispatched',
          sortable: true,
        },
        {
          key: 'tarrifsTotal',
          label: 'Tariffs',
          sortable: true,
        },
        {
          key: 'carrierPayTotal',
          label: 'Carrier Pay',
          sortable: true,
        },
        {
          key: 'pendingDepositTotal',
          label: 'Unpaid deposit',
          sortable: true,
        },
        {
          key: 'conversionRate',
          label: 'Conversion Rate',
          sortable: true,
        },
        {
          key: 'grossProfit',
          label: 'Gross Profit',
          sortable: true,
        },
        {
          key: 'profitMargin',
          label: 'Profit Margin',
          sortable: true,
        },
        {
          key: 'averageProfit',
          label: 'Average Profit',
          sortable: true,
        },
        {
          key: 'show_details',
          label: '',
          sortable: false,
        },
      ]
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
    flatPickr,
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.fetchAgentSourceSalesReport();
    },
    async fetchSources() {
      const response = await ApiClient.sources.get({});
      if (response.status == 200) {
        this.sources = response.data.data.map(u => ({ value: u.id, label: u.name }));
      }
    },    
    async fetchAgentSourceSalesReport() {
      this.prepareDates();
      this.loading = true;
      const response = await ApiClient.reports.agentSourceSales({ params: this.form });
      this.loading = false;
      this.items = response.data.data.rows;
      this.empty = !this.items.length;
    },
    prepareDates() {
      let parts = [];
      if (this.periodType == 'list') {
        if (this.timePeriod == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDates) return;
        parts = this.selectedDates.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }

      this.form.createdStart = null;
      this.form.createdEnd = null;
      this.form.dispatchedStart = null;
      this.form.dispatchedEnd = null;

      if (this.filterType == 1) {
        this.form.createdStart = parts[0];
        this.form.createdEnd = parts[1];
      } else {
        this.form.dispatchedStart = parts[0];
        this.form.dispatchedEnd = parts[1];
      }

      this.form.sources = this.sourcesFilter.map(x => x.value).join(',');
    },
  },
  async mounted() {
    this.fetchSources();
  },
};
</script>

<style lang="scss" scoped>
.text-danger a {
  color: inherit;
}
</style>
