import LayoutCentered from '@/layout/LayoutCentered';
import LeadsTabs from '@/components/pages/leads/LeadsTabs';

export default [
  {
    // Layout 2
    path: '/leads',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        component: LeadsTabs,
        name: 'LeadList',
      },
      {
        component: LeadsTabs,
        name: 'LeadsArchived',
        path: 'archived',
      },
      {
        component: LeadsTabs,
        name: 'Reclaims',
        path: 'reclaims',
      },
      {
        component: LeadsTabs,
        name: 'LeadsSettings',
        path: 'settings',
      },
      {
        component: LeadsTabs,
        name: 'LeadsWithStatus',
        path: 'status/:status',
      },
      {
        component: LeadsTabs,
        name: 'LeadDetails',
        path: 'view/:id',
      }
    ],
  },
];
