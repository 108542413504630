import LayoutCentered from '@/layout/LayoutCentered';
import PreferencesTabs from '@/components/pages/preferences/PreferencesTabs';

export default [
  {
    // Layout 2
    path: '/preferences',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      /* redirections */
      {
        path: '',
        beforeEnter: (to, from, next) => {
          next('/preferences/settings');
        },
      },
      /* / redirections */
      {
        component: PreferencesTabs,
        path: '',
        children: [
          {
            path: 'settings',
            component: () => import('@/components/pages/preferences/settings/Settings'),
          },
          {
            path: 'account-info',
            component: () => import('@/components/pages/preferences/accountInfo/AccountInfo'),
          },
          {
            path: 'automated-emails',
            component: () => import('@/components/pages/preferences/automatedEmails/AutomatedEmails'),
          },
          {
            path: 'automated-forms',
            component: () => import('@/components/pages/preferences/automatedForms/AutomatedForms'),
          },
          {
            path: 'external-forms',
            component: () => import('@/components/pages/preferences/externalForms/ExternalForms'),
          },
        ],
      },
    ],
  },
];
