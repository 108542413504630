<template>
  <div>
    <b-btn v-if="$route.path === '/vehicles/models'" variant="primary" class="mb-4 text-capitalize" pill @click="handleOpenVehicleCreateModal">
      <span class="mr-1 fas fa-plus"></span> New Vehicle
    </b-btn>

    <b-btn v-else variant="primary" class="mb-4 text-capitalize" pill @click="handleOpenVehicleTypeMakeModal">
      <span class="mr-1 fas fa-plus"></span> New Vehicle {{ title }}
    </b-btn>

    <div class="nav-responsive-lg mb-2">
      <b-nav tabs>
        <b-nav-item :active="getTabActive('/vehicles/types')" to="/vehicles/types"> Type </b-nav-item>
        <b-nav-item :active="getTabActive('/vehicles/makes')" to="/vehicles/makes"> Make </b-nav-item>
        <b-nav-item :active="getTabActive('/vehicles/models')" to="/vehicles/models"> Model </b-nav-item>
      </b-nav>
    </div>
    <div>
      <router-view />
    </div>

    <VehicleModal :id="vehicleCreateModalId" :on-submit="handleSubmitVehicle" />
    <VehicleTypeMakeModal :id="vehicleTypeMakeModalId" :vehicle-form="vehicleTypeMakeForm" :title="title" :on-submit="handleSubmitVehicleTypeMake" />
  </div>
</template>

<script>
import api from '@/api';
import { tabActive } from '@/mixins/methods';
import { getCleanObject } from '@/helpers/methods';
import VehicleModal from '@/components/shared/modals/VehicleModal';
import VehicleTypeMakeModal from '@/components/shared/modals/VehicleTypeMakeModal';

export default {
  name: 'VehiclesTabs',
  components: { VehicleModal, VehicleTypeMakeModal },
  mixins: [tabActive],
  data() {
    return {
      vehicleCreateModalId: 'vehicleCreateModal',
      vehicleTypeMakeModalId: 'vehicleTypeMakeModal',
      vehicleTypeMakeForm: {
        name: null,
        surcharge: null,
      },
    };
  },
  computed: {
    path() {
      const { path } = this.$route;
      const pathArr = path.split('/');

      return pathArr[pathArr.length - 1];
    },
    title() {
      return this.$route.path === '/vehicles/types' ? 'Type' : 'Make';
    },
  },
  methods: {
    async handleSubmitVehicle({ form }) {
      await api.vehicles.post(null, getCleanObject(form));
    },
    async handleSubmitVehicleTypeMake({ form }) {
      const { $route } = this;
      const endpoint = $route.path === '/vehicles/types' ? 'vehicleTypes' : 'vehicleMakes';
      await api[endpoint].post(null, getCleanObject(form));
      this.vehicleTypeMakeForm = { name: null, surcharge: null };
    },
    handleOpenVehicleTypeMakeModal() {
      const { vehicleTypeMakeModalId } = this;

      this.$bvModal.show(vehicleTypeMakeModalId);
    },
    handleOpenVehicleCreateModal() {
      const { vehicleCreateModalId } = this;

      this.$bvModal.show(vehicleCreateModalId);
    },
  },
};
</script>

<style></style>
