<template>
  <div>
    <div v-if="leadlike">
      <div class="d-flex justify-content-between align-items-center">
        <h1><span id="lead-hash">{{ targetType }} #</span>{{ leadlike.orderNumber || leadlike.id }}<small v-if="leadlike.deletedAt" class="text-muted">- Archived</small></h1>
      </div>

      <ul v-if="targetType === 'Quote'" class="nav nav-pills mb-4">
        <LeadlikeTabLink label="View" route-name="QuoteDetails" :id="leadlike.id" />
        <LeadlikeTabLink label="Edit" route-name="QuoteEdit" :id="leadlike.id" v-if="!leadlike.deletedAt" />
        <LeadlikeTabLink label="Offers" route-name="QuoteOffers" :id="leadlike.id" />
        <LeadlikeTabLink label="Loadboards" route-name="QuoteLoadboards" :id="leadlike.id" v-if="!leadlike.deletedAt && tokenCan('orders:dispatch')" />
        <LeadlikeTabLink label="Emails" route-name="QuoteEmails" :id="leadlike.id" />
        <LeadlikeTabLink label="Sms" route-name="QuoteSms" :id="leadlike.id" v-if="!leadlike.deletedAt" />
        <LeadlikeTabLink label="Attachments" :count="leadlike.attachments.length" route-name="QuoteAttachments" :id="leadlike.id" v-if="!leadlike.deletedAt" />
        <LeadlikeTabLink label="Rates" route-name="QuoteMarketRates" :id="leadlike.id" />
        <LeadlikeTabLink label="History" route-name="QuoteHistory" :id="leadlike.id" />
        <LeadlikeTabLink label="Views" route-name="QuoteViews" :id="leadlike.id" v-if="tokenCan('admin')" />
      </ul>

      <ul v-if="targetType === 'Order'" class="nav nav-pills mb-4">
        <LeadlikeTabLink label="View" route-name="OrderDetails" :id="leadlike.id" />
        <LeadlikeTabLink label="Edit" route-name="OrderEdit" :id="leadlike.id" />
        <LeadlikeTabLink label="Dispatch" route-name="OrderDispatch" :id="leadlike.id" v-if="!leadlike.deletedAt && tokenCan('orders:dispatch')" />
        <LeadlikeTabLink label="Loadboards" route-name="OrderLoadboards" :id="leadlike.id" v-if="!leadlike.deletedAt && tokenCan('orders:dispatch')" />
        <LeadlikeTabLink label="Payments" route-name="OrderPayments" :id="leadlike.id" />
        <LeadlikeTabLink label="Attachments" :count="leadlike.attachments.length" route-name="OrderAttachments" :id="leadlike.id" />
        <LeadlikeTabLink label="Emails" route-name="OrderEmails" :id="leadlike.id" />
        <LeadlikeTabLink label="SMS" route-name="OrderSms" :id="leadlike.id" v-if="!leadlike.deletedAt" />
        <LeadlikeTabLink label="Rates" route-name="OrderMarketRates" :id="leadlike.id" v-if="!leadlike.deletedAt" />
        <LeadlikeTabLink label="History" route-name="OrderHistory" :id="leadlike.id" />
        <LeadlikeTabLink label="Views" route-name="OrderViews" :id="leadlike.id" v-if="tokenCan('admin')" />
      </ul>

      <router-view v-bind="routerViewProps" @save="updateLocalState" @refresh="reloadLeadlikeData" />
    </div>
    <LoadingIndicator v-else centered />
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LeadlikeTabLink from './LeadlikeTabLink.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'Leadlike',
  props: {
    targetType: {
      type: String,
      default: 'Order',
    },
  },
  mixins: [permissionsMixin],
  components: { LeadlikeTabLink, LoadingIndicator },
  data() {
    return {
      leadlike: null,
      carrier: null,
    };
  },
  async mounted() {
    await this.reloadLeadlikeData();
    if (!this.$store.state.leads.emailTemplates.length && !this.$store.state.leads.smsTemplates.length) {
      this.$store.dispatch('leads/fetchTemplates');
    }
    if (this.leadlike.leadTypeId == 3 && this.$route.name != 'OrderDetails') {
      this.$router.push({ name: 'OrderDetails', params: { id: this.leadlike.id } });
    }
    if (this.leadlike.leadTypeId == 2 && this.$route.name != 'QuoteDetails') {
      this.$router.push({ name: 'QuoteDetails', params: { id: this.leadlike.id } });
    }
    if (this.leadlike.leadTypeId == 1 && this.$route.name != 'LeadDetails') {
      this.$router.push({ name: 'LeadDetails', params: { id: this.leadlike.id } });
    }
  },
  computed: {
    routerViewProps() {
      if (['OrderEdit', 'QuoteEdit'].includes(this.$route.name)) {
        return { 'initial-lead-quote-order': this.leadlike };
      } else if (['OrderDispatch', 'OrderLoadboards', 'QuoteLoadboards', 'OrderOffers', 'QuoteOffers'].includes(this.$route.name)) {
        return { order: this.leadlike };
      } else {
        return { 'lead-quote-order': this.leadlike };
      }
    },
    getLeadId() {
      return this.$route.params.id;
    },
  },
  methods: {
    updateLocalState(leadlike) {
      this.leadlike = leadlike;
    },
    async reloadLeadlikeData() {
      const { id } = this.$route.params;
      const apiResponse = await ApiClient.leads.get({ id });
      this.leadlike = apiResponse.data.data;
    },
  },
  watch: {
    getLeadId() {
      this.reloadLeadlikeData();
    },
    '$route' (to, from) {
      if((to.name == 'QuoteDetails' || to.name == 'OrderDetails') && !(from.name == 'QuoteDetails' || from.name == 'OrderDetails')){
        this.reloadLeadlikeData();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#lead-hash {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
</style>
