<template>
  <div>
    <div class="grid--three-column">
      <div v-if="customer.contact" class="card mb-4">
        <h5 class="card-header">Contact</h5>
        <div class="card-body">
          <LabeledData label="Company" :value="customer.contact.company" hide-if-blank />
          <LabeledData label="Contact" :value="customer.contact.contact" hide-if-blank />
          <LabeledData label="First Name" :value="customer.contact.firstName" hide-if-blank />
          <LabeledData label="Last Name" :value="customer.contact.lastName" hide-if-blank />
          <LabeledData label="Phone" :value="customer.contact.phone | formatPhone" hide-if-blank />
          <LabeledData label="Phone 2" :value="customer.contact.phone2 | formatPhone" hide-if-blank />
          <LabeledData label="Email" :value="customer.contact.email" hide-if-blank />
          <LabeledData label="Cell phone" :value="customer.contact.cellPhone | formatPhone" hide-if-blank />
          <div class="mt-3" v-if="customer.notes">
            <span class="h5">Notes:</span>
            <div class="pre">{{ customer.notes }}</div>
          </div>
        </div>
      </div>
      <div v-if="customer.location" class="card mb-4">
        <h5 class="card-header">Address</h5>
        <div class="card-body">
          <LabeledData label="Location" :value="customer.location.city + ', ' + customer.location.state + ' ' + customer.location.zip" hide-if-blank />
          <LabeledData label="Address" :value="customer.location.address" hide-if-blank />
          <LabeledData label="Address2" :value="customer.location.address2" hide-if-blank />
        </div>
      </div>
    </div>
    <div v-if="customer.leads.length" class="card mb-4">
      <h5 class="card-header">Active Orders</h5>
      <div class="card-body">
        <table class="table">
          <thead class="table">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Pickup Date</th>
              <th scope="col">Customer</th>
              <th scope="col">Vehicles</th>
              <th scope="col">Route</th>
              <th scope="col">Customer Pay</th>
              <th scope="col">User</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lqo in customer.leads" :key="lqo.id">
              <td class="px-1">
                <router-link :to="{ name: 'OrderDetails', params: { id: lqo.id } }">#{{ lqo.id }}</router-link>
              </td>
              <td>{{ lqo.pickupDate | formatDate }}</td>
              <td>
                <div v-if="lqo.customer.contact.firstName || lqo.customer.contact.lastName">{{ lqo.customer.contact.firstName }} {{ lqo.customer.contact.lastName }}</div>
                <div v-if="lqo.customer.contact.company">{{ lqo.customer.contact.company }}</div>
              </td>
              <td>
                <div v-for="vehicle in lqo.vehicles" :key="vehicle.id">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</div>
              </td>
              <td>
                <LeadlikeTableLocation :location="lqo.pickupLocation" />
                <span class="mr-1 fas fa-arrow-right"></span>
                <LeadlikeTableLocation :location="lqo.deliveryLocation" />
              </td>
              <td>{{ lqo.customerPay }}</td>
              <td>{{ lqo.user ? lqo.user.name : '-' }}</td>
              <td>{{ lqo.leadType.title }}</td>
              <td>{{ lqo.leadStatus.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import LabeledData from '@/components/shared/LabeledData';
import LeadlikeTableLocation from '@/components/shared/LeadlikeTableLocation';

export default {
  name: 'CustomerDetails',
  components: { LabeledData, LeadlikeTableLocation },
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.pre {
  white-space: pre-wrap;
}
</style>
