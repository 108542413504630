<template>
  <div class="mb-3">
    <div class="label">{{ title }}</div>
    <div :class="{ 'font-weight-bold': true, 'customer-name': customerName }"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'LeadsCardData',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    customerName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.8rem;
}
.customer-name {
  font-size: 16px;
  text-transform: capitalize;
}
</style>
