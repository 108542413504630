<template>
  <div>
    <div v-if="leadQuoteOrder.tasksDue.length" class="callout callout-danger mb-3">
      <div v-if="checkCCFailed">CC failed authorization. Update CC details and authorize manually from CIM (within Auth.NET)</div>
      There are tasks due. Go "View" tab and check the tasks section below.
      <div v-if="checkCCTask && !checkCCFailed">Billing Address do not match with either PU or DEL location.</div>
    </div>
    <div class="d-flex justify-content-end mb-2">
      <button class="btn btn-secondary btn-sm" @click="shipperInvoiceId = leadQuoteOrder.id">Send Shipper Invoice</button>
    </div>
    <div class="card mb-4">
      <h5 class="card-header">Payments</h5>
      <div class="card-body">
        <LoadingIndicator v-if="paymentsLoading" centered />
        <span v-else>
          <div v-if="preparedPayments.length">
            <table class="table">
              <thead class="table">
                <tr>
                  <th scope="col">Date received</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Surcharge</th>
                  <th scope="col">Payment direction</th>
                  <th scope="col">Payment type</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Agent</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody v-for="payment in preparedPayments" :key="payment.id">
                <tr>
                  <td>{{ payment.date | formatDate }}</td>
                  <td>{{ payment.amount | formatMoney }}</td>
                  <td>{{ payment.surcharge | formatMoney }}</td>
                  <td>{{ payment.direction }}</td>
                  <td>{{ payment.type }}</td>
                  <td>{{ payment.note }}</td>
                  <td>{{ payment.agent }}</td>
                  <td class="text-right">
                    <button class="btn btn-outline-secondary btn-sm" :disabled="!payment.isSuccessful" @click="receiptId = payment.id">Receipt</button>
                    <button class="btn btn-outline-secondary btn-sm" :disabled="!payment.isSuccessful" @click="editPayment(payment.id)">Edit</button>
                    <button class="btn btn-outline-danger btn-sm" :disabled="!payment.isSuccessful" @click="confirmDeleting(payment.id)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>Total paid: {{ totalPaid | formatMoney }}</h4>
            <h4 class="m-0">Total received: {{ totalReceived | formatMoney }}</h4>
            <div>-------------------------------------------------------</div>
            <h4>Net Received: {{ (totalReceived - totalPaid) | formatMoney }}</h4>
          </div>
          <div class="alert alert-warning" v-else>No payments have been recorded</div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-warning mr-3" @click="createPaymentExtendedModal = true">Charge Credit/Debit Card</button>
            <button class="btn btn-primary" @click="createPaymentModal = true">Record new payment</button>
          </div>
        </span>
      </div>
    </div>

    <ModalDialog v-show="createPaymentExtendedModal" @close="() => ((createPaymentExtendedModal = false), (newOrSaved = null))">
      <div class="modal-content">
        <h5>Charge a Credit/Debit Card</h5>
        <ValidationObserver ref="observermakeCreateExtendedPaymentApiRequest">
          <form @submit.prevent="makeCreateExtendedPaymentApiRequest()">
            <ValidationProvider name="Select Method" rules="" v-slot="{ errors: err }">
              <label>Select Method</label>
              <select :class="{ 'form-control mb-3': true, 'is-invalid': err[0] }" @change="event => (newOrSaved = event.target.value)">
                <option v-for="card in allCards" :key="card.customerPaymentProfileId" :value="card.customerPaymentProfileId">
                  {{ card.payment.creditCard.cardNumber + ' ' + card.payment.creditCard.cardType }}
                </option>
                <option value="new">New Credit/Debit Card</option>
              </select>
              <span>{{ err[0] }}</span>
            </ValidationProvider>
            <FormFieldsCreditCard
              v-if="newOrSaved == 'new'"
              :form-fields="createExtendedCardForm"
              @input="updateExtendedCardForm"
              :error-resolver="field => createExtendedCardErrors[field]"
            />
            <FormFieldsProfilePayment :form-fields="createExtendedCardForm" @input="updateExtendedCardForm" :error-resolver="field => createExtendedCardErrors[field]" />
            <FormFieldsErrors :errors="createExtendedCardErrors" />
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" :disabled="disableCreateButton" type="submit">Charge</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </ModalDialog>

    <ModalDialog v-show="idOfEditedPayment" @close="() => (idOfEditedPayment = null)">
      <div class="modal-content">
        <h5>Edit payment #{{ idOfEditedPayment }}</h5>
        <FormFieldsPayment :form-fields="editPaymentForm" @input="newForm => (editPaymentForm = newForm)" :error-resolver="field => editPaymentErrors[field]" />
        <FormFieldsErrors :errors="editPaymentErrors" />
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" :disabled="disableEditButton" @click="makeUpdatePaymentApiRequest">Save</button>
        </div>
      </div>
    </ModalDialog>

    <ModalDialog v-show="createPaymentModal" @close="() => (createPaymentModal = false)">
      <div class="modal-content">
        <h5>Record a payment</h5>
        <ValidationObserver ref="observermakeCreatePaymentApiRequest">
          <form @submit.prevent="makeCreatePaymentApiRequest()">
            <FormFieldsPayment :form-fields="createPaymentForm" @input="updateCreatePaymentForm" :error-resolver="field => createPaymentErrors[field]" />
            <FormFieldsErrors :errors="createPaymentErrors" />
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" :disabled="disableCreateButton" type="submit">Create</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </ModalDialog>

    <ModalDialog v-show="receiptId" @close="() => (receiptId = null)">
      <div class="modal-content">
        <h5>Send receipt #{{ receiptId }}</h5>
        <ValidatedInput id="toAddress" v-model="toAddress" label="Email address" :errors="errors.toAddress" />
        <FormFieldsErrors :errors="errors" />
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="sendReceipt">Send Receipt</button>
        </div>
      </div>
    </ModalDialog>

    <ModalDialog v-show="shipperInvoiceId" @close="() => (shipperInvoiceId = null)">
      <div class="modal-content">
        <h5>Send shipper invoice</h5>
        <ValidatedInput id="toAddress" v-model="toAddress" label="Email address" :errors="errors.toAddress" />
        <FormFieldsErrors :errors="errors" />
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="sendShipperInvoice">Send Shipper Invoice</button>
        </div>
      </div>
    </ModalDialog>

    <div class="card mb-4">
      <h5 class="card-header">Credit Card Info</h5>
      <div class="card-body">
        <div v-if="!allCards">
          <div v-if="!showPermissionButton" class="alert alert-warning">No cards have been recorded</div>
          <button class="btn btn-warning btn-sm mr-2" v-if="showPermissionButton" @click="showPaymentProfileConfirmation = true">Show Payment Profile</button>
          <ConfirmationModal
            v-if="showPaymentProfileConfirmation"
            @confirm="showPaymentProfile"
            @cancel="showPaymentProfileConfirmation = false"
            :cancel-label="'No'"
            :confirm-label="'Yes'"
            :label="'Have you asked customer for new CC information?'"
          />
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="createCardModal = true">Add Card to Order</button>
          </div>
        </div>
        <div v-else>
          <div v-for="card in allCards" :key="card.customerPaymentProfileId" class="d-flex align-items-center mb-2">
            <div class="mr-4">
              <LabeledData class="ml-2 mb-1" label="Saved Card" :value="card.payment.creditCard.cardNumber + ' ' + card.payment.creditCard.cardType" />
              <LabeledData class="ml-2 mb-1" label="Billing Info" :value="card.billTo.address + ', ' + card.billTo.city + ', ' + card.billTo.zip" />
            </div>
            <button type="button" class="btn btn-sm btn-warning mr-3" @click="createProfilePaymentModal = card.customerPaymentProfileId" :disabled="checkCCFailed">
              Charge CC
            </button>
            <button type="button" class="btn btn-sm btn-danger" @click="deleteSavedCCModal = card.customerPaymentProfileId">Delete CC</button>
          </div>
          <button class="btn btn-sm btn-primary mt-2" @click="createCardModal = true">Add Another to Order</button>
        </div>
      </div>
    </div>

    <ModalDialog msize="modal-sm" v-show="deleteSavedCCModal" @close="() => (deleteSavedCCModal = false)">
      <div class="modal-content text-center">
        <h5>Are you sure to delete saved card?</h5>
        <div class="d-flex justify-content-center">
          <button class="btn btn-sm btn-warning mr-3" @click="deleteSavedCCModal = false">No, keep card</button>
          <button class="btn btn-sm btn-danger" @click="deleteSavedCC">Yes, delete</button>
        </div>
      </div>
    </ModalDialog>

    <ModalDialog msize="modal-md" v-show="createProfilePaymentModal" @close="() => (createProfilePaymentModal = false)">
      <div class="modal-content">
        <h5>Make a payment</h5>
        <ValidationObserver ref="observerProfilePaymentApiRequest">
          <form @submit.prevent="makeCreateProfilePaymentApiRequest()">
            <FormFieldsProfilePayment
              :form-fields="createProfilePaymentForm"
              @input="updateCreateProfilePaymentForm"
              :error-resolver="field => createProfilePaymentErrors[field]"
            />
            <FormFieldsErrors :errors="createProfilePaymentErrors" />
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" :disabled="disableCreateButton" type="submit">Charge</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </ModalDialog>

    <ModalDialog v-show="createCardModal" @close="() => (createCardModal = false)">
      <div class="modal-content">
        <h5>Save a Credit/Debit Card</h5>
        <ValidationObserver ref="observermakeCreateCardApiRequest">
          <form @submit.prevent="makeCreateCardApiRequest()">
            <FormFieldsCreditCard :form-fields="createCardForm" @input="updateCreateCardForm" :error-resolver="field => createCardErrors[field]" />
            <FormFieldsErrors :errors="createCardErrors" />
            <div class="form-group mt-3 mb-0">
              <input id="save-check" type="checkbox" :checked="!disableSaveButton" class="form-group" @change="checkBoxChecked($event)" />
              <label for="save-check">Are you sure you want to save this information?</label>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" :disabled="disableSaveButton" type="submit">Save</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </ModalDialog>

    <div class="card mb-4">
      <h5 class="card-header">Landing Page</h5>
      <div class="card-body">
        <a :href="landingPageUrl" target="_blank">{{ landingPageUrl }}</a>
      </div>
    </div>

    <ConfirmationModal v-if="idOfPaymentToBeDeleted" @confirm="deletePayment" @cancel="idOfPaymentToBeDeleted = null" />
  </div>
</template>

<script>
import { apiRequest } from '@/api/apiRequest';
import { ApiClient } from '@/api/ApiClient';
import { mapState } from 'vuex';
import paymentDirectionsMixin from '@/mixins/paymentDirections';
import paymentTypesMixin from '@/mixins/paymentTypes';
import FormFieldsPayment from '@/components/shared/formFields/FormFieldsPayment';
import FormFieldsProfilePayment from '@/components/shared/formFields/FormFieldsProfilePayment';
import FormFieldsCreditCard from '@/components/shared/formFields/FormFieldsCreditCard';
import FormFieldsErrors from '@/components/shared/formFields/FormFieldsErrors';
import ValidatedInput from '@/components/shared/ValidatedInput';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import ConfirmationModal from '@/components/shared/ConfirmationModal';
import ModalDialog from '@/components/shared/ModalDialog';
import LabeledData from './LabeledData';

export default {
  data() {
    return {
      payments: [],
      preparedPayments: [],
      createExtendedCardForm: {},
      createExtendedCardErrors: {},
      newOrSaved: 'new',
      allCards: null,
      deleteSavedCCModal: false,
      deleteSavedCCId: {},
      disableSaveButton: true,
      createPaymentExtendedModal: false,
      createPaymentForm: {},
      createProfilePaymentForm: {},
      createProfilePaymentErrors: {},
      createCardForm: {},
      createPaymentErrors: {},
      createCardErrors: {},
      editPaymentForm: {},
      editPaymentErrors: {},
      idOfEditedPayment: null,
      paymentsLoading: true,
      idOfPaymentToBeDeleted: null,
      createPaymentModal: false,
      createProfilePaymentModal: false,
      createCardModal: false,
      disableCreateButton: false,
      disableEditButton: false,
      errors: {},
      toAddress: this.leadQuoteOrder?.customer?.contact?.email,
      receiptId: null,
      shipperInvoiceId: null,
      showPermissionButton: false,
      showPaymentProfileConfirmation: false,
    };
  },
  mixins: [paymentDirectionsMixin, paymentTypesMixin],
  components: {
    LoadingIndicator,
    ConfirmationModal,
    FormFieldsErrors,
    FormFieldsPayment,
    FormFieldsProfilePayment,
    FormFieldsCreditCard,
    ValidatedInput,
    ModalDialog,
    LabeledData,
  },
  props: {
    leadQuoteOrder: {
      type: Object,
      required: true,
    },
  },
  watch: {},
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    checkCCTask() {
      var isTrue = false;
      this.leadQuoteOrder.tasksDue.find(t => {
        if (t.note.includes('zip')) {
          isTrue = true;
        }
      });
      return isTrue;
    },
    checkCCFailed() {
      var isTrue = false;
      this.leadQuoteOrder.tasksDue.find(t => {
        if (t.note.includes('CC failed validation')) {
          isTrue = true;
        }
      });
      return isTrue;
    },
    orderId() {
      return this.leadQuoteOrder.id;
    },
    landingPageUrl() {
      return process.env.VUE_APP_NEXUS_LANDING_PAGE_URL + 'payment/?uuid=' + this.leadQuoteOrder.uuid;
    },
    totalPaid() {
      return this.payments.payments
        .filter(function (payment) {
          if (payment.paymentDirectionId === 2 || payment.paymentDirectionId === 5) {
            return true;
          }
        })
        .reduce((sum, payment) => (sum += Number.parseFloat(payment.amount) + Number.parseFloat(payment.surcharge)), 0);
    },
    totalReceived() {
      return this.payments.payments
        .filter(function (payment) {
          if (payment.paymentDirectionId === 1 || payment.paymentDirectionId === 6) {
            return true;
          }
        })
        .reduce((sum, payment) => (sum += Number.parseFloat(payment.amount) + Number.parseFloat(payment.surcharge)), 0);
    },
  },
  async mounted() {
    this.fetchPayments();
    this.checkCC();
  },
  methods: {
    async showPaymentProfile() {
      const response = await ApiClient.leads.payments.customers.permission.get({ leadId: this.orderId });
      if (response.status == 200) {
        this.showPaymentProfileConfirmation = false;
        this.showPermissionButton = false;
      }
      this.checkCC();
    },
    async sendReceipt() {
      if (!this.toAddress) return;
      await ApiClient.payments.sendReceipt({ id: this.receiptId, body: { toAddress: this.toAddress } });
      this.receiptId = null;
    },
    async sendShipperInvoice() {
      if (!this.toAddress) return;
      await ApiClient.leads.payments.shipperInvoice({ leadId: this.shipperInvoiceId, body: { toAddress: this.toAddress } });
      this.shipperInvoiceId = null;
    },
    checkBoxChecked(event) {
      if (event.target.checked) {
        this.disableSaveButton = false;
      } else {
        this.disableSaveButton = true;
      }
    },
    editPayment(paymentId) {
      this.idOfEditedPayment = paymentId;
      this.editPaymentForm = this.payments.payments.find(p => p.id == paymentId);
    },
    updateCreatePaymentForm(newForm) {
      this.createPaymentForm = newForm;
    },
    updateExtendedCardForm(newForm) {
      this.createExtendedCardForm = newForm;
    },
    updateCreateCardForm(newForm) {
      this.createCardForm = newForm;
    },
    updateCreateProfilePaymentForm(newForm) {
      this.createProfilePaymentForm = newForm;
    },
    async makeCreatePaymentApiRequest() {
      const isValid = await this.$refs.observermakeCreatePaymentApiRequest.validate();
      if (isValid) {
        this.disableCreateButton = true;
        if (!this.createPaymentForm.dateReceived) {
          var todayDate = new Date().toISOString().slice(0, 10);
          todayDate.replace('-', '/');
          this.createPaymentForm.dateReceived = todayDate;
        }
        const response = await ApiClient.leads.payments.post({ leadId: this.orderId, body: this.createPaymentForm });
        this.disableCreateButton = false;

        if (response.status === 200) {
          this.fetchPayments();
          this.createPaymentErrors = {};
          this.createPaymentForm = {};
          this.createPaymentModal = false;
        } else if (response.status === 422) {
          this.createPaymentErrors = response.data.errors;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    async makeCreateExtendedPaymentApiRequest() {
      const isValid = await this.$refs.observermakeCreateExtendedPaymentApiRequest.validate();
      if (isValid) {
        this.disableCreateButton = true;
        if (this.newOrSaved == null) {
          this.createExtendedCardErrors = { error: 'Select a method' };
          this.disableCreateButton = false;
        }
        if (!this.createExtendedCardForm.dateReceived) {
          var todayDate = new Date().toISOString().slice(0, 10);
          todayDate.replace('-', '/');
          this.createExtendedCardForm.dateReceived = todayDate;
        }
        if (!this.createExtendedCardForm.paymentTypeId) {
          this.createExtendedCardForm.paymentTypeId = '1';
        }
        if (!this.createExtendedCardForm.paymentDirectionId) {
          this.createExtendedCardForm.paymentDirectionId = '1';
        }
        if (!this.createExtendedCardForm.uuid) {
          this.createExtendedCardForm.uuid = this.leadQuoteOrder.uuid;
        }
        if (!this.createExtendedCardForm.userId) {
          this.createExtendedCardForm.userId = this.user.id;
        }
        if (!this.createExtendedCardForm.leadId) {
          this.createExtendedCardForm.leadId = this.leadQuoteOrder.id;
        }

        if (this.newOrSaved == 'new') {
          const response = await ApiClient.leads.payments.cc.post({ body: this.createExtendedCardForm });
          this.disableCreateButton = false;
          if (response.status === 200) {
            this.fetchPayments();
            this.createExtendedCardErrors = {};
            this.createExtendedCardForm = {};
            this.createPaymentExtendedModal = false;
          } else if (response.status === 422) {
            this.createExtendedCardErrors = response.data.errors;
          }
        }

        if (this.newOrSaved != 'new') {
          this.createExtendedCardForm.paymentProfileId = this.newOrSaved;
          const response = await ApiClient.leads.payments.profilepayment.post({ body: this.createExtendedCardForm });
          this.disableCreateButton = false;
          if (response.status === 200) {
            this.fetchPayments();
            this.createExtendedCardErrors = {};
            this.createExtendedCardForm = {};
            this.createPaymentExtendedModal = false;
          } else if (response.status === 422) {
            this.createExtendedCardErrors = response.data.errors;
          }
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    async makeCreateProfilePaymentApiRequest() {
      const isValid = await this.$refs.observerProfilePaymentApiRequest.validate();
      if (isValid) {
        this.disableCreateButton = true;
        this.createProfilePaymentForm.paymentProfileId = this.createProfilePaymentModal;
        if (!this.createProfilePaymentForm.dateReceived) {
          var todayDate = new Date().toISOString().slice(0, 10);
          todayDate.replace('-', '/');
          this.createProfilePaymentForm.dateReceived = todayDate;
        }
        if (!this.createProfilePaymentForm.paymentTypeId) {
          this.createProfilePaymentForm.paymentTypeId = '1';
        }
        if (!this.createProfilePaymentForm.paymentDirectionId) {
          this.createProfilePaymentForm.paymentDirectionId = '1';
        }
        if (!this.createProfilePaymentForm.uuid) {
          this.createProfilePaymentForm.uuid = this.leadQuoteOrder.uuid;
        }
        if (!this.createProfilePaymentForm.userId) {
          this.createProfilePaymentForm.userId = this.user.id;
        }
        if (!this.createProfilePaymentForm.leadId) {
          this.createProfilePaymentForm.leadId = this.leadQuoteOrder.id;
        }
        const response = await ApiClient.leads.payments.profilepayment.post({ body: this.createProfilePaymentForm });
        this.disableCreateButton = false;

        if (response.status === 200) {
          this.fetchPayments();
          this.createProfilePaymentErrors = {};
          this.createProfilePaymentForm = {};
          this.createProfilePaymentModal = false;
        } else if (response.status === 422) {
          this.createProfilePaymentErrors = response.data.errors;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    async makeUpdatePaymentApiRequest() {
      this.disableEditButton = true;
      const response = await ApiClient.payments.put({
        id: this.idOfEditedPayment,
        body: this.editPaymentForm,
      });
      this.disableEditButton = false;

      if (response.status === 200) {
        this.fetchPayments();
        this.editPaymentErrors = {};
        this.editPaymentForm = {};
        this.idOfEditedPayment = null;
      } else if (response.status === 422) {
        this.editPaymentErrors = response.data.errors;
      }
    },
    async fetchPayments() {
      this.paymentsLoading = true;
      const response = await ApiClient.leads.payments.get({ leadId: this.orderId });
      this.payments = response.data.data[0];
      this.preparedPayments = [];
      this.prepPayments(this.payments);
      this.paymentsLoading = false;
    },
    prepPayments(payments) {
      if (payments) {
        payments.payments.forEach(payment => {
          let paymentAgent = '-';
          if (payment.user) {
            paymentAgent = payment.user.name ? payment.user.name : '-';
          }
          if (!payment.userId) {
            paymentAgent = 'System';
          }
          let tempArray = {
            isSuccessful: 1,
            id: payment.id,
            date: payment.dateReceived,
            amount: payment.amount,
            surcharge: payment.surcharge,
            direction: payment.paymentDirection.title,
            type: payment.paymentType.title,
            note: payment.notes,
            agent: paymentAgent,
          };
          this.preparedPayments.push(tempArray);
        });
        payments.cardTransactions.forEach(payment => {
          if (!payment.isSuccessful) {
            let tempArray = {
              isSuccessful: 0,
              id: payment.id,
              date: payment.createdAt,
              amount: payment.amount,
              surcharge: 0,
              direction: '-',
              type: 'Credit Card',
              note: 'Transaction ID: ' + (payment.transactionId ? payment.transactionId : 'N/A') + '// DECLINED',
              agent: 'System',
            };
            this.preparedPayments.push(tempArray);
          }
        });
        this.preparedPayments.sort((a, b) => a.date.localeCompare(b.date));
      }
    },
    confirmDeleting(paymentId) {
      this.idOfPaymentToBeDeleted = paymentId;
    },
    async deletePayment() {
      const id = this.idOfPaymentToBeDeleted;
      this.idOfPaymentToBeDeleted = null;
      // We do an optimisitc UI update and immediately remove the payment
      //this.payments = this.payments.filter(p => p.payment.id !== id);
      this.preparedPayments = this.preparedPayments.filter(p => p.id !== id);
      const response = await apiRequest({
        path: `/payments/${id}`,
        method: 'delete',
      });
      if (response.status != 200) {
        // If something goes wrong - we re-fetch the payments to get UI in consistent state
        this.fetchPayments();
      }
    },
    async deleteSavedCC() {
      const response = await ApiClient.leads.payments.customers.single.delete({ customerId: this.leadQuoteOrder.customer.id, cardId: this.deleteSavedCCModal });
      if (response.status == 200) {
        this.deleteSavedCCModal = false;
        this.newOrSaved = null;
        this.checkCC();
      }
    },
    async checkCC() {
      const response = await ApiClient.leads.payments.customers.get({ leadId: this.orderId });
      if (response.status == 200) {
        if (response.data.data) {
          this.allCards = response.data.data.paymentProfiles;
        }
      }
      if (response.status == 403) {
        this.showPermissionButton = true;
      }
    },
    async makeCreateCardApiRequest() {
      const isValid = await this.$refs.observermakeCreateCardApiRequest.validate();
      if (isValid) {
        if (this.createCardForm.country == null) {
          this.createCardForm.country = 'US';
        }
        const response = await ApiClient.leads.payments.customers.post({ customerId: this.leadQuoteOrder.customer.id, body: this.createCardForm });
        if (response.status == 200) {
          this.createCardModal = false;
          this.createCardErrors = {};
          this.createCardForm = {};
          this.disableSaveButton = true;
          this.checkCC();
        } else if (response.status === 422) {
          this.createCardErrors = response.data.errors;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  padding: 16px;
}
</style>
