<template>
  <div>
    <h1>New order</h1>
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit()">
        <FormFieldsLead
          :form-fields="form"
          :errors="errors"
          @input="handleFormInput"
          render-pricing-form
          render-pickup-contact-form
          render-delivery-contact-form
          render-shipping-form
          :show-customer-search="true"
        />
        <button :disabled="submitDisabled" class="btn btn-primary" type="submit">Save</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FormFieldsLead from '@/components/shared/formFields/FormFieldsLead';
import { ApiClient } from '@/api/ApiClient';

export default {
  name: 'OrderNew',
  components: { FormFieldsLead },
  data() {
    return {
      errors: {},
      customers: [],
      form: {
        customer: {
          contact: {},
        },
        vehicles: [{}],
        pickupLocation: {},
        pickupContact: {},
        deliveryLocation: {},
        deliveryContact: {},
        sourceId: null,
      },
      submitDisabled: false,
    };
  },
  methods: {
    handleFormInput(newForm) {
      this.form = newForm;
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.save();
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    async save() {
      if (this.submitDisabled) return;
      this.submitDisabled = true;
      const body = Object.assign({}, this.form, { lead_type_id: 3, lead_status_id: 3 });
      const response = await ApiClient.leads.post({ body });
      this.submitDisabled = false;
      if (response.status === 200) {
        this.errors = {};
        const newOrder = response.data.data;
        this.$router.push({ name: 'OrderDetails', params: { id: newOrder.id } });
      } else if (response.status === 422) {
        this.errors = response.data.errors;
      }
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
