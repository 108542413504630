import LayoutCentered from '@/layout/LayoutCentered';
import AccountTabs from '@/components/pages/accounts/account/AccountTabs';

export default [
  {
    // Layout 2
    path: '/account',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'customers',
    },
    children: [
      /* redirections */
      {
        path: '',
        beforeEnter: (to, from, next) => {
          next('/accounts/shippers');
        },
      },
      /* / redirections */
      {
        component: AccountTabs,
        path: '',
        beforeEnter: (to, _, next) => {
          if (Object.prototype.hasOwnProperty.call(to.query, 'id')) next();
          else next('/accounts/shippers');
        },
        children: [
          {
            path: 'details',
            component: () => import('@/components/pages/accounts/account/accountDetail/AccountDetail'),
          },
          {
            path: 'edit',
            component: () => import('@/components/pages/accounts/account/accountCreateEdit/AccountCreateEdit'),
          },
          {
            path: 'account-history',
            component: () => import('@/components/pages/accounts/account/accountHistory/AccountHistory'),
          },
          {
            path: 'order-history',
            component: () => import('@/components/pages/accounts/account/orderHistory/OrderHistory'),
          },
          {
            path: 'carrier-history',
            component: () => import('@/components/pages/accounts/account/carrierHistory/CarrierHistory'),
          },
        ],
      },
    ],
  },
];
