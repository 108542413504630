import LayoutCentered from '@/layout/LayoutCentered';
import VehiclesTabs from '@/components/pages/vehicles/VehiclesTabs';

export default [
  {
    // Layout 2
    path: '/vehicles',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'admin',
    },
    children: [
      {
        component: VehiclesTabs,
        path: '',
        children: [
          /* redirections */
          {
            path: '',
            beforeEnter: (to, from, next) => {
              next('/vehicles/types');
            },
          },
          /* / redirections */
          {
            path: 'types',
            component: () => import('@/components/pages/vehicles/vehiclesType/VehiclesType'),
            name: 'vehicle.types',
          },
          {
            path: 'makes',
            component: () => import('@/components/pages/vehicles/vehiclesMake/VehiclesMake'),
            name: 'vehicle.makes',
          },
          {
            path: 'models',
            component: () => import('@/components/pages/vehicles/vehiclesModel/VehiclesModel'),
            name: 'vehicle.models',
          },
        ],
      },
    ],
  },
];
