<template>
  <div class="main-wrapper">
    <div v-for="(vehicle, index) in formFields" :key="vehicle.id" @keydown.enter.prevent>
      <div v-if="!vehicle._delete" class="vehicle-form-grid">
        <ValidatedInput
          rules="required"
          label="Year"
          :id="`vehicleYear-${index}`"
          :value="vehicle.year"
          :errors="errorResolver({ index, field: 'year' })"
          @input="newValue => handleInputChange({ index, field: 'year', newValue })"
        />
        <Typeahead
          :item-key="'id'"
          :item-value="'name'"
          :request="makeTypeahead(index)"
          :ref="'make' + index"
          @hit="val => handleInputChange({ index, field: 'make', newValue: val.name, isTypeahead: true })"
        >
          <ValidatedInput
            rules="required"
            label="Make"
            :id="`vehicleMake-${index}`"
            text-transform="capitalize"
            :value="vehicle.make"
            :errors="errorResolver({ index, field: 'make' })"
            @keydown.down="refAction('make', index, 'down')"
            @keydown.up="refAction('make', index, 'up')"
            @keydown.enter="refAction('make', index, 'hit')"
            @keydown.esc="refAction('make', index, 'reset')"
            @blur="refAction('make', index, 'reset'); refAction('make', index, 'cancel')"
            @input="newValue => handleInputChange({ index, field: 'make', newValue })"
          />
        </Typeahead>
        <Typeahead
          :item-key="'model'"
          :item-value="'model'"
          :request="modelTypeahead(index)"
          :ref="'model' + index"
          @hit="val => handleInputChange({ index, field: 'model', newValue: val.model, isTypeahead: true })"
        >
          <ValidatedInput
            rules="required"
            label="Model"
            :id="`vehicleModel-${index}`"
            text-transform="capitalize"
            :value="vehicle.model"
            :errors="errorResolver({ index, field: 'model' })"
            @keydown.down="refAction('model', index, 'down')"
            @keydown.up="refAction('model', index, 'up')"
            @keydown.enter="refAction('model', index, 'hit')"
            @keydown.esc="refAction('model', index, 'reset')"
            @blur="refAction('model', index, 'reset'); refAction('model', index, 'cancel');"
            @input="newValue => handleInputChange({ index, field: 'model', newValue })"
          />
        </Typeahead>
        <ValidatedInput
          label="Color"
          :id="`color-${index}`"
          text-transform="capitalize"
          :value="vehicle.color"
          :errors="errorResolver({ index, field: 'color' })"
          @input="newValue => handleInputChange({ index, field: 'color', newValue })"
        />
        <div class="form-group">
          <label :for="`vehicleTypeId-${index}`">Vehicle type*</label>
          <ValidationProvider name="Vehicle Type" rules="required" v-slot="{ errors }">
            <select
              :id="`vehicleTypeId-${index}`"
              v-model="vehicle.vehicleTypeId"
              :value="vehicle.vehicleTypeId"
              :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              @input="e => handleInputChange({ index, field: 'vehicleTypeId', newValue: e.target.value })"
            >
              <option v-for="type in vehicleTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
            </select>
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label :for="`vehicleIsInoperable-${index}`">Condition*</label>
          <ValidationProvider name="Condition" rules="required" v-slot="{ errors }">
            <select
              :id="`vehicleIsInoperable-${index}`"
              :value="vehicle.isInoperable"
              :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              @input="e => handleInputChange({ index, field: 'isInoperable', newValue: e.target.value })"
            >
              <option value="0">Operable</option>
              <option value="1">Not operable</option>
            </select>
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <ValidatedInput
          rules="required|min:2"
          mask="price"
          label="Price offered"
          :id="`priceOffered-${index}`"
          :value="vehicle.price"
          :errors="errorResolver({ index, field: 'price' })"
          @input="newValue => handleInputChange({ index, field: 'price', newValue })"
        />
        <ValidatedInput
          v-if="!hideDeposit"
          rules="required|min:2"
          mask="price"
          label="Deposit"
          :id="`deposit-${index}`"
          :value="vehicle.deposit"
          :errors="errorResolver({ index, field: 'deposit' })"
          @input="newValue => handleInputChange({ index, field: 'deposit', newValue })"
        />
        <ValidatedInput
          label="VIN"
          :id="`vin-${index}`"
          :value="vehicle.vin"
          text-transform="uc"
          :errors="errorResolver({ index, field: 'vin' })"
          @input="newValue => handleInputChange({ index, field: 'vin', newValue })"
        />
        <ValidatedInput
          label="Lot number"
          :id="`lot_number-${index}`"
          :value="vehicle.lotNumber"
          :errors="errorResolver({ index, field: 'lotNumber' })"
          @input="newValue => handleInputChange({ index, field: 'lotNumber', newValue })"
        />
        <ValidatedInput
          label="Plate number"
          :id="`plateNumber-${index}`"
          :value="vehicle.plateNumber"
          :errors="errorResolver({ index, field: 'plateNumber' })"
          @input="newValue => handleInputChange({ index, field: 'plateNumber', newValue })"
        />
        <div class="form-group form-actions" v-if="index > 0">
          <button @click.prevent="() => handleDeleteClick(index)" class="btn btn-outline-danger">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="actions">
      <button @click="handleAddClick" type="button" class="btn btn-secondary">Add vehicle</button>
    </div>
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';
import Typeahead from '@/components/shared/Typeahead';
import { apiRequest } from '@/api/apiRequest';
import { ApiClient } from '@/api/ApiClient';

export default {
  data() {
    return {
      vehicleTypes: [],
    };
  },
  components: {
    ValidatedInput,
    Typeahead,
  },
  props: {
    formFields: {
      type: Array,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
    hideDeposit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInputChange({ index, field, newValue, isTypeahead }) {
      let oldValue = this.formFields[index][field];
      if((field == 'deposit' || field == 'price' || field == 'year') && newValue == '') newValue = null;
      const newFormFields = this.formFields.map((vehicle, currentIndex) => {
        if (currentIndex != index) {
          return vehicle;
        } else {
          const newVehicle = Object.assign({}, vehicle);
          newVehicle[field] = newValue;
          return newVehicle;
        }
      });
      this.$emit('input', newFormFields);

      if (oldValue != newValue && !isTypeahead) {
        if (field == 'make' || field == 'model') {
          // ensure formFields coming from parent are updated
          this.$nextTick(() => {
            this.refAction(field, index, newValue ? 'update' : 'reset');
          });
        }
      }
      if (field == 'vin') {
        if (newValue.length == 17) {
          this.checkVIN(newValue, index);
        }
      }
    },
    async checkVIN(vin, index) {
      const response = await apiRequest({ path: `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json` });
      let vinData = response.data.Results[0];
      if (vinData.ErrorCode == '0') {
        let year = vinData.ModelYear;
        let make = vinData.Make;
        let model = vinData.Model;
        this.formFields[index].make = make;
        this.formFields[index].model = model;
        this.formFields[index].year = year;
        const newFormFields = this.formFields;
        this.$emit('input', newFormFields);
      } else {
        console.log('VIN ERROR');
      }
    },
    refAction(field, index, action) {
      return this.$refs[field + index][0][action]();
    },
    handleAddClick() {
      const newFormFields = this.formFields.concat([{ isInoperable: '0' }]);
      this.$emit('input', newFormFields);
    },
    handleDeleteClick(index) {
      const vehicleToRemove = Object.assign({}, this.formFields[index]);
      // If vehicle exists on the back-end, we just mark it for deletion
      // if it does not (when user just clicked 'Add vehicle' button), then we remove it completely from the UI.
      if (vehicleToRemove.id) {
        vehicleToRemove._delete = true;
        const newFormFields = this.formFields.map((v, i) => (index === i ? vehicleToRemove : v));
        this.$emit('input', newFormFields);
      } else {
        const newFormFields = this.formFields.filter((_, i) => i != index);
        this.$emit('input', newFormFields);
      }
    },
    makeTypeahead(index) {
      return () => ApiClient.vehicles.makes.get({ params: { make: this.formFields[index].make, year: this.formFields[index].year } });
    },
    modelTypeahead(index) {
      return () => ApiClient.vehicles.models.get({ params: { make: this.formFields[index].make, model: this.formFields[index].model, year: this.formFields[index].year } });
    },
  },
  async mounted() {
    const response = await apiRequest({
      path: '/vehicle-types',
    });
    if (response.status === 200) {
      this.vehicleTypes = response.data.data;
    }
  },
};
</script>

<style lang="scss" scoped>
.vehicle-form-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dotted grey;
}

.form-fields-group {
  border: 2px solid #eee;
  padding: 16px 16px 0 16px;
}

.form-actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.actions {
  padding: 16px 0 0 0;
}
</style>
