<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="onClose">
      <div class="modal-dialog modal-dialog-centered" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="onClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body">
            <ValidatedSelect
              rules="required"
              label="Cancel Reason"
              id="cancelReason"
              v-model="reason"
              :options="cancelReasons"
            />
            <b-form-input :class="{ 'd-none': reason != 10 }" placeholder="Reason for cancellation:" required v-model="otherReason"></b-form-input>
            <div class="text-right">
              <b-button @click="markCancel" type="button" class="mt-3" variant="danger">Confirm Cancellation</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import cancelReasonsMixin from '@/mixins/cancelReasons';
export default {
  name: 'WhyCancelModal',
  components: {
    ValidatedSelect,
  },
  mixins: [cancelReasonsMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    leadId: {
      type: Number,
      default: null,
    },
    leadTypeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      reason: null,
      otherReason: null,
    };
  },
  computed: {
    isQuote() {
      if (this.leadTypeId == 2) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async markCancel() {
      /*
      var cancelReason = this.reason;
      if (this.reason == "Other") {
        cancelReason = this.otherReason;
      }
      var leadType = this.isQuote ? 'Quote' : 'Order';
      var note =  leadType + " Cancelled // REASON: " + cancelReason;
      await ApiClient.leads.notes.post({ leadId: this.leadId, body: { note: note } });
       */
      const response = await ApiClient.leads.cancel({ id: this.leadId, body: { cancelReasonId: this.reason, cancelReasonNote: this.otherReason } } );
      if (response.status === 200) {
        if (this.isQuote) {
          this.$router.push({ name: 'QuotesArchived' });
        } else {
          this.$router.push({ name: 'OrdersArchived' });
        }
      }
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}

.modal-body {
  background-color: #fafafa;
}
</style>
