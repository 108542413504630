<template>
  <div class="emails-list">
    <LoadingIndicator v-if="loading" centered />
    <div v-if="!loading">
      <table v-if="emails.length > 0" class="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Sender</th>
            <th>Recipient</th>
            <th>Subject</th>
            <th>Sent At</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in emails" :key="email.id">
            <td>{{ email.user ? email.user.name : 'System' }}</td>
            <td>{{ email.fromAddress }}</td>
            <td>{{ email.toAddress }}</td>
            <td>{{ email.subject }}</td>
            <td>{{ email.sentAt | formatDateTime }}</td>
            <td>
              <div v-if="email.openedAt">Opened: {{ email.openedAt | formatDateTime }}</div>
              <div v-if="email.clickedAt">Clicked: {{ email.clickedAt | formatDateTime }}</div>
              <div v-if="email.unsubscribedAt">Unsubscribed: {{ email.unsubscribedAt | formatDateTime }}</div>
              <div v-if="email.spamComplaintAt">Spam complaint: {{ email.spamComplaintAt | formatDateTime }}</div>
              <div v-if="email.bouncedAt">Bounced: {{ email.bouncedAt | formatDateTime }}</div>
              <div v-if="email.failedAt">Failed: {{ email.failedAt | formatDateTime }}</div>
              <div v-if="email.deliveredAt">Delivered: {{ email.deliveredAt | formatDateTime }}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-info">No emails have been sent yet.</div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  props: {
    leadlikeId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      emails: [],
      loading: false,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await ApiClient.emails.get({ params: { leadId: this.leadlikeId } });
      this.loading = false;
      this.emails = response.data.data;
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    leadlikeId: function () {
      this.fetch();
    },
  },
};
</script>
