import LayoutCentered from '@/layout/LayoutCentered';

export default [
  {
    // Layout 2
    path: '/profile',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      /* redirections */
      {
        path: '',
        beforeEnter: (to, from, next) => {
          next('/profile/details');
        },
      },
      /* / redirections */
      {
        path: '',
        component: () => import('@/components/pages/profile/ProfileTabs'),
        children: [
          {
            path: 'details',
            component: () => import('@/components/pages/profile/profileDetails/ProfileDetails'),
          },
          {
            path: 'edit',
            component: () => import('@/components/pages/profile/editProfile/EditProfile'),
          },
          {
            path: 'templates/email',
            component: () => import('@/components/pages/profile/templates/EmailTemplates'),
          },
          {
            path: 'templates/sms',
            component: () => import('@/components/pages/profile/templates/SmsTemplates'),
          },
          {
            path: 'preferences',
            component: () => import('@/components/pages/profile/preferences/Preferences'),
          },
        ],
      },
    ],
  },
];
