import LayoutCentered from '@/layout/LayoutCentered';

export default [
  {
    // Layout 2
    path: '/',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/components/Home'),
      },
    ],
  },
];
