<template>
  <div class="position-relative">
    <div v-if="loading" class="loading-overlay">
      <LoadingIndicator centered />
    </div>

    <table class="table">
      <thead class="table">
        <tr>
          <th class="px-0" scope="col"><b-form-checkbox @input="toggle" v-model="rowToggle" size="sm" class="my-0" /></th>
          <TableSortableHeader class="px-0 text-center" label="Id" field="id" />
          <TableSortableHeader label="Created At" field="createdAt" />
          <TableSortableHeader :label="selectedStatus.label" :field="selectedStatus.sort" />
          <th scope="col">Customer</th>
          <th scope="col">Vehicles</th>
          <th scope="col">Route</th>
          
          <th scope="col">Price</th>
          <th scope="col">User</th>
        </tr>
      </thead>
      <tbody :class="{ 'table-loading': loading }">
        <tr v-for="lqo in items" :key="lqo.id" :class="rowClassName(lqo)">
          <td class="px-0">
            <b-form-checkbox v-model="selectedRows" :key="lqo.id" :value="lqo.id" size="sm" class="my-0" />
          </td>
          <td class="px-0 text-center">
            <b-link :to="{ name: routeForIndividualItem, params: { id: lqo.id } }">#{{ lqo.id }}</b-link>
            <div class="text-tiny" v-for="task in lqo.tasksDue.filter(x => x.isFlag)" :key="task.id">{{ task.note }}</div>
          </td>
          <td>
            {{ lqo.createdAt | formatDate }}
          </td>
          <td>
            {{ lqo[selectedStatus.sort_by] | formatDate }}
          </td>
          <td>
            <div v-if="lqo.customer.contact.firstName || lqo.customer.contact.lastName">{{ lqo.customer.contact.firstName }} {{ lqo.customer.contact.lastName }}</div>
            <div v-if="lqo.customer.contact.company">{{ lqo.customer.contact.company }}</div>
          </td>
          <td>
            <div v-if="lqo.vehicles.length <= 2">
              <a v-for="vehicle in lqo.vehicles" :key="vehicle.id" class="vehicle-link" :href="vehicleSearchLink(vehicle)" target="_blank" rel="noreferrer noopener">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </a>
            </div>
            <div v-else-if="lqo.vehicles.length > 2">Multiple Vehicles</div>
          </td>
          <td>
            <a href="#" @click="handleRouteClicked(lqo)">
              <LeadlikeTableLocation :location="lqo.pickupLocation" />
              <span class="mr-1 fas fa-arrow-right"></span>
              <LeadlikeTableLocation :location="lqo.deliveryLocation" />
            </a>
          </td>
          <td v-html="getBalance(lqo)" class="balance"></td>
          <td>
            {{ lqo.user ? lqo.user.name : '-' }}
          </td>
        </tr>
      </tbody>
    </table>

    <RouteMapModal id="cardRouteModal" :route="routeInfo" />
  </div>
</template>

<script>
import TableSortableHeader from '@/components/shared/TableSortableHeader';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import LeadlikeTableLocation from '@/components/shared/LeadlikeTableLocation';
import RouteMapModal from '@/components/shared/route/RouteMapModal';

const orderStatuses = [
  { title: 'Orders', id: 3, sort: 'move_date', sort_by: 'moveDate', label: 'Move Date' },
  { title: 'Posted CD', id: 6, sort: 'move_date', sort_by: 'moveDate', label: 'Move Date' },
  { title: 'Not signed', id: 7, sort: 'dispatched_at', sort_by: 'dispatchedAt', label: 'Dispatched Date' },
  { title: 'Dispatched', id: 8, sort: 'signed_at', sort_by: 'signedAt', label: 'Signed Date' },
  { title: 'Picked-Up', id: 11, sort: 'pickup_at', sort_by: 'pickupAt', label: 'Picked Up' },
  { title: 'Delivered', id: 12, sort: 'delivery_at', sort_by: 'deliveryAt', label: 'Delivered Date' },
  { title: 'Issues', id: 9, sort: 'issues_at', sort_by: 'issuesAt', label: 'Reported Date' },
  { title: 'Hold', id: 4, sort: 'on_hold_At', sort_by: 'onHoldAt', label: 'Hold Date' },
  { title: 'Archive', id: 'archived', sort: 'deleted_at', sort_by: 'deletedAt', label: 'Archive Date' },
];

export default {
  name: 'LeadlikeTable',
  components: {
    LeadlikeTableLocation,
    LoadingIndicator,
    TableSortableHeader,
    RouteMapModal,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    routeForIndividualItem: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tablestatus: {
      type: [Number, String],
      default: 3,
    },
  },
  computed: {
    selectedStatus() {
      return this.statuses.find(s => s.id == this.tablestatus);
    },
  },
  data() {
    return {
      leadForRouteModal: null,
      statuses: orderStatuses,
      selectedRows: [],
      rowToggle: false,
      routeInfo: { id: null, origin: null, destination: null, infoHtml: null },
    };
  },
  methods: {
    rowClassName(lqo) {
      if(lqo.ndpOrderId) return 'text-success';
      return lqo.tasksDue.length ? 'text-danger' : '';
    },
    toggle(checked) {
      if (checked) {
        this.selectedRows = this.items.map(x => x.id);
      } else {
        this.selectedRows = [];
      }
    },
    handleRouteClicked(lead) {
      const { id, pickupLocation, deliveryLocation } = lead;
      this.routeInfo = {
        id,
        origin: `${pickupLocation.city}, ${pickupLocation.state}`,
        destination: `${deliveryLocation.city}, ${deliveryLocation.state}`,
        infoHtml: `(A) ${pickupLocation.city}, ${pickupLocation.state.toUpperCase()}, ${pickupLocation.zip}<i class="mx-1 fa-lg fas fa-shipping-fast"></i>(B) ${
          deliveryLocation.city
        }, ${deliveryLocation.state.toUpperCase()}, ${deliveryLocation.zip}`,
      };
      this.$bvModal.show('cardRouteModal');
    },
    vehicleSearchLink(vehicle) {
      return `https://www.google.com/search?tbm=isch&q=${vehicle.year} ${vehicle.make} ${vehicle.model}`;
    },
    getBalance(lqo) {
      var tariff = lqo.vehicles?.map(v => v.price).reduce((total, p) => total + (parseFloat(p) || 0), 0) || 0;
      var deposit = lqo.vehicles?.map(v => v.deposit).reduce((total, p) => total + (parseFloat(p) || 0), 0) || 0;
      var carrierPay = parseInt(lqo.carrierPay) || 0;
      var html = `<div>Tariff: $${tariff} </div><div>Deposit: $${deposit} </div><div>Cpay: $${carrierPay} </div>`;
      return html;
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-link {
  display: block;
}
.table-loading {
  opacity: 0.5;
}
.loading-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
}
tbody td {
  padding: 5px;
  /*min-height: 85px;*/
}
td {
  vertical-align: middle;
}
.balance {
  font-size: 12px;
}
.text-danger a, .text-success a {
  color: inherit;
}
</style>
