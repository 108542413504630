export default {
  methods: {
    getIsInvalidForm(nestedKeys = []) {
      const { $touch, $invalid, $pending, $anyError } = this.getValidationObj(nestedKeys);
      $touch();
      return $pending || $invalid || $anyError;
    },
    getValidationState(nestedKeys) {
      const { $dirty, $error } = this.getValidationObj(nestedKeys);
      return $dirty ? !$error : null;
    },
    getValidationObj(nestedKeys) {
      return nestedKeys.reduce((acc, curr) => {
        return acc[curr];
      }, this.$v);
    },
  },
};
