<template>
  <div>
    <div class="card mb-4 suggest">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="mb-0">Customer contact</h5>
      </div>
      <div class="card-body">
        <FormFieldsContact
          label="Customer"
          :form-fields="(formFields.customer && formFields.customer.contact) || {}"
          :error-resolver="field => errors[`customer.contact.${field}`]"
          @input="handleCustomerContactChange"
        />
        <div class="mt-2 suggest-lists" v-if="showCustomerList && showCustomerSearch">
          <CustomerList
            v-if="showCustomerList && showCustomerSearch"
            :search="customerSearchQuery"
            :on-close="() => (showCustomerList = false)"
            @fillField="customerFill($event)"
          />
        </div>
      </div>
    </div>
    <div class="grid--two-column">
      <div>
        <div class="card mb-4">
          <h5 class="card-header">Pickup location</h5>
          <div class="card-body">
            <FormFieldsLocation
              label="Pickup"
              :form-fields="formFields.pickupLocation"
              :error-resolver="field => errors[`pickupLocation.${field}`]"
              @input="handlePickupLocationChange"
            />
          </div>
          <div v-if="renderPickupContactForm">
            <h5 class="card-header">Pickup contact</h5>
            <div class="card-body">
              <FormFieldsContact
                label="Pickup"
                layout="vertical"
                :form-fields="formFields.pickupContact || {}"
                :error-resolver="field => errors[`pickupContact.${field}`]"
                @input="handlePickupContactChange"
              />
              <ValidatedInput
                label="Buyer #"
                id="auctionBuyerNumber"
                :value="formFields.auctionBuyerNumber"
                @input="newValue => handleFieldChange('auctionBuyerNumber', newValue)"
                :errors="errors['auctionBuyerNumber']"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="card mb-4">
          <h5 class="card-header">Delivery location</h5>
          <div class="card-body">
            <FormFieldsLocation
              label="Delivery"
              :form-fields="formFields.deliveryLocation"
              :error-resolver="field => errors[`deliveryLocation.${field}`]"
              @input="handleDeliveryLocationChange"
            />
          </div>
          <div v-if="renderDeliveryContactForm">
            <h5 class="card-header">Delivery contact</h5>
            <div class="card-body">
              <FormFieldsContact
                label="Delivery"
                layout="vertical"
                :form-fields="formFields.deliveryContact || {}"
                :error-resolver="field => errors[`deliveryContact.${field}`]"
                @input="handleDeliveryContactChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4" v-if="renderShippingForm">
      <h5 class="card-header">Shipping info</h5>
      <div class="card-body">
        <div class="grid--two-column">
          <div class="form-group">
            <label>Pickup Window*</label>
            <ValidationProvider name="Pickup Window" rules="required" v-slot="{ errors }">
              <div :class="{ 'flat-invalid': errors[0] }">
                <flat-pickr
                  class="form-control"
                  id="moveDate"
                  :config="fltConfig"
                  :value="movingDate"
                  :errors="errors['moveDate']"
                  @input="newValue => handleFieldChange('moveDate', newValue)"
                />
              </div>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <input
            type="hidden"
            id="moveDateLast"
            :value="formFields.moveDateLast"
            @input="newValue => handleFieldChange('moveDateLast', newValue)"
            :errors="errors['moveDateLast']"
          />
          <ValidatedSelect
            rules="required"
            label="Transport type"
            id="transportType"
            :value="formFields.transportTypeId"
            :options="transportTypes"
            @input="newValue => handleFieldChange('transportTypeId', newValue)"
            :errors="errors['transportTypeId']"
          />
        </div>
        <div class="grid--two-column" v-if="!isLeadRules">
          <ValidatedInput
            type="textarea"
            label="Notes from shipper"
            id="shipperNotes"
            :value="formFields.shipperNotes"
            @input="newValue => handleFieldChange('shipperNotes', newValue)"
            :errors="errors['shipperNotes']"
          />
          <ValidatedInput
            type="textarea"
            label="Information for shipper"
            id="shipperInformation"
            :value="formFields.shipperInformation"
            @input="newValue => handleFieldChange('shipperInformation', newValue)"
            :errors="errors['shipperInformation']"
          />
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <h5 class="card-header">Vehicles</h5>
      <div class="card-body">
        <FormFieldsVehicles
          :hide-deposit="hideCodcop"
          :form-fields="formFields.vehicles || []"
          :error-resolver="({ index, field }) => errors[`vehicles.${index}.${field}`]"
          @input="handleVehiclesChange"
        />
      </div>
    </div>
    <div class="card mb-4" v-if="renderPricingForm">
      <h5 class="card-header">Pricing information</h5>
      <div class="card-body">
        <div class="grid--two-column">
          <div class="grid--two-column">
            <div class="form-group">
              <label>Tariff</label>
              <div>{{ !isFeeDisabled && hideCodcop ? priceFormat(totalPriceOffered + totalPriceOffered * 0.05) : priceFormat(totalPriceOffered) }}</div>
            </div>
            <div class="form-group" v-if="hideCodcop">
              <label>Processing Fee</label>
              <div>{{ isFeeDisabled ? '$0' : priceFormat(totalPriceOffered * 0.05) }}</div>
            </div>
          </div>
          <div @keydown.enter.prevent>
            <ValidatedInput
              label="Carrier pay"
              id="carrierPay"
              mask="price"
              :value="formFields.carrierPay"
              @input="newValue => handleFieldChange('carrierPay', newValue)"
              :errors="errors['carrierPay']"
            />
          </div>
          <ValidatedSelect
            rules="required"
            label="Balance Paid By"
            id="balanceMethod"
            :value="formFields.balanceMethodId"
            :options="balanceMethods"
            @input="newValue => handleFieldChange('balanceMethodId', newValue)"
            :errors="errors['balanceMethodId']"
          />
          <ValidatedSelect
            :rules="requireCodcop"
            :is-hidden="hideCodcop"
            label="COD/COP Method"
            id="balanceCodcopMethod"
            :value="formFields.balanceCodcopMethodId"
            :options="balanceCodcopMethods"
            @input="newValue => handleFieldChange('balanceCodcopMethodId', newValue)"
            :errors="errors['balanceCodcopMethodId']"
          />
          <div v-if="hideCodcop" class="form-group">
            <div class="mt-4 pt-3">
              <input type="checkbox" id="checkbox" v-model="isFeeDisabled" @input="newValue => handleFieldChange('disableProcessingFee', checkFeeDisabled)" />
              <label for="checkbox">Do not auto-calculate Processing Fee</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4" v-if="!isOrder">
      <div class="card-body">
        <b-form-group label="Auto campaigns"><b-radio-group v-model="isCampaignDisabled" :options="campaignOptions" @input="newValue => handleFieldChange('isCampaignDisabled', newValue)" /></b-form-group>
      </div>
    </div>
    <div class="card mb-4" v-if="tokenCan('orders:assign') || isOrderSourceVisible(formFields.sourceId)">
      <h5 class="card-header">Admin</h5>
      <div class="card-body">
        <div class="grid--two-column">
          <div class="form-group" v-if="tokenCan('orders:assign')">
            <label>Assigned user</label>
            <UserDropdown @select="newValue => handleFieldChange('userId', newValue)" :value="formFields.userId" />
          </div>
          <div class="form-group" v-if="isOrderSourceVisible(formFields.sourceId)">
            <ValidatedSelect
              label="Where is this customer from?"
              rules="required"
              id="sourceId"
              :value="formFields.sourceId"
              :options="visibleOrderSources"
              @input="newValue => handleFieldChange('sourceId', newValue)"
              :errors="errors['sourceId']"
            />
          </div>
        </div>
      </div>
    </div>
    <FormFieldsErrors :errors="errors" />
    <InfoModal 
      v-if="showInfoModal" 
      @close="showInfoModal = false" 
      :label="'Please don\'t forget to go the payments tab and add a new CC for this order'"
    />
  </div>
</template>

<script>
import UserDropdown from '@/components/shared/UserDropdown';
import ValidatedInput from '@/components/shared/ValidatedInput';
import FormFieldsContact from '@/components/shared/formFields/FormFieldsContact';
import FormFieldsLocation from '@/components/shared/formFields/FormFieldsLocation';
import FormFieldsVehicles from '@/components/shared/formFields/FormFieldsVehicles';
import FormFieldsErrors from '@/components/shared/formFields/FormFieldsErrors';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import CustomerList from '@/components/shared/CustomerList';
import InfoModal from '@/components/shared/InfoModal';
import dateTypesMixin from '@/mixins/dateTypes';
import balanceTermStartOptionsMixin from '@/mixins/balanceTermStartOptions';
import balanceTermsMixin from '@/mixins/balanceTerms';
import balancePaymentMethodsMixin from '@/mixins/balancePaymentMethods';
import balanceMethodsMixin from '@/mixins/balanceMethods';
import balanceCodcopMethodsMixin from '@/mixins/balanceCodcopMethods';
import orderSourcesMixin from '@/mixins/orderSources';
import transportTypesMixin from '@/mixins/transportTypes';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import { debounce } from 'debounce';

export default {
  components: {
    UserDropdown,
    FormFieldsLocation,
    FormFieldsContact,
    FormFieldsVehicles,
    ValidatedInput,
    FormFieldsErrors,
    ValidatedSelect,
    flatPickr,
    CustomerList,
    InfoModal,
  },
  mixins: [
    dateTypesMixin,
    balanceTermStartOptionsMixin,
    balanceTermsMixin,
    balancePaymentMethodsMixin,
    balanceMethodsMixin,
    balanceCodcopMethodsMixin,
    orderSourcesMixin,
    transportTypesMixin,
    permissionsMixin,
  ],
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    renderPickupContactForm: {
      type: Boolean,
      default: false,
    },
    renderDeliveryContactForm: {
      type: Boolean,
      default: false,
    },
    renderPricingForm: {
      type: Boolean,
      default: false,
    },
    renderShippingForm: {
      type: Boolean,
      default: false,
    },
    showCustomerSearch: {
      type: Boolean,
      default: false,
    },
    isLeadRules: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var self = this;
    return {
      customers: [],
      showCustomerList: false,
      searchDebounced: null,
      customerSearchQuery: [],
      date: null,
      movingDate: null,
      hideCodcop: false,
      isFeeDisabled: null,
      showCustomerSearchModal: false,
      requireCodcop: '',
      showInfoModal: false,
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
        onChange: function (dates) {
          self.prepareDates(dates);
        },
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        },
      },
      campaignOptions : [
        { text: 'Enabled', value: 0 },
        { text: 'Disabled', value: 1 },
      ],
      isCampaignDisabled: this.formFields.isCampaignDisabled,
    };
  },
  watch: {
    'formFields.moveDate': function () {
      this.movingDate = this.formFields.moveDateLast ? [this.formFields.moveDate, this.formFields.moveDateLast] : this.formFields.moveDate;
    },
    'formFields.balanceMethodId': function () {
      this.hideCodcop = this.formFields.balanceMethodId == '3' ? true : false;
      this.requireCodcop = this.formFields.balanceMethodId == '3' ? '' : 'required';
      if (this.formFields.balanceMethodId == 3) {
        this.handleFieldChange('balanceCodcopMethodId', null);
        this.formFields.vehicles.forEach((value, index) => {
          this.formFields.vehicles[index].deposit = 0;
        });
      }
    },
    'formFields.disableProcessingFee': function () {
      if (this.formFields.disableProcessingFee == 1) {
        this.isFeeDisabled = true;
      } else {
        this.isFeeDisabled = false;
      }
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
    handleCustomerContactChange(contact, search) {
      const customer = Object.assign({}, this.formFields.customer, { contact });
      this.handleFieldChange('customer', customer);
      if (search.field == 'firstName' || search.field == 'email' || search.field == 'phone') {
        this.startSearch(search);
      }
    },
    handlePickupContactChange(pickupContact) {
      this.handleFieldChange('pickupContact', pickupContact);
    },
    handleDeliveryContactChange(deliveryContact) {
      this.handleFieldChange('deliveryContact', deliveryContact);
    },
    handlePickupLocationChange(pickupLocation) {
      this.handleFieldChange('pickupLocation', pickupLocation);
    },
    handleDeliveryLocationChange(deliveryLocation) {
      this.handleFieldChange('deliveryLocation', deliveryLocation);
    },
    handleVehiclesChange(vehicles) {
      this.handleFieldChange('vehicles', vehicles);
    },
    handleFieldChange(field, value) {
      console.log(field,value);
      const newFormFields = Object.assign({}, this.formFields, { [field]: value });
      this.$emit('input', newFormFields);
    },
    customerFill(infos) {
      this.formFields.customer_id = infos.customerId;
      this.formFields.customer.contact.firstName = infos.firstName;
      this.formFields.customer.contact.lastName = infos.lastName;
      this.formFields.customer.contact.company = infos.company;
      this.formFields.customer.contact.email = infos.email;
      this.formFields.customer.contact.phone = infos.phone;
      this.formFields.customer.contact.phone2 = infos.phone2;
      this.formFields.customer.contact.cellPhone = infos.cellPhone;
      this.showInfoModal = true;
    },
    prepareDates(dates) {
      var firsDate = document.getElementById('moveDate');
      var lastDate = document.getElementById('moveDateLast');
      if (dates[1]) {
        firsDate.value = moment(dates[0]).format('YYYY-MM-DD');
        lastDate.value = moment(dates[1]).format('YYYY-MM-DD');
        this.handleFieldChange('moveDateLast', moment(dates[1]).format('YYYY-MM-DD'));
      } else {
        lastDate.value = '';
        this.handleFieldChange('moveDateLast', null);
      }
    },
    priceFormat(price) {
      var formattedPrice = Number(price).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      });
      return formattedPrice;
    },
    isOrderSourceVisible(id) {
      return this.tokenCan('orders:source') && (!id || this.orderSources.some(x => x.value == id && x.visible));
    },
    startSearch(search) {
      this.searchDebounced(search);
      return;
    },
    stopSearch(query) {
      this.showCustomerList = true;
      if (this.customerSearchQuery.length == 0) {
        this.customerSearchQuery.push(query);
        return;
      }
      if (this.customerSearchQuery.length != 0) {
        this.customerSearchQuery.forEach(function (item, index, object) {
          if (item.field == query.field) {
            object.splice(index, 1);
          }
        });
        this.customerSearchQuery.push(query);
        return;
      }
    },
  },
  computed: {
    isOrder() {
      return this.formFields.leadTypeId == 3;
    },
    totalPriceOffered() {
      return this.formFields.vehicles?.map(v => v.price).reduce((total, p) => total + (parseFloat(p) || 0), 0) || 0;
    },
    checkFeeDisabled() {
      return this.isFeeDisabled == true ? 0 : 1;
    },
    visibleOrderSources() {
      return this.orderSources.filter(x => x.visible);
    },
  },
  mounted() {
    this.searchDebounced = debounce(function (search) {
      this.stopSearch(search);
    }, 800);
    this.movingDate = this.formFields.moveDateLast ? [this.formFields.moveDate, this.formFields.moveDateLast] : this.formFields.moveDate;
  },
};
</script>

<style lang="scss" scoped>
.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
}

.flat-invalid {
  border: 1px solid #d9534f;
  border-radius: 0.25rem;
}

.suggest {
  position: relative;
}
.suggest-lists {
  position: absolute;
  background-color: #fff;
  border: 1px solid #00000047;
  box-shadow: 0 20px 15px 11px #0000002e;
  width: calc(100% + 2px);
  margin-left: -1px;
  top: 97%;
  border-radius: 6px;
  left: 0;
  z-index: 9;
}
</style>
