<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Reports</h1>
    </div>
    <!-- Stats + Links -->
    <b-card no-body class="d-flex w-100 mb-4">
      <div class="row no-gutters row-bordered h-100">
        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:sales')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportSales' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Sales Report</span><br />
              <small class="text-muted">Shows order activity by user and time period</small>
            </span>
          </router-link>
        </div>
        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:dispatch-sales')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportDispatchSales' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Dispatch Report</span><br />
              <small class="text-muted">Shows dispatch activity by user and time period</small>
            </span>
          </router-link>
        </div>
        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:competition')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportCompetition' }">
            <i class="fas fa-globe d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Competition Report</span><br />
              <small class="text-muted">Shows competition activity</small>
            </span>
          </router-link>
        </div>

        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:leads')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportLeads' }">
            <i class="fas fa-globe d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Leads Report</span><br />
              <small class="text-muted">Shows leads activity time period</small>
            </span>
          </router-link>
        </div>

        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center"  v-if="tokenCan('reports:top-posters')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportTopPosters' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Top Posters</span><br />
              <small class="text-muted">Shows company activity by time period</small>
            </span>
          </router-link>
        </div>

        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center d-none" v-if="tokenCan('reports:balance-issues')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportBalanceIssues' }">
            <i class="fas fa-balance-scale d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Balance Issues</span><br />
              <small class="text-muted">Shows orders with balance issues in selected time period</small>
            </span>
          </router-link>
        </div>

        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:followups')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportFollowups' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Follow Ups Report</span><br />
              <small class="text-muted">Shows follow up activity by user and time period</small>
            </span>
          </router-link>
        </div>

        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:cancellation')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportCancellation' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Cancellation Report</span><br />
              <small class="text-muted">Shows cancellation data by user and time period</small>
            </span>
          </router-link>
        </div>

        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:agent-source-sales')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportAgentSourceSales' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Agent Source Sales</span><br />
              <small class="text-muted">Shows agent sales data per source</small>
            </span>
          </router-link>
        </div>


        <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center" v-if="tokenCan('reports:orders')">
          <router-link class="card-body media align-items-center text-body" :to="{ name: 'ReportOrders' }">
            <i class="fas fa-chart-bar d-block text-primary" style="font-size: 1.8rem"></i>
            <span class="media-body d-block ml-3">
              <span class="text-big">Orders Report</span><br />
              <small class="text-muted">Shows order balance data by time period</small>
            </span>
          </router-link>
        </div>

      </div>
    </b-card>
    <!-- / Stats + Links -->
  </div>
</template>

<script>
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'ReportList',
  mixins: [permissionsMixin],
};
</script>

<style lang="scss" scoped></style>
