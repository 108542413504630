<template>
  <div>
    <slot v-if="loaded"></slot>
    <div v-else>
      <div v-for="index in count" :key="index" class="bone"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 3,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bone {
  border: 1px solid #dfdfdf;
  opacity: 0.5;
  height: 240px;
  margin-bottom: 32px;

  background-color: #ccc;
  background: linear-gradient(0.25turn, transparent, #fff, transparent);
  background-size: 20% 100%;
  background-repeat: no-repeat;
  background-position: left;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: right;
  }
}
</style>
