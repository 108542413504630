const paymentDirections = [
  { value: 1, label: 'Shipper to Company' },
  { value: 2, label: 'Company to Carrier' },
  //  { value: 3, label: 'Company to Pickup Terminal' },
  //  { value: 4, label: 'Company to Delivery Terminal' },
  { value: 5, label: 'Company to Shipper' },
  { value: 6, label: 'Carrier to Company' },
];

export default {
  data() {
    return {
      paymentDirections,
    };
  },
  methods: {
    paymentDirectionLabel(value) {
      return paymentDirections.find(pd => pd.value == value).label;
    },
  },
};
