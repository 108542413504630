<template>
  <div class="card mb-4">
    <div class="card-body">
      <LoadingIndicator v-if="loading" centered />
      <div v-if="!loading">
        <table v-if="items.length > 0" class="table">
          <thead>
            <tr>
              <th>User</th>
              <th>Lead Type</th>
              <th>Viewed At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td><b-link v-if="item.user" :to="userLink(item.user.id)"> {{ item.user.name }} </b-link></td>
              <td>{{ item.leadType.title }}</td>
              <td>{{ item.createdAt | formatDateTime }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-info">No items has been found yet.</div>
        <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import pageLinksMixin from '@/mixins/pageLinks';

export default {
  props: {
    leadQuoteOrder: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mixins: [pageLinksMixin],
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  computed: {
    fetchParams() {
      const params = {};
      params.page = this.currentPage;
      params.perPage = this.perPage;
      return params;
    },
    hasNextPage() {
      return this.items.length === this.perPage;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await ApiClient.leads.views.get({ leadId: this.leadQuoteOrder.id, params: this.fetchParams });
      this.loading = false;
      this.items = response.data.data;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
  },
  watch: {
    fetchParams() {
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
};
</script>
