<template>
  <div class="position-relative">
    <slot>
      <input type="text" placeholder="..." autocomplete="off" @keydown.down="down" @keydown.up="up" @keydown.enter="hit" @keydown.esc="reset" @blur="reset" @input="update" />
    </slot>

    <!-- the list -->
    <div class="dropdown-menu" :class="{ 'd-block': hasItems }" :style="{ left: isRtlMode ? 'auto' : 0, right: isRtlMode ? 0 : 'auto' }">
      <a class="dropdown-item" href="javascript:void(0)" v-for="(item, $item) in items" :key="item.id" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)">
        <span class="name" v-text="item[itemValue]"></span>
      </a>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
const debounceRequest = debounce(async (request, callback) => {
  callback(await request());
}, 500);

export default {
  props: {
    request: { type: Function },
    itemKey: { type: String },
    itemValue: { type: String },
  },
  data() {
    return {
      items: [],
      current: -1,
      loading: false,
      selectFirst: false,
    };
  },

  computed: {
    hasItems() {
      return this.items.length > 0;
    },
  },

  methods: {
    update() {
      this.cancel();
      this.loading = true;
      this.fetch().then(response => {
        if (response) {
          let data = response.data.data;
          this.items = this.limit ? data.slice(0, this.limit) : data;
          this.current = -1;
          this.loading = false;
          if (this.selectFirst) {
            this.down();
          }
        }
      });
    },

    fetch() {
      let cancel = new Promise(resolve => (this.cancel = resolve));
      let search = new Promise(resolve => debounceRequest(this.request, resp => resolve(resp)));
      return Promise.race([cancel, search]);
    },

    cancel() {
      // used to 'cancel' previous searches
    },

    reset() {
      this.items = [];
      this.query = '';
      this.loading = false;
    },

    setActive(index) {
      this.current = index;
    },

    activeClass(index) {
      return {
        active: this.current === index,
      };
    },

    hit() {
      if (this.current !== -1) {
        this.onHit(this.items[this.current]);
      }
    },

    up() {
      if (this.current > 0) {
        this.current--;
      } else if (this.current === -1) {
        this.current = this.items.length - 1;
      } else {
        this.current = -1;
      }
    },

    down() {
      if (this.current < this.items.length - 1) {
        this.current++;
      } else {
        this.current = -1;
      }
    },

    onHit(item) {
      this.$emit('hit', item);
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group + .dropdown-menu {
  margin-top: -14px;
}
</style>
