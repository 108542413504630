<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="$emit('close')">
      <div :class="{ 'modal-dialog modal-dialog-centered': true, ' modal-xl': !preselected }" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="$emit('close')" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body">
            <LeadlikeSms :preselected="preselected" :lead-quote-order="lead" @resetModal="sent" @clickclose="$emit('close')" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import LeadlikeSms from '@/components/shared/LeadlikeSms';

export default {
  name: 'LeadCardEdit',
  components: {
    LeadlikeSms,
  },
  props: {
    preselected: {
      type: String,
      default: null,
    },
    lead: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    sent() {
      this.preselected = null;
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}

.modal-body {
  background-color: #fafafa;
}
</style>
