<template>
  <b-modal :id="id" ok-title="Save" centered @ok="handleOk" :ok-disabled="submitDisabled">
    <template #modal-title>
      <span class="text-capitalize">Create Vehicle {{ title }}</span>
    </template>
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Name">
        <b-input v-model="$v.form.name.$model" :state="getValidationState(['form', 'name'])" placeholder="Change Name" />
        <FormError :value="$v.form.name" label="Name" :errors="[{ type: 'required' }, { type: 'maxLength', param: 'max' }]" />
      </b-form-group>
      <b-form-group v-if="title === 'Type'" label="Surcharge">
        <b-input v-model="$v.form.surcharge.$model" placeholder="Change Surcharge" />
      </b-form-group>
      <b-form-group v-if="title === 'Type'" label="Surcharge Long">
        <b-input v-model="$v.form.surcharge_long.$model" placeholder="Change Surcharge" />
      </b-form-group>
      <b-form-group label="CD Vehicle Type">
        <b-form-select v-model="$v.form.cd_vehicle_type_id.$model" :options="cdVehicleTypes">
          <!-- This slot appears above the options from 'options' prop -->
          <template #first>
            <b-form-select-option :value="null"> - - </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label="LB Vehicle Type">
        <b-form-select v-model="$v.form.lb_vehicle_type_id.$model" :options="lbVehicleTypes">
          <!-- This slot appears above the options from 'options' prop -->
          <template #first>
            <b-form-select-option :value="null"> - - </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import validation from '@/mixins/validation';
import { getDecimalRegex } from '@/helpers/methods';
import { required, maxLength } from 'vuelidate/lib/validators';
import FormError from '@/components/shared/errors/FormError';
//import AsyncMultiselect from '@/components/shared/async/AsyncMultiselect';
import { apiRequest } from '@/api/apiRequest';

export default {
  name: 'VehicleTypeForm',
  components: {
    FormError,
  },
  mixins: [validation],
  props: {
    id: {
      type: String,
      required: true,
    },
    vehicleForm: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: null,
      cdVehicleTypes: [],
      lbVehicleTypes: [],
      submitDisabled: false,
    };
  },
  validations() {
    const { title } = this;

    return {
      form: {
        name: { maxLength: maxLength(100), required },
        surcharge: {
          required: title === 'Type' ? required : {},
          onlyDecimal:
            title === 'Type'
              ? function (value) {
                  return getDecimalRegex(4).test(value);
                }
              : {},
        },
        surcharge_long: {
          required: title === 'Type' ? required : {},
          onlyDecimal:
            title === 'Type'
              ? function (value) {
                  return getDecimalRegex(4).test(value);
                }
              : {},
        },
        cd_vehicle_type_id: {},
        lb_vehicle_type_id: {},
      },
    };
  },
  watch: {
    vehicleForm: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.form = { ...newVal };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      this.submitDisabled = true;
      if (this.getIsInvalidForm(['form'])) return;

      const { id, form } = this;

      await this.onSubmit({ form });
      this.$nextTick(() => {
        this.submitDisabled = false;
        this.$bvModal.hide(id);
      });
    },
  },

  async mounted() {
    let response = await apiRequest({path: '/cd-vehicle-types'});
    if (response.status === 200) {
      this.cdVehicleTypes = response.data.data.map(x => {
        return { value: x.id, text: x.name };
      });
    }
    response = await apiRequest({path: '/lb-vehicle-types'});
    if (response.status === 200) {
      this.lbVehicleTypes = response.data.data.map(x => {
        return { value: x.id, text: x.name };
      });
    }
  },
};
</script>
