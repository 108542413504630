import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import globals from '@/globals';

import pagesRoutes from './routes/pages';
import authRoutes from './routes/auth';
import profileRoutes from './routes/profile';
import accountRoutes from './routes/account';
import accountsRoutes from './routes/accounts';
import carriersRoutes from './routes/carriers';
import customersRoutes from './routes/customers';
import leadsRoutes from './routes/leads';
import quotesRoutes from './routes/quotes';
import ordersRouters from './routes/orders';
import preferencesRoutes from './routes/preferences';
import sourcesRoutes from './routes/sources';
//import userRoutes from './routes/user';
import usersRoutes from './routes/users';
import referrersRoutes from './routes/referrers';
import vehiclesRoutes from './routes/vehicles';
import locationsRoutes from './routes/locations';
import notificationsRoutes from './routes/notifications';
import reportsRoutes from './routes/reports';
import competitionRoutes from './routes/competition';
import marketRatesRoutes from './routes/marketRates';
import NotFound from '@/components/NotFound';

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    // Default route
    // { path: '', redirect: '/home' },
    // 404 Not Found
    { path: '*', component: NotFound },
    ...authRoutes,
    ...pagesRoutes,
    ...accountRoutes,
    ...accountsRoutes,
    ...leadsRoutes,
    ...quotesRoutes,
    ...ordersRouters,
    ...preferencesRoutes,
    ...profileRoutes,
    ...sourcesRoutes,
//    ...userRoutes,
    ...usersRoutes,
    ...referrersRoutes,
    ...vehiclesRoutes,
    ...locationsRoutes,
    ...notificationsRoutes,
    ...carriersRoutes,
    ...customersRoutes,
    ...reportsRoutes,
    ...competitionRoutes,
    ...marketRatesRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  const userBlob = localStorage.getItem('user');
  const user = userBlob && JSON.parse(userBlob);

  const routeRequiresLogin = route => route.meta.auth;
  const userMissesPermission = route => {
    const { requirePermission } = route.meta;
    return requirePermission && !user.permissions.includes(requirePermission);
  };

  if (!user && to.matched.some(routeRequiresLogin)) {
    setTimeout(() => next('/auth/login'), 10);
    return;
  }

  if (user && to.matched.some(userMissesPermission)) {
    setTimeout(() => next('/'), 10);
    return;
  }

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);

  // Remove loading state
  // setTimeout(() => document.body.classList.remove('app-loading'), 10);
});

export default router;
