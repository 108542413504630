<template>
  <div>
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit()">
        <div>
          <b-row>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">Carrier information</h5>
                <div class="card-body">
                  <b-form-group label="Company Name"><b-input v-model="form.contact.company" type="text" rules="required" /></b-form-group>
                  <b-form-group label="Tax ID"><b-input v-model="form.taxIdNum" /></b-form-group>
                  <b-form-group label="MC Number"><b-input v-model="form.mcNumber" type="text" /></b-form-group>
                  <b-form-group label="Status"><b-radio-group v-model="form.isActive" :options="carrierStatus" /></b-form-group>
                  <b-form-group label="Can Dispatch"><b-radio-group v-model="form.canDispatch" :options="carrierDispatch" /></b-form-group>
                  <b-form-group label="Rating"><b-form-rating no-border v-model="form.rating" class="col-xl-6 col-12" /></b-form-group>
                  <b-form-group label="Notes"><b-textarea v-model="form.notes" rows="3"></b-textarea></b-form-group>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">Contact Information</h5>
                <div class="card-body">
                  <b-row>
                    <b-col>
                      <b-form-group label="First Name"><b-input v-model="form.contact.firstName" type="text" /></b-form-group>
                      <b-form-group label="Last Name"><b-input v-model="form.contact.lastName" type="text" /></b-form-group>
                      <b-form-group label="Contact"><b-input v-model="form.contact.contact" type="text" /></b-form-group>
                      <b-form-group label="Contact 2"><b-input v-model="form.contact.contact2" type="text" /></b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Email"><b-input v-model="form.contact.email" type="email" /></b-form-group>
                      <b-form-group label="Phone"><b-input v-model="form.contact.phone" type="text" /></b-form-group>
                      <b-form-group label="Phone 2"><b-input v-model="form.contact.phone2" type="text" /></b-form-group>
                      <b-form-group label="Cell Phone"><b-input v-model="form.contact.cellPhone" type="text" /></b-form-group>
                      <b-form-group label="Fax"><b-input v-model="form.contact.fax" type="text" /></b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">Location Information</h5>
                <div class="card-body">
                  <b-form-group label="Address"><b-input v-model="form.location.address" type="text" /></b-form-group>
                  <b-form-group label="Address 2"><b-input v-model="form.location.address2" type="text" /></b-form-group>
                  <b-row>
                    <b-col cols="5"
                      ><b-form-group label="City"><b-input v-model="form.location.city" type="text" /></b-form-group
                    ></b-col>
                    <b-col
                      ><b-form-group label="Zip"><b-input v-model="form.location.zip" type="text" /></b-form-group
                    ></b-col>
                    <b-col
                      ><b-form-group label="State"><b-input v-model="form.location.state" type="text" /></b-form-group
                    ></b-col>
                    <b-col
                      ><b-form-group label="Country"><b-input v-model="form.location.country" type="text" /></b-form-group
                    ></b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">Insurance Company Information</h5>
                <div class="card-body">
                  <b-row>
                    <b-col>
                      <b-form-group label="Name"><b-input v-model="form.insurance.company" type="text" /></b-form-group>
                      <b-form-group label="Address"><b-input v-model="form.insurance.address" type="text" /></b-form-group>
                      <b-form-group label="Email"><b-input v-model="form.insurance.email" type="email" /></b-form-group>
                      <b-form-group label="Phone"><b-input v-model="form.insurance.phone" type="text" /></b-form-group>
                      <b-form-group label="Agent Name"><b-input v-model="form.insurance.agentName" type="text" /></b-form-group>
                      <b-form-group label="Agent Phone"><b-input v-model="form.insurance.agentPhone" type="text" /></b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Policy Number"><b-input v-model="form.insurance.policyNumber" type="text" /></b-form-group>
                      <b-form-group label="Expiration Date"
                        ><flat-pickr v-model="form.insurance.expirationDate" placeholder="Select Expiration Date" :config="datePicker"></flat-pickr
                      ></b-form-group>
                      <b-form-group label="Certificate Holder" label-for="isCertificateHolder"
                        ><b-check v-model="form.insurance.isCertificateHolder" value="1" id="isCertificateHolder"
                      /></b-form-group>
                      <b-form-group label="Additionally Insured" label-for="isExtraInsured"
                        ><b-check v-model="form.insurance.isExtraInsured" value="1" id="isExtraInsured"
                      /></b-form-group>
                      <b-form-group label="Broker/Carrier Contract" label-for="isBrokerContract"
                        ><b-check v-model="form.insurance.isBrokerContract" value="1" id="isBrokerContract"
                      /></b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <FormFieldsErrors :errors="errors" />
        </div>
        <button :disabled="loading" class="btn btn-primary" type="submit">Save</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import FormFieldsErrors from '@/components/shared/formFields/FormFieldsErrors';

const emptyForm = {
  id: null,
  rating: null,
  isActive: 1,
  canDispatch: 1,
  taxIdNum: '',
  mcNumber: '',
  notes: '',
  contact: {
    company: '',
    firstName: '',
    lastName: '',
    contact: '',
    contact2: '',
    phone: '',
    phone2: '',
    cellPhone: '',
    fax: '',
    email: '',
  },
  location: {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'USA',
  },
  insurance: {
    company: '',
    address: '',
    phone: '',
    email: '',
    agentName: '',
    agentPhone: '',
    policyNumber: '',
    expirationDate: null,
    isCertificateHolder: 0,
    isExtraInsured: 0,
    isBrokerContract: 0,
  },
};

const carrierStatus = [
  { text: 'Active', value: 1 },
  { text: 'Inactive', value: 0 },
];

const carrierDispatch = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 0 },
];

const datePicker = {
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'F j, Y',
  // minDate: 'today',
  wrap: true,
};

export default {
  name: 'CarrierEdit',
  created() {
    this.carrierStatus = carrierStatus;
    this.datePicker = datePicker;
    this.carrierDispatch = carrierDispatch;
  },
  data() {
    return {
      form: this.deepMergeForm(emptyForm, this.carrier),
      errors: {},
      loading: false,
    };
  },
  props: {
    carrier: {
      type: Object,
      default: null,
    },
  },
  components: { FormFieldsErrors },
  methods: {
    deepMergeForm(a, b) {
      if (!b) b = {};
      let start = Object.assign({}, a, b);
      for (let key of ['location', 'contact', 'insurance']) {
        if (!b[key]) start[key] = Object.assign({}, a[key]);
      }
      delete start.leads;
      return start;
    },
    async submit() {
      if (this.loading) return;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loading = true;
        let response = null;
        if (this.carrier) {
          response = await ApiClient.carriers.put({ id: this.carrier.id, body: this.form });
        } else {
          response = await ApiClient.carriers.post({ body: this.form });
        }
        this.loading = false;
        if (response.status === 200) {
          if (!this.carrier) this.form = Object.assign({}, emptyForm);
          this.errors = {};
          const carrier = response.data.data;
          this.$emit('save', carrier);
          this.$router.push({ name: 'CarrierDetails', params: { id: carrier.id } });
        } else if (response.status === 422) {
          this.errors = response.data.errors;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, { title: 'Error', variant: 'danger', solid: true });
    },
  },
  async mounted() {
    //console.log(this.form);
  },
};
</script>
