<template>
  <div>
    <div v-if="user.id">
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ 'User #' + user.id + ' - ' + user.name }}</h1>
        <button v-if="!user.deletedAt && tokenCan('admin')" class="btn btn-sm btn-danger ml-1" href="#" @click="deleting = true">Disable User</button>
        <button v-if="user.deletedAt && tokenCan('admin')" class="btn btn-sm btn-warning ml-1" href="#" @click="restoreUser">Enable User</button>
        <ConfirmationModal
          v-if="deleting"
          @confirm="removeUser"
          @cancel="deleting = false"
          :cancel-label="'No'"
          :confirm-label="'Yes'"
          :label="'Are you sure to disable this user?'"
        />
      </div>

      <ul class="nav nav-pills mb-4">
        <TabLink label="View" route-name="UserDetails" :id="user.id" />
        <TabLink label="Edit" route-name="UserEdit" :id="user.id" />
        <TabLink label="Lead views" route-name="UserLeadViews" :id="user.id" />
        <TabLink label="Auth logs" route-name="UserAuthLogs" :id="user.id" />
      </ul>

      <router-view :user="user" @save="updateLocalState" @refresh="fetch" />
    </div>
    <LoadingIndicator v-else centered />
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import TabLink from '@/components/shared/TabLink.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import ConfirmationModal from '@/components/shared/ConfirmationModal';

export default {
  name: 'User',
  components: { LoadingIndicator, TabLink, ConfirmationModal },
  mixins: [permissionsMixin],
  data() {
    return {
      deleting: null,
      user: {},
    };
  },
  methods: {
    updateLocalState(user) {
      this.user = user;
    },
    async fetch() {
      const { id } = this.$route.params;
      const apiResponse = await ApiClient.users.get({ id });
      this.updateLocalState(apiResponse.data.data);
    },
    async removeUser() {
      const { id } = this.user;
      const response = await ApiClient.users.delete({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'UserList' });
      }
    },
    async restoreUser() {
      const { id } = this.user;
      const response = await ApiClient.users.restore({ id });
      if (response.status === 200) {
        this.fetch();
      }
    },
  },
  async mounted() {
    await this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
