<template>
  <b-modal :id="id" ok-title="Save" centered @ok="handleOk" :ok-disabled="submitDisabled">
    <template #modal-title>
      <span class="text-capitalize">Create Vehicle</span>
    </template>
    <b-form @submit.stop.prevent="handleSubmit">
      <b-form-group v-for="item in vehicleIds" :key="item.id" :label="item.label">
        <AsyncMultiselect
          label="name"
          track-by="id"
          :value="$v.form[item.id].$model"
          :options="options[item.type]"
          :fetching-options="{ queryKey: 'name', endpoint: item.type }"
          :class-list="!$v.form[item.id].$dirty ? '' : getValidationState(['form', item.id]) ? 'has-success' : 'has-error'"
          :on-update-options="({ data }) => handleUpdateVehicleOptions({ data, type: item.type })"
          :on-change-input="value => handleChangeVehicle({ id: item.id, value })"
        />
        <FormError :value="$v.form[item.id]" :label="item.label" :errors="[{ type: 'required' }]" />
      </b-form-group>

      <b-form-group label="Year">
        <b-form-input v-model="$v.form.year.$model" :state="getValidationState(['form', 'year'])" placeholder="Change Vehicle Year" />
        <FormError
          :value="$v.form.year"
          label="Year"
          :errors="[{ type: 'required' }, { type: 'integer' }, { type: 'minValue', param: 'min' }, { type: 'maxValue', param: 'max' }]"
        />
      </b-form-group>

      <b-form-group label="Type">
        <b-input v-model="$v.form.type.$model" :state="getValidationState(['form', 'type'])" placeholder="Change Type" />
        <FormError :value="$v.form.type" label="Type" :errors="[{ type: 'maxLength', param: 'max' }]" />
      </b-form-group>

      <b-form-group label="Body">
        <b-input v-model="$v.form.body.$model" :state="getValidationState(['form', 'body'])" placeholder="Change Body" />
        <FormError :value="$v.form.body" label="Body" :errors="[{ type: 'maxLength', param: 'max' }]" />
      </b-form-group>

      <b-form-group label="Model">
        <b-input v-model="$v.form.model.$model" :state="getValidationState(['form', 'model'])" placeholder="Change Model" />
        <FormError :value="$v.form.model" label="Model" :errors="[{ type: 'required' }, { type: 'maxLength', param: 'max' }]" />
      </b-form-group>

      <b-form-group label="Original Model">
        <b-input v-model="$v.form.original_model.$model" :state="getValidationState(['form', 'original_model'])" placeholder="Change Original Model" />
        <FormError :value="$v.form.original_model" label="Original Model" :errors="[{ type: 'maxLength', param: 'max' }]" />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import validation from '@/mixins/validation';
import { required, minValue, maxValue, maxLength, integer } from 'vuelidate/lib/validators';
import { newVehicle } from '@/helpers/variables';
import FormError from '@/components/shared/errors/FormError';
import AsyncMultiselect from '@/components/shared/async/AsyncMultiselect';

export default {
  name: 'VehicleModal',
  components: { FormError, AsyncMultiselect },
  mixins: [validation],
  props: {
    id: {
      type: String,
      required: true,
    },
    vehicleForm: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {
        ...newVehicle,
      },
      vehicleIds: [
        { id: 'vehicle_type', type: 'vehicleTypes', label: 'Vehicle Type' },
        { id: 'vehicle_make', type: 'vehicleMakes', label: 'Vehicle Make' },
      ],
      options: {
        vehicleTypes: [],
        vehicleMakes: [],
      },
      submitDisabled: false,
    };
  },
  validations: {
    form: {
      vehicle_type: { required },
      vehicle_make: { required },
      year: { required, integer, minValue: minValue(1900), maxValue: maxValue(new Date().getFullYear()) },
      type: { maxLength: maxLength(50) },
      body: { maxLength: maxLength(50) },
      model: { required, maxLength: maxLength(100) },
      original_model: { maxLength: maxLength(100) },
    },
  },
  watch: {
    vehicleForm: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.vehicle_make_id && newVal !== oldVal) {
          this.form = { ...newVehicle, ...newVal };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (this.getIsInvalidForm(['form'])) return;
      this.submitDisabled = true;

      const { id } = this;
      const { vehicle_type, vehicle_make } = this.form;

      await this.onSubmit({
        form: {
          ...this.form,
          vehicle_type: null,
          vehicle_make: null,
          vehicle_type_id: vehicle_type.id,
          vehicle_make_id: vehicle_make.id,
        },
      });
      this.$nextTick(() => {
        this.submitDisabled = false;
        this.handleResetForm();
        this.$bvModal.hide(id);
      });
    },
    async handleUpdateVehicleOptions({ data, type }) {
      this.options = {
        ...this.options,
        [type]: [...data.map(({ id, name }) => ({ id, name }))],
      };
    },
    handleChangeVehicle({ id, value }) {
      this.form = { ...this.form, [id]: value };
      this.$v.form[id].$touch();
    },
    handleResetForm() {
      this.form = {
        ...newVehicle,
      };
    },
  },
};
</script>
