import LayoutCentered from '@/layout/LayoutCentered';
import LocationsTabs from '@/components/pages/locations/LocationsTabs';

export default [
  {
    // Layout 2
    path: '/locations',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'admin',
    },
    children: [
      {
        component: LocationsTabs,
        path: '',
        children: [
          /* redirections */
          {
            path: '',
            beforeEnter: (to, from, next) => {
              next('/locations/states');
            },
          },
          /* / redirections */
          {
            path: 'states',
            component: () => import('@/components/pages/locations/states/States'),
          },
          {
            path: 'cities',
            component: () => import('@/components/pages/locations/cities/Cities'),
          },
          {
            path: 'counties',
            component: () => import('@/components/pages/locations/counties/Counties'),
          },
          {
            path: 'zips',
            component: () => import('@/components/pages/locations/zips/Zips'),
          },
        ],
      },
    ],
  },
];
