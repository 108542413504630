<template>
  <div style="position:relative;">
    <div class="d-flex align-items-center">
      <b-input :value="value" @input="onInput" placeholder="Enter location..." size="sm"></b-input>
    </div>
    <div v-if="locations.length" style="position:absolute;background:#fff;">
      <div class="list-group">
        <div class="list-group-item list-group-item--clickable" v-for="location in locations" :key="locationOption(location)">
          <div @click="selectLocation(location)">{{ locationOption(location) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import { debounce } from 'debounce';

const searchLocations = debounce(async (searchTerm, callback) => {
  callback(await ApiClient.locations.get({ params: { q: searchTerm } }));
}, 200);

export default {
  name: 'LocationPicker',
  data() {
    return { locations: [] };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(searchTerm) {
      if (searchTerm.length > 2) {
        searchLocations(searchTerm, response => {
          this.locations = response.data.data.slice(0, 5);
        });
      } else {
        this.locations = [];
      }
      this.$emit('input', searchTerm);
    },
    selectLocation(loc) {
      this.locations = [];
      this.$emit('input', this.locationOption(loc));
    },
    locationOption(loc) {
      let p = [];
      if(loc.zip) p.push(loc.zip);
      if(loc.city) p.push(loc.city);
      if(loc.state) p.push(loc.state);
      return p.join(', ');
    },
  }
};
</script>

<style lang="scss" scoped>
.list-group-item--clickable {
  cursor: pointer;
}

.list-group-item--clickable:hover {
  background-color: var(--light);
}

.selected-value {
  padding-right: 16px;
}

.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
}
</style>
