<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="preparedData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';

import { Chart as ChartJS, Title, Tooltip, Legend, Filler, LineElement, LinearScale, CategoryScale, PointElement } from 'chart.js';
import moment from 'moment';
ChartJS.register(Title, Tooltip, Legend, Filler, LineElement, LinearScale, CategoryScale, PointElement);

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    chartsData: {
      type: Object,
      default: () => {},
    },
    carsWaiting: {
      type: Number,
      default: 0,
    },
    showCarsWaiting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preparedData: {},
      tempDatasets: [],
      tempLabels: [],
      colors: {
        0: '#def4ff70',
        1: '#f9e5e570',
        2: '#d9f5eb70',
      },
      bcolors: {
        0: '#26B4FFe3',
        1: '#d9534fe3',
        2: '#02BC77e3',
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3,
        plugins: {
          legend: {
            labels: {
              boxHeight: 3,
              font: {
                size: 14,
              },
            },
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            id: 'y1',
            display: true,
            position: 'left',
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 50,
            },
          },
          y1: {
            id: 'y2',
            display: true,
            position: 'right',
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 5,
            },
          },
        },
      },
    };
  },
  methods: {
    formatChartData() {
      this.preparedData = {
        labels: [],
        datasets: [],
      };
      let color = this.colors;
      let bcolor = this.bcolors;
      this.chartOptions.scales.y1.display = this.showCarsWaiting;
      this.chartsData.data.forEach((carType, index) => {
        let tempArray = {
          label: carType.label,
          backgroundColor: color[index],
          borderColor: bcolor[index],
          data: carType.data,
          fill: true,
        };
        this.preparedData.datasets.push(tempArray);
      });
      this.chartsData.labels.forEach(date => {
        this.preparedData.labels.push(moment(date).format('MMM Do'));
      });
      if (this.showCarsWaiting) {
        let tempData = [];
        this.chartsData.labels.forEach((val, index) => {
          if (index != 0) {
            tempData.push(null);
          }
        });
        tempData.push(this.carsWaiting);
        let tempArray2 = {
          label: 'Cars Waiting',
          backgroundColor: '#000000',
          borderColor: '#000000',
          yAxisID: 'y1',
          data: tempData,
          fill: true,
        };
        this.preparedData.datasets.push(tempArray2);
      }
    },
  },
  mounted() {
    this.formatChartData();
  },
  watch: {
    showCarsWaiting: function () {
      this.formatChartData();
    },
    chartsData: function () {
      this.formatChartData();
    },
  },
};
</script>
