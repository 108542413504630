<template>
  <div class="card mb-4">
    <b-form inline @submit.prevent="submit" class="px-4 pt-3">
      Pickup: <LocationPicker v-model="pickupLocation" class="ml-1" /><b-select class="mr-4 ml-1" size="sm" v-model="pickupRadius" :options="radiusOptions" /> 
      Delivery: <LocationPicker v-model="deliveryLocation" class="ml-1" /><b-select class="mr-4 ml-1" size="sm" v-model="deliveryRadius" :options="radiusOptions" />
      Period: <b-select class="ml-1" size="sm" v-model="period" :options="intervalsOptions" />
      <button class="btn btn-primary ml-3 btn-sm">Search</button>
    </b-form>

    <div class="card-body">
      <LoadingIndicator v-if="loading" centered />

      <div v-if="!loading && stats" class="mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span class="mr-2">Median prices:</span>
            <span v-for="(value, name) in stats" :key="name" class="mr-2">
              {{ name }} - <b>{{ value }}</b>
            </span>
          </div>
          <div v-if="carsWaiting">
            <b-form-checkbox id="checkbox-1" v-model="showCarsWaiting" name="checkbox-1"> Cars waiting: <b>{{ carsWaiting }}</b> </b-form-checkbox>
          </div>
        </div>
        <LineChart v-if="items.length" :charts-data="datasets" :show-cars-waiting="showCarsWaiting" :cars-waiting="carsWaiting" class="py-4"></LineChart>
      </div>

      <!-- Table -->
      <b-table v-if="!loading" :items="items" :fields="fields" striped bordered responsive class="card-table">
        <template #cell(originZip)="data"> {{ data.item.originCity }} {{ data.item.originState }}, {{ data.item.originZip }} </template>

        <template #cell(destinationZip)="data"> {{ data.item.destinationCity }} {{ data.item.destinationState }}, {{ data.item.destinationZip }} </template>

        <template #cell(vehicleType)="data">
          {{ data.item.vehicleType }}<br />
          {{ data.item.vehicleMake }} {{ data.item.vehicleModel }} {{ data.item.vehicleYear }}<br />
          <div v-if="data.item.hasInopVehicle" class="text-danger">inoperable</div>
          <div v-if="data.item.trailerType && data.item.trailerType != 'OPEN'" class="text-danger">{{ data.item.trailerType.toLowerCase() }}</div>
          <div v-if="data.item.vehicleCount > 1" class="text-danger">{{ data.item.vehicleCount }} vehicles</div>
        </template>

        <template #cell(createdDate)="data">
          {{ data.item.createdDate | formatDate }}
        </template>

        <template #cell(availableDate)="data">
          {{ data.item.availableDate | formatDate }}
        </template>

        <template #cell(lastSeenAt)="data">
          {{ data.item.lastSeenAt | formatDateTime }}
        </template>
      </b-table>
      <!-- / Table -->
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import LineChart from '@/components/shared/LineChart';
import LocationPicker from '@/components/shared/LocationPicker';

export default {
  name: 'MarketRatesList',
  data() {
    return {
      loading: false,
      loading2: false,
      items: [],
      datasets: {},
      labels: [],
      fields: [
        //    { key: 'listingId', label: 'Listing', sortable: true },
        { key: 'companyName', label: 'Company', sortable: true },
        //    { key: 'shipperOrderId', label: 'Order', sortable: true },
        { key: 'originZip', label: 'Origin', sortable: true },
        { key: 'destinationZip', label: 'Destination', sortable: true },
        { key: 'distance', label: 'Distance', sortable: true },
        { key: 'price', label: 'Price', sortable: true },
        { key: 'vehicleType', label: 'Vehicle' },
        { key: 'createdDate', label: 'Posted', sortable: true },
        { key: 'availableDate', label: 'Available', sortable: true },
        { key: 'lastSeenAt', label: 'Last seen', sortable: true },
      ],
      pickupLocation: this.defaultPickupZip || this.leadQuoteOrder?.pickupLocation.zip,
      pickupRadius: 50,
      deliveryLocation: this.defaultDeliveryZip || this.leadQuoteOrder?.deliveryLocation.zip,
      deliveryRadius: 50,
      period: 14,
      radiusOptions: [
        { text: 'exact match', value: 0 },
        { text: '25 miles', value: 25 },
        { text: '50 miles', value: 50 },
        { text: '100 miles', value: 100 },
      ],
      intervalsOptions: [
        { text: '7 Days', value: 7 },
        { text: '2 Weeks', value: 14 },
        { text: '1 Month', value: 30 },
    //    { text: '3 Months', value: 90 },
      ],
      stats: {},
      carsWaiting: null,
      showCarsWaiting: true,
    };
  },
  components: {
    LoadingIndicator,
    LineChart,
    LocationPicker,
  },
  props: {
    leadQuoteOrder: {
      type: Object,
      required: false,
      default: null,
    },
    defaultPickupZip: {
      type: String,
      required: false,
      default: null,
    },
    defaultDeliveryZip: {
      type: String,
      required: false,
      default: null,
    },
  },
  async created() {
    this.fetch();
    this.fetchMedianPrices();
  },
  methods: {
    async submit(e) {
      e.preventDefault();      
      this.fetchMedianPrices();
      this.fetch();
    },
    async fetch() {
      if (!this.pickupLocation || !this.deliveryLocation) return;
      this.loading = true;
      const response = await ApiClient.marketRates.get({
        params: { pickupRadius: this.pickupRadius, deliveryRadius: this.deliveryRadius, pickupLocation: this.pickupLocation, deliveryLocation: this.deliveryLocation },
      });
      this.items = response.data.data;
      this.stats = response.data.stats;
      this.carsWaiting = response.data.carsWaiting;
      this.loading = false;
    },
    async fetchMedianPrices() {
      if (!this.pickupLocation || !this.deliveryLocation) return;
      this.loading2 = true;
      const response = await ApiClient.marketRates.medianPrices({
        params: { pickupRadius: this.pickupRadius, deliveryRadius: this.deliveryRadius, pickupLocation: this.pickupLocation, deliveryLocation: this.deliveryLocation, period: this.period },
      });
      this.datasets = response.data;
      this.labels = response.data.labels;
      this.loading2 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.zip {
  width: 60px;
}
</style>
