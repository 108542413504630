import LayoutCentered from '@/layout/LayoutCentered';
import OrderList from '@/components/pages/orders/OrderList';
import OrderNew from '@/components/pages/orders/OrderNew';
import Leadlike from '@/components/shared/Leadlike';
import LeadlikeDetails from '@/components/shared/LeadlikeDetails';
import LeadlikeEdit from '@/components/shared/LeadlikeEdit';
import OrderOffers from '@/components/shared/OrderOffers';
import OrderDispatch from '@/components/shared/OrderDispatch';
import OrderPayments from '@/components/shared/OrderPayments';
import OrderLoadboards from '@/components/shared/OrderLoadboards';
import LeadlikeEmails from '@/components/shared/LeadlikeEmails';
import LeadlikeSms from '@/components/shared/LeadlikeSms';
import LeadlikeHistory from '@/components/shared/LeadlikeHistory';
import MarketRatesList from '@/components/shared/MarketRatesList';
import OrderAttachments from '@/components/shared/OrderAttachments';
import LeadViewsList from '@/components/shared/LeadViewsList';

export default [
  {
    path: '/orders',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        component: OrderList,
        name: 'OrderList',
        path: '',
        props: true,
      },
      {
        component: OrderList,
        name: 'OrdersArchived',
        path: 'archived',
        props: { showArchived: true },
      },
      {
        component: OrderList,
        name: 'OrdersWithStatus',
        path: 'status/:status',
      },
      { path: 'new', name: 'OrderNew', component: OrderNew },
      {
        path: 'view/:id',
        component: Leadlike,
        children: [
          {
            path: '',
            name: 'OrderDetails',
            component: LeadlikeDetails,
          },
          {
            path: 'edit',
            name: 'OrderEdit',
            component: LeadlikeEdit,
          },
          {
            path: 'dispatch',
            name: 'OrderDispatch',
            component: OrderDispatch,
          },
          {
            path: 'offers',
            name: 'OrderOffers',
            component: OrderOffers,
          },
          {
            path: 'payments',
            name: 'OrderPayments',
            component: OrderPayments,
          },
          {
            path: 'loadboards',
            name: 'OrderLoadboards',
            component: OrderLoadboards,
          },
          {
            path: 'emails',
            name: 'OrderEmails',
            component: LeadlikeEmails,
          },
          {
            path: 'sms',
            name: 'OrderSms',
            component: LeadlikeSms,
          },
          {
            path: 'history',
            name: 'OrderHistory',
            component: LeadlikeHistory,
          },
          {
            path: 'market-rates',
            name: 'OrderMarketRates',
            component: MarketRatesList,
          },
          {
            path: 'attachments',
            name: 'OrderAttachments',
            component: OrderAttachments,
          },
          {
            path: 'views',
            name: 'OrderViews',
            component: LeadViewsList,
          },
        ],
      },
    ],
  },
];
