<template>
  <div :class="classes"></div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return { loader: true, centered: this.centered };
    },
  },
};
</script>

<style lang="scss" scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.loader {
  margin: 8px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid #ddd;
  border-right: 4px solid #ddd;
  border-bottom: 4px solid #ddd;
  border-left: 4px solid #666;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.centered {
  margin-left: auto;
  margin-right: auto;
}
</style>
