<template>
  <b-modal :id="id" ok-title="Save" centered @ok="handleOk">
    <template #modal-title>
      <span class="text-capitalize">Create {{ location.text }}</span>
    </template>
    <b-form @submit.stop.prevent="handleSubmit">
      <b-form-group v-for="item in filteredLocations" :key="item.id" :label="item.label">
        <AsyncMultiselect
          :value="form[item.id]"
          :options="options[item.type]"
          :fetching-options="{ queryKey: 'name', endpoint: item.type }"
          :class-list="!$v.form[item.id].$dirty ? '' : getValidationState(['form', item.id]) ? 'has-success' : 'has-error'"
          :on-update-options="({ data }) => handleUpdateLocationOptions({ data, type: item.type })"
          :on-change-input="value => handleChangeLocation({ id: item.id, value })"
        />
        <FormError :value="$v.form[item.id]" :label="item.label" :errors="[{ type: 'required' }]" />
      </b-form-group>

      <b-form-group v-if="location.path === 'zips'" label="Zip">
        <b-input v-model="$v.form.zip.$model" :state="getValidationState(['form', 'zip'])" />
        <FormError :value="$v.form.zip" label="Zip" :errors="[{ type: 'required' }, { type: 'maxLength', param: 'max' }]" />
      </b-form-group>

      <b-form-group v-else label="Name">
        <b-input v-model="$v.form.name.$model" :state="getValidationState(['form', 'name'])" />
        <FormError :value="$v.form.name" label="Name" :errors="[{ type: 'required' }, { type: 'maxLength', param: 'max' }]" />
      </b-form-group>

      <b-form-group label="Surcharge Pick">
        <b-input v-model="$v.form.surcharge_pick.$model" :state="getValidationState(['form', 'surcharge_pick'])" type="number" />
        <FormError :value="$v.form.surcharge_pick" label="Surcharge Pick" :errors="[{ type: 'required' }, { type: 'decimal' }]" />
      </b-form-group>

      <b-form-group label="Surcharge Drop">
        <b-input v-model="$v.form.surcharge_drop.$model" :state="getValidationState(['form', 'surcharge_drop'])" type="number" />
        <FormError :value="$v.form.surcharge_drop" label="Surcharge Drop" :errors="[{ type: 'required' }, { type: 'decimal' }]" />
      </b-form-group>

      <b-form-group v-if="location.path === 'zips'" label="Latitude">
        <b-input v-model="$v.form.latitude.$model" :state="getValidationState(['form', 'latitude'])" type="number" />
        <FormError :value="$v.form.latitude" label="Latitude" :errors="[{ type: 'decimal' }]" />
      </b-form-group>

      <b-form-group v-if="location.path === 'zips'" label="Longitude">
        <b-input v-model="$v.form.longitude.$model" :state="getValidationState(['form', 'longitude'])" type="number" />
        <FormError :value="$v.form.longitude" label="Longitude" :errors="[{ type: 'decimal' }]" />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import validation from '@/mixins/validation';
import { required, maxLength, decimal } from 'vuelidate/lib/validators';
import { initialLocations } from '@/helpers/variables';
import FormError from '@/components/shared/errors/FormError';
import AsyncMultiselect from '../async/AsyncMultiselect';

export default {
  name: 'LocationCreateModal',
  components: { FormError, AsyncMultiselect },
  mixins: [validation],
  props: {
    id: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {
        ...initialLocations,
      },
      locations: [
        { id: 'state_id', type: 'states', label: 'States', activeList: ['cities', 'counties', 'zips'] },
        { id: 'city_id', type: 'cities', label: 'Cities', activeList: ['zips'] },
        { id: 'county_id', type: 'counties', label: 'Counties', activeList: ['cities', 'zips'] },
      ],
      options: {
        states: [],
        cities: [],
        counties: [],
      },
    };
  },
  validations() {
    const { path } = this.location;

    return {
      form: {
        name: {
          required: path !== 'zips' ? required : {},
          maxLength: maxLength(255),
        },
        zip: {
          required: path === 'zips' ? required : {},
          maxLength: maxLength(5),
        },
        state_id: {
          required: this.getPathIdStatus({ id: 'state_id' }) ? required : {},
        },
        city_id: {
          required: this.getPathIdStatus({ id: 'city_id' }) ? required : {},
        },
        county_id: {
          required: this.getPathIdStatus({ id: 'county_id' }) ? required : {},
        },
        surcharge_pick: { required, decimal },
        surcharge_drop: { required, decimal },
        latitude: { decimal },
        longitude: { decimal },
      },
    };
  },
  computed: {
    filteredLocations() {
      const { locations } = this;
      const { path } = this.location;

      return locations.filter(item => item.activeList.includes(path));
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (this.getIsInvalidForm(['form'])) return;

      const { id } = this;
      const { state_id, city_id, county_id } = this.form;
      const parsedForm = {
        ...this.form,
        state_id: this.getPathIdStatus({ id: 'state_id' }) ? state_id.value : null,
        city_id: this.getPathIdStatus({ id: 'city_id' }) ? city_id.value : null,
        county_id: this.getPathIdStatus({ id: 'county_id' }) ? county_id.value : null,
      };

      await this.onSubmit({ form: parsedForm });
      this.$nextTick(() => {
        this.handleResetForm();
        this.$bvModal.hide(id);
      });
    },
    async handleUpdateLocationOptions({ data, type }) {
      this.options = {
        ...this.options,
        [type]: [
          ...data.map(({ id, name }) => {
            return {
              value: id,
              text: name,
            };
          }),
        ],
      };
    },
    handleChangeLocation({ id, value }) {
      this.form = { ...this.form, [id]: value };
      this.$v.form[id].$touch();
    },
    handleResetForm() {
      this.form = {
        ...initialLocations,
      };
    },
    getPathIdStatus({ id }) {
      const { locations } = this;
      const { path } = this.location;
      const currLocation = locations.find(item => item.id === id);

      return currLocation.activeList.includes(path);
    },
  },
};
</script>
