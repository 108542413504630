<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="@/vendor/libs/custom-ckeditor/custom-ckeditor.scss" lang="scss"></style>
<style src="@/vendor/libs/c3/c3.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - Nexus CRM',
  },
};
</script>

<style lang="scss">
@mixin list-margin-bottom($margin: 1.5rem) {
  margin-bottom: $margin !important;
  &:last-child {
    margin-bottom: 0rem !important;
  }
}

/* Appwork Additional Styles */
@media (min-width: 992px) {
  .navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: 0.5rem;
    width: 22rem;
  }
}
.navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

/* Modified Existing Classes */
.btn {
  font-weight: bold;
}

/* Fixed Bootstrap Tooltip */
.tooltip {
  top: 0;
}

// Added for Cke 4 editor
.has-error-cke {
  border: solid 3px #d9534f;
}
.has-success-cke {
  border: solid 3px #02bc77;
}

/* Added for Multiselect */
.has-error .multiselect__tags {
  border-color: #d9534f;
}

.has-success .multiselect__tags {
  border-color: #02bc77;
}

.form-error-message {
  font-size: 85%;
  width: 100%;
  margin-top: 0.25rem;
  color: #d9534f;
}

/* Text */
.font-size-1 {
  font-size: 1rem;
}
.text-decoration-underline {
  text-decoration: underline;
}
.letter-spacing-title {
  letter-spacing: 1.5px;
}

/* Additional */
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer-events-none {
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.icon-md::before {
  font-size: 1.5rem;
}
.border-left-pill {
  border-bottom-left-radius: 50rem;
  border-top-left-radius: 50rem;
}
.border-right-pill {
  border-bottom-right-radius: 50rem;
  border-top-right-radius: 50rem;
}
.max-w-200 {
  max-width: 200px;
}
.min-h-30 {
  min-height: 30px;
}
.custom-card-list > .card {
  @include list-margin-bottom();
}
.custom-div-list > div {
  @include list-margin-bottom(1rem);
}

.b-toast-danger .toast-header {
  color: white;
  background-color: var(--danger);
}

.b-toast-success .toast-header {
  color: white;
  background-color: var(--success);
}

.notif-toast {
  .toast-header {
    color: #5a6066 !important;
    background-color: unset !important;
    border-bottom: unset !important;
  }
}

.light-style {
  .b-toast {
    .toast {
      background-color: #def4ff;
    }
  }
}
</style>
