<template>
  <div>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <h1>New user</h1>
      </div>
      <UserEdit :user="null" />
    </div>
  </div>
</template>

<script>
import UserEdit from '@/components/pages/users/UserEdit';

export default {
  name: 'UserNew',
  components: { UserEdit },
};
</script>

<style lang="scss" scoped></style>
