import LayoutCentered from '@/layout/LayoutCentered';
import AccountsTabs from '@/components/pages/accounts/AccountsTabs';

export default [
  {
    // Layout 2
    path: '/accounts',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'carriers',
    },
    children: [
      {
        path: 'create',
        component: () => import('@/components/pages/accounts/account/accountCreateEdit/AccountCreateEdit'),
      },
      /* redirections */
      {
        path: '',
        beforeEnter: (to, from, next) => {
          next('/accounts/shippers');
        },
      },
      /* / redirections */
      {
        component: AccountsTabs,
        path: '',
        children: [
          {
            path: 'shippers',
            component: () => import('@/components/pages/accounts/shippers/Shippers'),
            name: 'account.shippers',
            meta: {
              auth: true,
              requirePermission: 'customers',
            },
          },
          {
            path: 'carriers',
            component: () => import('@/components/pages/accounts/carriers/Carriers'),
            name: 'account.carriers',
            meta: {
              auth: true,
              requirePermission: 'carriers',
            },
          },
        ],
      },
    ],
  },
];
