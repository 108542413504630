<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="$emit('close')">
      <div class="modal-dialog modal-sm modal-dialog-centered" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="$emit('close')" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body">
            <flat-pickr class="form-control" v-model="datetime" placeholder="Select date" :config="fltConfig" value="" />
            <button class="mt-3 btn btn-sm btn-primary" @click="$emit('input', value)">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DatePickerModal',
  data() {
    return {
      datetime: null,
      fltConfig: {
        inline: true,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'm/d/Y H:i',
        allowInput: false,
      },
    };
  },
  computed: {
    value() {
      return this.datetime ? moment(this.datetime).toISOString() : null;
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}

.modal-body {
  background-color: #fafafa;
}
</style>
