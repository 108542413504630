const dateTypes = [
  { value: '1', label: 'Estimated' },
  { value: '2', label: 'Exactly' },
  { value: '3', label: 'No earlier than' },
  { value: '4', label: 'No later than' },
];

export default {
  data() {
    return {
      dateTypes,
    };
  },
  methods: {
    dateTypeLabel(value) {
      if(!value) return '';
      return dateTypes.find(dt => dt.value == value).label;
    },
  },
};
