<template>
  <div>
    <template>
      <div class="card mb-4">
        <h5 class="card-header">Added Truckers</h5>
        <div class="card-body">
          <LoadingIndicator v-if="offersLoading" centered />
          <div v-else>
            <table class="table" v-if="offers.length">
              <thead>
                <tr>
                  <th>Agent</th>
                  <th>Carrier</th>
                  <th>Pickup Date</th>
                  <th>Delivery Date</th>
                  <th>Transport type</th>
                  <th>Carrier pay</th>
                  <th>Note</th>
                  <th>Created at</th>
                  <th width="15%"></th>
                  <!-- <th></th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in offers" :key="item.id" :class="rowClass(item)">
                  <td>{{ item.user && item.user.name }}</td>
                  <td>
                    <b-link v-if="item.carrier" :to="carrierLink(item.carrier)">{{ item.carrier.contact.company }}
                    </b-link>
                  </td>
                  <td>{{ dateTypeLabel(item.pickupDateTypeId) }} {{ item.pickupDate | formatDate }}</td>
                  <td>{{ dateTypeLabel(item.deliveryDateTypeId) }} {{ item.deliveryDate | formatDate }}</td>
                  <td>{{ transportTypeName(item.transportTypeId) }}</td>
                  <td>{{ item.carrierPay }}</td>
                  <td>{{ item.note }}</td>
                  <td>{{ item.createdAt | formatDateTime }}</td>
                  <td>
                    <b-btn size="xs" variant="primary" v-if="!order.carrier" @click="handleDispatching(order, item)">
                      Dispatch</b-btn>
                  </td>
                  <!-- <td><b-link class="btn btn-primary btn-xs" v-if="item.carrier" :to="dispatchLink(item.carrier)">Dispatch</b-link></td> -->
                </tr>
              </tbody>
              <DispatchModal v-if="dispatching" :order="dispatchingOrder" :offer="dispatchingOffer"
                @refresh="$emit('refresh')" :on-close="() => (dispatching = null)"></DispatchModal>
            </table>
            <div class="alert alert-warning" v-else>No truckers have been added</div>
          </div>
        </div>

        <div class="card-body border border-top">
          <b-form @submit.prevent="submit" inline>
            <b-form-group label="Carrier" class="mr-3 carrier">
              <CarrierPicker @input="setCarrier" />
            </b-form-group>
            <b-form-group label="Pickup Date" class="mr-3 cdate">
              <flat-pickr class="" v-model="form.pickupDate" placeholder="Select date" :config="fltConfig" value="" />
            </b-form-group>
            <b-form-group label="Pickup date type" class="mr-3">
              <b-select class="custom-select" v-model="form.pickupDateTypeId" :options="dateTypeOptions" />
            </b-form-group>
            <b-form-group label="Delivery Date" class="mr-3 cdate">
              <flat-pickr class="" v-model="form.deliveryDate" placeholder="Select date" ref="datePicker"
                :config="fltConfig2" value="" />
            </b-form-group>
            <b-form-group label="Delivery date type" class="mr-3">
              <b-select class="custom-select" v-model="form.deliveryDateTypeId" :options="dateTypeOptions" />
            </b-form-group>
            <b-form-group label="Transport type" class="mr-3">
              <b-select class="custom-select" v-model="form.transportTypeId" :options="transportTypeOptions" />
            </b-form-group>
            <b-form-group label="Carrier Pay" class="mr-3">
              <b-input class="cpay" v-model="form.carrierPay" type="text" :number="true" />
            </b-form-group>
            <b-form-group label="Note" class="mr-3">
              <b-input v-model="form.note" type="text" />
            </b-form-group>
            <button class="btn btn-primary mt-4" :disabled="formSubmit ? 'disabled' : undefined">Create</button>
          </b-form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import CarrierPicker from './CarrierPicker';
import LoadingIndicator from './LoadingIndicator.vue';
import DispatchModal from './DispatchModal.vue';
import dateTypesMixin from '@/mixins/dateTypes';
import transportTypesMixin from '@/mixins/transportTypes';

export default {
  name: 'OrdersOffers',
  data() {
    let self = this;
    return {
      offers: [], carriers: [], searchTerm: '', offersLoading: true, formSubmit: false,
      dispatching: false,
      dispatchingOrder: null,
      dispatchingOffer: null,
      fltConfig: {
        inline: false,
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: false,
        onChange: function () {
          if (!self.form.deliveryDate) {
            self.$refs.datePicker.fp.open();
          }
        },
      },
      fltConfig2: {
        inline: false,
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: false,
        minDate: this.order.moveDate,
      },
      form: {
        id: null,
        leadId: this.order.id,
        pickupDate: this.order.moveDate,
        pickupDateTypeId: this.order.pickupDateTypeId,
        deliveryDate: null,
        deliveryDateTypeId: this.order.deliveryDateTypeId,
        transportTypeId: this.order.transportTypeId,
        carrierPay: this.order.carrierPay ? parseInt(this.order.carrierPay) : null,
        carrierId: null,
        note: null,
      },
      transportTypeOptions: [
        { value: null, text: '-' },
     //   { value: 1, text: 'Open' },
     //   { value: 2, text: 'Enclosed' },
      ],
      dateTypeOptions: [
        { value: null, text: '-' },
      ],
    };
  },
  mixins: [dateTypesMixin, transportTypesMixin],
  components: {
    CarrierPicker,
    LoadingIndicator,
    DispatchModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  created() {
    this.transportTypeOptions = this.transportTypeOptions.concat(this.transportTypes.map(x => {x.text = x.label; return x}));
    this.dateTypeOptions = this.dateTypeOptions.concat(this.dateTypes.map(x => {x.text = x.label; return x}));
    this.fetch();
  },
  methods: {
    handleDispatching(order, offer) {
      this.dispatching = true;
      this.dispatchingOrder = order;
      this.dispatchingOffer = offer;
    },
    async dispatchOrder(carrierId) {
      const response = await ApiClient.leads.dispatch.post({
        leadId: this.order.id,
        body: { carrierId: carrierId },
      });
      if (response.status === 200) {
        this.errors = {};
        this.$emit('refresh');
      } else if (response.status === 400) {
        this.errors = { generalError: response.data.message };
      } else if (response.status === 422) {
        this.errors = response.data.errors;
      }
    },
    transportTypeName(id) {
      if (id == 1) return 'Open';
      if (id == 2) return 'Enclosed';
      return '-';
    },
    setCarrier(carrier) {
      this.form.carrierId = carrier ? carrier.id : null;
    },
    async submit() {
      this.formSubmit = true;
      let form = Object.assign({}, this.form);
      const response = await ApiClient.leads.offers.post({ leadId: this.order.id, body: form });
      if (response.status === 200) {
        this.reset();
        this.fetch();
      }
      this.formSubmit = false;
    },
    reset() {
      this.form = Object.assign(this.form, {
        id: null,
        leadId: this.order.id,
        pickupDate: this.order.moveDate,
        pickupDateTypeId: this.order.pickupDateTypeId,
        deliveryDate: null,
        deliveryDateTypeId: this.order.deliveryDateTypeId,
        transportTypeId: this.order.transportTypeId,
        carrierPay: this.order.carrierPay,
        carrierId: null,
        note: null,
      });
    },
    async fetch() {
      this.offersLoading = true;
      const offersResponse = await ApiClient.leads.offers.get({ leadId: this.order.id });
      this.offers = offersResponse.data.data;
      this.offersLoading = false;
    },
    rowClass(item) {
      if (item.deletedAt) return 'text-secondary';
      return '';
    },
    async remove(id) {
      await ApiClient.leads.offers.delete({ leadId: this.order.id, id: id });
      this.fetch();
    },
    carrierLink(carrier) {
      return carrier ? { name: 'CarrierDetails', params: { id: carrier.id } } : null;
    },
    dispatchLink(carrier) {
      return carrier ? { name: 'OrderDispatch', params: { id: this.order.id }, query: { carrierId: carrier.id } } : null;
    },
  },
  watch: {
    'form.pickupDate': function (val) {
      this.fltConfig2.minDate = val;
    },
  }
};
</script>

<style lang="scss" >
.carrier {
  width: 300px;
}

.cpay {
  width: 90px;
}

.cdate .form-control {
  width: 110px;
}
</style>
