<template>
  <div>
    <div class="nav-responsive-lg mb-4">
      <b-nav tabs>
        <b-nav-item :active="getTabActive('/account/details')" :to="{ path: '/account/details', query: { ...$route.query } }">
          <i class="fas fa-info mr-2"></i> Account Detail
        </b-nav-item>
        <b-nav-item :active="getTabActive('/account/edit')" :to="{ path: '/account/edit', query: { ...$route.query } }"> <i class="fas fa-pen mr-2"></i> Edit Account </b-nav-item>
        <b-nav-item :active="getTabActive('/account/account-history')" :to="{ path: '/account/account-history', query: { ...$route.query } }">
          <i class="fas fa-user mr-2"></i> Account History
        </b-nav-item>
        <b-nav-item :active="getTabActive('/account/order-history')" :to="{ path: '/account/order-history', query: { ...$route.query } }">
          <i class="fas fa-truck-moving mr-2"></i> Order History
        </b-nav-item>
        <b-nav-item :active="getTabActive('/account/carrier-history')" :to="{ path: '/account/carrier-history', query: { ...$route.query } }">
          <i class="fas fa-dolly mr-2"></i> Carrier History
        </b-nav-item>
      </b-nav>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { tabActive } from '@/mixins/methods';

export default {
  name: 'AccountDetailTabs',
  mixins: [tabActive],
};
</script>

<style></style>
