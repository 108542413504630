<template>
  <div>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <h1>New carrier</h1>
      </div>
      <CarrierEdit :carrier="null" />
    </div>
  </div>
</template>

<script>
import CarrierEdit from '@/components/pages/carriers/CarrierEdit';

export default {
  name: 'CarrierNew',
  components: { CarrierEdit },
};
</script>

<style lang="scss" scoped></style>
