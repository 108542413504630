import LayoutCentered from '@/layout/LayoutCentered';
import QuoteList from '@/components/pages/quotes/QuoteList';
import QuoteNew from '@/components/pages/quotes/QuoteNew';
import Leadlike from '@/components/shared/Leadlike';
import LeadlikeDetails from '@/components/shared/LeadlikeDetails';
import LeadlikeEdit from '@/components/shared/LeadlikeEdit';
import LeadlikeEmails from '@/components/shared/LeadlikeEmails';
import LeadlikeSms from '@/components/shared/LeadlikeSms';
import MarketRatesList from '@/components/shared/MarketRatesList';
import LeadlikeHistory from '@/components/shared/LeadlikeHistory';
import QuoteAttachments from '@/components/shared/OrderAttachments';
import OrderLoadboards from '@/components/shared/OrderLoadboards';
import OrderOffers from '@/components/shared/OrderOffers';
import LeadViewsList from '@/components/shared/LeadViewsList';

export default [
  {
    path: '/quotes',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        component: QuoteList,
        name: 'QuoteList',
        path: '',
      },
      {
        component: QuoteList,
        name: 'QuotesArchived',
        path: 'archived',
        props: { showArchived: true },
      },
      {
        component: QuoteList,
        name: 'QuotesWithStatus',
        path: 'status/:status',
      },
      { path: 'new', name: 'QuoteNew', component: QuoteNew },
      {
        path: 'view/:id',
        component: Leadlike,
        props: { targetType: 'Quote' },
        children: [
          {
            path: '',
            name: 'QuoteDetails',
            component: LeadlikeDetails,
          },
          {
            path: 'edit',
            name: 'QuoteEdit',
            props: { leadlikeType: 'Quote' },
            component: LeadlikeEdit,
          },
          {
            path: 'emails',
            name: 'QuoteEmails',
            component: LeadlikeEmails,
          },
          {
            path: 'sms',
            name: 'QuoteSms',
            component: LeadlikeSms,
          },
          {
            path: 'market-rates',
            name: 'QuoteMarketRates',
            component: MarketRatesList,
          },
          {
            path: 'history',
            name: 'QuoteHistory',
            component: LeadlikeHistory,
          },
          {
            path: 'attachments',
            name: 'QuoteAttachments',
            component: QuoteAttachments,
          },
          {
            path: 'offers',
            name: 'QuoteOffers',
            component: OrderOffers,
          },
          {
            path: 'loadboards',
            name: 'QuoteLoadboards',
            component: OrderLoadboards,
          },
          {
            path: 'views',
            name: 'QuoteViews',
            component: LeadViewsList,
          },
        ],
      },
    ],
  },
];
