<template>
  <div>
    <LayoutCenteredTopBar />
    <div class="layout--content">
      <div class="layout--grid">
        <LayoutCenteredSidebar />
        <div class="layout--main-contnet">
          <div class="d-flex justify-content-between align-items-center">
            <b-breadcrumb class="my-4 text-capitalize h5" :items="breadcrumbs"></b-breadcrumb>
            <div>
              <router-link class="btn btn-warning btn-md" :to="{ name: 'QuoteNew' }"> <span class="mr-1 fas fa-plus"></span> New quote </router-link>
              <router-link class="btn btn-primary btn-md" :to="{ name: 'OrderNew' }"> <span class="mr-1 fas fa-plus"></span> New order </router-link>
            </div>
          </div>
          <router-view />
          <div class="text-center text-lighter p-3">{{ appVersion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutCenteredTopBar from './LayoutCenteredTopBar';
import LayoutCenteredSidebar from './LayoutCenteredSidebar';

export default {
  name: 'LayoutCentered',
  components: {
    LayoutCenteredTopBar,
    LayoutCenteredSidebar,
  },
  computed: {
    appVersion() {
      return document.documentElement.dataset.buildTimestampUtc;
    },
    breadcrumbs() {
      const { path } = this.$route;
      const [firstPathElement, ...otherPathElements] = path.split('/').filter(pathElement => pathElement);
      const parsedBreadcrumbs = [];
      if (firstPathElement) {
        parsedBreadcrumbs.push({ text: firstPathElement, to: { path: `/${firstPathElement}` } });
        if (otherPathElements && otherPathElements.length > 0) {
          parsedBreadcrumbs.push(otherPathElements.join(' '));
        }
      }
      return [{ text: 'Home', to: { path: '/' } }, ...parsedBreadcrumbs];
    },
  },
};
</script>

<style lang="scss" scoped>
.layout--content {
  max-width: 1440px;
  margin: 0 auto;
}

.layout--grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0px;
  min-height: calc(100vh - 72px);
}

.layout--main-contnet {
  padding: 0 16px 64px;
  background-color: #fafafa;
}
</style>
