const leadTypes = [
  { value: '1', label: 'Lead' },
  { value: '2', label: 'Quote' },
  { value: '3', label: 'Order' },
];

export default {
  data() {
    return {
      leadTypes,
    };
  },
  methods: {
    leadTypeLabel(value) {
      if (!value) {
        return '-';
      }
      return leadTypes.find(dt => dt.value == value).label;
    },
  },
};
