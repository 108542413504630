<template>
  <div class="lead-card-edit outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header"></div>
          <div class="modal-body">
            {{ label }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="$emit('close')">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
export default {
  name: 'InfoModal',
  props: {
    label: {
      type: String,
      default: 'Are you sure?',
    },
  },
};
</script>

<style lang="scss" scoped>
.outter-modal {
  position: absolute;
  z-index: 1089;
}

.modal-body {
  background-color: #fafafa;
}
</style>
