<template>
  <div>
    <b-btn v-if="location.path !== 'states'" variant="primary" class="mb-4 text-capitalize" pill @click="handleOpenCreateLocationModal">
      <span class="mr-1 fas fa-plus"></span> New {{ location.text }}
    </b-btn>
    <div class="nav-responsive-lg mb-2">
      <b-nav tabs>
        <b-nav-item :active="getTabActive('/locations/states')" to="/locations/states"> <i class="fas fa-flag-usa mr-2"></i> States </b-nav-item>
        <b-nav-item :active="getTabActive('/locations/cities')" to="/locations/cities"> <i class="fas fa-city mr-2"></i> Cities </b-nav-item>
        <b-nav-item :active="getTabActive('/locations/counties')" to="/locations/counties"> <i class="fas fa-flag mr-2"></i> Counties </b-nav-item>
        <b-nav-item :active="getTabActive('/locations/zips')" to="/locations/zips"> <i class="fas fa-map-marker mr-2"></i> Zips </b-nav-item>
      </b-nav>
    </div>
    <div>
      <router-view />
    </div>

    <LocationCreateModal :id="createModalId" :location="location" :on-submit="handleSubmitLocation" />
  </div>
</template>

<script>
import api from '@/api';
import { tabActive } from '@/mixins/methods';
import { getCleanObject } from '@/helpers/methods';
import LocationCreateModal from '@/components/shared/modals/LocationCreateModal';

export default {
  name: 'LocationsTabs',
  components: { LocationCreateModal },
  mixins: [tabActive],
  data() {
    return {
      createModalId: 'createModal',
      location: {
        path: 'states',
        text: 'state',
      },
    };
  },
  watch: {
    '$route.path': {
      handler: function (newVal) {
        this.handleSetLocation({ value: newVal });
      },
      immediate: true,
    },
  },
  methods: {
    async handleSubmitLocation({ form }) {
      const { path } = this.location;

      await api[path].post(null, getCleanObject(form));
    },
    handleOpenCreateLocationModal() {
      const { createModalId } = this;

      this.$bvModal.show(createModalId);
    },
    handleSetLocation({ value }) {
      const routes = value.split('/');
      const lastRoute = routes[routes.length - 1];
      this.location = {
        path: lastRoute,
        text: this.getSingularizeWord({ word: lastRoute }),
      };
    },
    getSingularizeWord({ word }) {
      return word.split('').reverse().join('').replace('sei', 'y').replace('s', '').split('').reverse().join('');
    },
  },
};
</script>

<style></style>
