const cancelReasons = [
  { value: 1, label: 'Customer Cancelled' },
  { value: 2, label: 'Customer Unreachable' },
  { value: 3, label: 'Vehicle Already Shipped' },
  { value: 4, label: 'Vehicle Not Ready for Shipment' },
  { value: 5, label: 'Payment Issue' },
  { value: 6, label: 'Missed Schedule' },
  { value: 7, label: 'Duplicate Order' },
  { value: 8, label: 'Refusal of Service' },
  { value: 9, label: 'Shipped by Competitor' },
  { value: 10, label: 'Other' },
];

export default {
  data() {
    return {
      cancelReasons,
    };
  },
  methods: {
    cancelReasonsLabel(value) {
      if (!value) {
        return '-';
      }
      return cancelReasons.find(dt => dt.value == value)?.label || '';
    },
  },
};
