export const leadPriorities = [
  { value: 0, text: 'No Priority', cssClass: 'bg-white' },
  { value: 1, text: 'Hot', cssClass: 'bg-hot' },
  { value: 2, text: 'Future', cssClass: 'bg-future' },
  { value: 3, text: 'Booked', cssClass: 'bg-booked' },
  { value: 4, text: 'Bad', cssClass: 'bg-bad' },
];

export default {
  data() {
    return {
      leadPriorities,
    };
  },
  methods: {
    getLeadPriority(value) {
      if (value) {
        return leadPriorities.find(p => p.value === value);
      } else {
        return leadPriorities[0];
      }
    },
  },
};
