<template>
  <div class="p-3" @click.stop="">
    <div class="text-right">
      <button @click="onClose" type="button" class="btn btn-sm btn-warning mb-3" data-bs-dismiss="modal" aria-label="Close">Close</button>
    </div>
    <table class="table table-hover" v-if="search">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in customers" :key="customer.id">
          <th scope="row">{{ customer.contact.firstName + ' ' + customer.contact.lastName }}</th>
          <td>{{ customer.contact.email }}</td>
          <td>{{ customer.contact.phone }}</td>
          <td><button type="button" class="btn btn-sm btn-primary" @click="fillForm(customer)">Select</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { apiRequest } from '@/api/apiRequest';

const searchFields = {
  firstName: 'first_name',
  phone: 'phone',
  email: 'email',
};

export default {
  props: {
    search: {
      type: Array,
      required: true,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      customers: null,
    };
  },
  methods: {
    async searchCustomer() {
      let searchPath = '/customers?';
      this.search.forEach(s => {
        if (s.newValue) {
          searchPath += searchFields[s.field] + '=' + s.newValue + '&';
        }
      });
      searchPath= searchPath.slice(0, -1);
      const response = await apiRequest({ path: searchPath });
      let prevCustomers = [];
      let uniqueCustomers = [];
      response.data.data.map(customer => {
        let customerData = (
          (customer.contact.firstName + customer.contact.lastName).replace(/\s/g, '') +
          customer.contact.email +
          customer.contact.phone.replace(/[^0-9]/g, '')
        ).trim();
        if (!prevCustomers.includes(customerData)) {
          uniqueCustomers.push(customer);
          prevCustomers.push(customerData);
        }
      });
      this.customers = uniqueCustomers;
    },
    fillForm(lead) {
      lead.contact.customerId = lead.id;
      this.$emit('fillField', lead.contact);
      this.onClose();
    },
  },
  watch: {
    search: {
      immediate: true,
      handler() {
        this.searchCustomer();
      },
    },
  },
  mounted() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.onClose();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.new-note-form {
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 24px;
}
</style>
