var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-4"},[_c('h5',{staticClass:"card-header"},[_vm._v("History")]),_vm._v(" "),_c('div',{staticClass:"card-body"},[(_vm.historyLoading)?_c('LoadingIndicator',{attrs:{"centered":""}}):_vm._e(),_vm._v(" "),(!_vm.historyLoading)?_c('b-table',{staticClass:"card-table",attrs:{"items":_vm.items,"fields":_vm.getTableFields(_vm.fields),"striped":"","bordered":"","responsive":""},scopedSlots:_vm._u([{key:"cell(userId)",fn:function(ref){
var value = ref.value;
return [_c('b-link',{attrs:{"to":_vm.userLink(value)}},[_vm._v(" #"+_vm._s(value)+" ")])]}},{key:"cell(userName)",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"cell(key)",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(value
            .split('.')
            .map(function (item) { return item.charAt(0).toUpperCase() + item.slice(1); })
            .join(' '))+"\n      ")]}},{key:"cell(oldValue)",fn:function(ref){
            var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"cell(newValue)",fn:function(ref){
            var value = ref.value;
return [_vm._v("\n        "+_vm._s(value)+"\n      ")]}},{key:"cell(createdAt)",fn:function(ref){
            var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(value)))])]}}],null,false,1906426366)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }