<template>
  <div class="rivals-list">
    <LoadingIndicator v-if="loading" centered />
    <div v-if="!loading">
      <table v-if="rivals.length > 0" class="table">
        <thead>
          <tr>
            <th>Listing ID</th>
            <th>Company</th>
            <th>Origin</th>
            <th>Destination</th>
            <th>Vehicle</th>
            <th>Move Date</th>
            <th>Price</th>
            <th>First seen</th>
            <th>Last seen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in rivals" :key="item.id">
            <td>{{ item.listingId }}</td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.originCity }} {{ item.originState }} {{ item.originZip }}</td>
            <td>{{ item.destinationCity }} {{ item.destinationState }} {{ item.destinationZip }}</td>
            <td>{{ item.vehicleType }} {{ item.vehicleMake }} {{ item.vehicleModel }} {{ item.vehicleYear }}</td>
            <td>{{ item.availableDate }}</td>
            <td>{{ item.price }}</td>
            <td>{{ item.crawlAt | formatDateTime }}</td>
            <td>{{ item.lastSeenAt | formatDateTime }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-info">No competition has been found yet.</div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  props: {
    leadlikeId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      rivals: [],
      loading: false,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await ApiClient.leads.rivals.get({ leadId: this.leadlikeId });
      this.loading = false;
      this.rivals = response.data.data;
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    leadlikeId: function () {
      this.fetch();
    },
  },
};
</script>
