import { contactList, locationList, contactKeys, locationKeys } from '@/helpers/variables';
import { getParsedTemplateCodes } from '@/helpers/methods';

const state = {
  items: [],
  configs: [
    { id: 0, value: '[id]', text: 'ID' },
    { id: 1, value: '[uuid]', text: 'UUID' },
    { id: 2, value: '[move_date]', text: 'Move Date' },
    { id: 3, value: '[pickup_date]', text: 'Pickup Date' },
    { id: 4, value: '[delivery_date]', text: 'Delivery Date' },
    { id: 5, value: '[shipper_notes]', text: 'Shipper Notes' },
    { id: 6, value: '[shipper_information]', text: 'Shipper Information' },
    { id: 7, value: '[carrier_pay]', text: 'Carrier Pay' },
    { id: 8, value: '[dispatch_information]', text: 'Dispatch Information' },
    { id: 9, value: '[driver_name]', text: 'Driver Name' },
    { id: 10, value: '[driver_phone]', text: 'Driver Phone' },
    { id: 11, value: '[lead_type]', text: 'Lead Type' },
    { id: 12, value: '[lead_status]', text: 'Lead Status' },
    { id: 13, value: '[transport_type]', text: 'Transport Type' },
    // ids start with 14
    ...getParsedTemplateCodes({ arr: contactList, keys: contactKeys }),
    ...getParsedTemplateCodes({ arr: locationList, keys: locationKeys }),
  ],
};

const getters = {
  emailConfigs: state => {
    return state.configs.filter(({ value }) => value.includes('email'));
  },
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
