import LayoutCentered from '@/layout/LayoutCentered';
import MarketRates from '@/components/pages/marketRates/MarketRates';
import MarketRatesMap from '@/components/pages/marketRates/MarketRatesMap';
import ActiveRoutes from '@/components/pages/marketRates/ActiveRoutes';
import Calculator from '@/components/pages/marketRates/Calculator';

export default [
  {
    path: '/market-rates',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'MarketRates',
        component: MarketRates,
      },
      {
        path: 'map',
        name: 'MarketRatesMap',
        component: MarketRatesMap,
      },
      {
        path: 'routes',
        name: 'ActiveRoutes',
        component: ActiveRoutes,
      },
      {
        path: 'calculator',
        name: 'Calculator',
        component: Calculator,
      },
    ],
  },
];
