const areaCodes = {
  201: ['EST', 'NJ'],
  202: ['EST', 'DIC'],
  203: ['EST', 'CT'],
  204: ['CST', null],
  205: ['CST', 'AL'],
  206: ['PST', 'WA'],
  207: ['EST', 'ME'],
  208: ['PST', 'ID'],
  209: ['PST', 'CA'],
  210: ['CST', 'TX'],
  212: ['EST', 'NY'],
  213: ['PST', 'CA'],
  214: ['CST', 'TX'],
  215: ['EST', 'PA'],
  216: ['EST', 'OH'],
  217: ['CST', 'IL'],
  218: ['CST', 'MN'],
  219: ['CST', 'IN'],
  220: ['EST', 'OH'],
  223: ['EST', 'PA'],
  224: ['CST', 'IL'],
  225: ['CST', 'LA'],
  226: ['EST', null],
  228: ['CST', 'MS'],
  229: ['EST', 'GA'],
  231: ['EST', 'MI'],
  234: ['EST', 'OH'],
  236: ['PST', null],
  239: ['EST', 'FL'],
  240: ['EST', 'MD'],
  242: ['EST', null],
  246: ['AST', null],
  248: ['EST', 'MI'],
  249: ['EST', null],
  250: ['PST', null],
  251: ['CST', 'AL'],
  252: ['EST', 'NC'],
  253: ['PST', 'WA'],
  254: ['CST', 'TX'],
  256: ['CST', 'AL'],
  260: ['EST', 'IN'],
  262: ['CST', 'WI'],
  264: ['AST', null],
  267: ['EST', 'PA'],
  268: ['AST', null],
  269: ['EST', 'MI'],
  270: ['CST', 'KY'],
  272: ['EST', 'PA'],
  276: ['EST', 'VA'],
  279: ['PST', 'CA'],
  281: ['CST', 'TX'],
  284: ['AST', null],
  289: ['EST', null],
  301: ['EST', 'MD'],
  302: ['EST', 'DE'],
  303: ['MST', 'CO'],
  304: ['EST', 'WV'],
  305: ['EST', 'FL'],
  306: ['CST', null],
  307: ['MST', 'WY'],
  308: ['MST', 'NE'],
  309: ['CST', 'IL'],
  310: ['PST', 'CA'],
  312: ['CST', 'IL'],
  313: ['EST', 'MI'],
  314: ['CST', 'MO'],
  315: ['EST', 'NY'],
  316: ['CST', 'KS'],
  317: ['EST', 'IN'],
  318: ['CST', 'LA'],
  319: ['CST', 'IA'],
  320: ['CST', 'MN'],
  321: ['EST', 'FL'],
  323: ['PST', 'CA'],
  325: ['CST', 'TX'],
  330: ['EST', 'OH'],
  331: ['CST', 'IL'],
  332: ['EST', 'NY'],
  334: ['CST', 'AL'],
  336: ['EST', 'NC'],
  337: ['CST', 'LA'],
  339: ['EST', 'MA'],
  340: ['AST', null],
  343: ['EST', null],
  345: ['EST', null],
  346: ['CST', 'TX'],
  347: ['EST', 'NY'],
  351: ['EST', 'MA'],
  352: ['EST', 'FL'],
  360: ['PST', 'WA'],
  361: ['CST', 'TX'],
  364: ['CST', 'KY'],
  365: ['EST', null],
  367: ['EST', null],
  380: ['EST', 'OH'],
  385: ['MST', 'UT'],
  386: ['EST', 'FL'],
  401: ['EST', 'RI'],
  402: ['CST', 'NE'],
  403: ['MST', null],
  404: ['EST', 'GA'],
  405: ['CST', 'OK'],
  406: ['MST', 'MT'],
  407: ['EST', 'FL'],
  408: ['PST', 'CA'],
  409: ['CST', 'TX'],
  410: ['EST', 'MD'],
  412: ['EST', 'PA'],
  413: ['EST', 'MA'],
  414: ['CST', 'WI'],
  415: ['PST', 'CA'],
  416: ['EST', null],
  417: ['CST', 'MO'],
  418: ['EST', null],
  419: ['EST', 'OH'],
  423: ['CST', 'TN'],
  424: ['PST', 'CA'],
  425: ['PST', 'WA'],
  430: ['CST', 'TX'],
  431: [null, null],
  432: ['CST', 'TX'],
  434: ['EST', 'VA'],
  435: ['MST', 'UT'],
  437: ['EST', null],
  438: ['EST', null],
  440: ['EST', 'OH'],
  441: ['AST', null],
  442: ['PST', 'CA'],
  443: ['EST', 'MD'],
  445: ['EST', 'PA'],
  450: ['EST', null],
  456: ['EST', null],
  458: ['PST', 'OR'],
  463: ['EST', 'IN'],
  469: ['CST', 'TX'],
  470: ['EST', 'GA'],
  473: ['AST', null],
  475: ['EST', 'CT'],
  478: ['EST', 'GA'],
  479: ['CST', 'AR'],
  480: ['MST', 'AZ'],
  484: ['EST', 'PA'],
  500: ['EST', null],
  501: ['CST', 'AR'],
  502: ['EST', 'KY'],
  503: ['PST', 'OR'],
  504: ['CST', 'LA'],
  505: ['MST', 'NM'],
  506: ['AST', null],
  507: ['CST', 'MN'],
  508: ['EST', 'MA'],
  509: ['PST', 'WA'],
  510: ['PST', 'CA'],
  512: ['CST', 'TX'],
  513: ['EST', 'OH'],
  514: ['EST', null],
  515: ['CST', 'IA'],
  516: ['EST', 'NY'],
  517: ['EST', 'MI'],
  518: ['EST', 'NY'],
  519: ['EST', null],
  520: ['MST', 'AZ'],
  521: ['EST', null],
  522: ['EST', null],
  530: ['PST', 'CA'],
  531: ['CST', 'NE'],
  533: ['EST', null],
  534: ['CST', 'WI'],
  539: ['CST', 'OK'],
  540: ['EST', 'VA'],
  541: ['PST', 'OR'],
  544: ['EST', null],
  548: ['EST', null],
  551: ['EST', 'NJ'],
  559: ['PST', 'CA'],
  561: ['EST', 'FL'],
  562: ['PST', 'CA'],
  563: ['CST', 'IA'],
  564: ['PST', 'WA'],
  566: ['EST', null],
  567: ['EST', 'OH'],
  570: ['EST', 'PA'],
  571: ['EST', 'VA'],
  573: ['CST', 'MO'],
  574: ['CST', 'IN'],
  575: ['MST', 'NM'],
  577: ['EST', null],
  579: ['EST', null],
  580: ['CST', 'OK'],
  581: ['EST', null],
  585: ['EST', 'NY'],
  586: ['EST', 'MI'],
  587: ['MST', null],
  588: ['EST', null],
  600: ['EST', null],
  601: ['CST', 'MS'],
  602: ['MST', 'AZ'],
  603: ['EST', 'NH'],
  604: ['PST', null],
  605: ['MST', 'SD'],
  606: ['CST', 'KY'],
  607: ['EST', 'NY'],
  608: ['CST', 'WI'],
  609: ['EST', 'NJ'],
  610: ['EST', 'PA'],
  612: ['CST', 'MN'],
  613: ['EST', null],
  614: ['EST', 'OH'],
  615: ['CST', 'TN'],
  616: ['EST', 'MI'],
  617: ['EST', 'MA'],
  618: ['CST', 'IL'],
  619: ['PST', 'CA'],
  620: ['MST', 'KS'],
  622: ['EST', null],
  623: ['MST', 'AZ'],
  626: ['PST', 'CA'],
  628: ['PST', 'CA'],
  629: ['CST', 'TN'],
  630: ['CST', 'IL'],
  631: ['EST', 'NY'],
  636: ['CST', 'MO'],
  639: ['CST', null],
  640: ['EST', 'NJ'],
  641: ['CST', 'IA'],
  646: ['EST', 'NY'],
  647: ['EST', null],
  649: ['EST', null],
  650: ['PST', 'CA'],
  651: ['CST', 'MN'],
  657: ['PST', 'CA'],
  660: ['CST', 'MO'],
  661: ['PST', 'CA'],
  662: ['CST', 'MS'],
  664: ['AST', null],
  667: ['EST', 'MD'],
  669: ['PST', 'CA'],
  670: [null, null],
  671: [null, null],
  678: ['EST', 'GA'],
  680: ['EST', 'NY'],
  681: ['EST', 'WV'],
  682: ['CST', 'TX'],
  684: [null, null],
  700: ['EST', null],
  701: ['MST', 'ND'],
  702: ['PST', 'NV'],
  703: ['EST', 'VA'],
  704: ['EST', 'NC'],
  705: ['EST', null],
  706: ['EST', 'GA'],
  707: ['PST', 'CA'],
  708: ['CST', 'IL'],
  709: ['AST', null],
  710: ['EST', null],
  712: ['CST', 'IA'],
  713: ['CST', 'TX'],
  714: ['PST', 'CA'],
  715: ['CST', 'WI'],
  716: ['EST', 'NY'],
  717: ['EST', 'PA'],
  718: ['EST', 'NY'],
  719: ['MST', 'CO'],
  720: ['MST', 'CO'],
  721: ['AST', null],
  724: ['EST', 'PA'],
  725: ['PST', 'NV'],
  726: ['CST', 'TX'],
  727: ['EST', 'FL'],
  731: ['CST', 'TN'],
  732: ['EST', 'NJ'],
  734: ['EST', 'MI'],
  737: ['CST', 'TX'],
  740: ['EST', 'OH'],
  743: ['EST', 'NC'],
  747: ['PST', 'CA'],
  754: ['EST', 'FL'],
  757: ['EST', 'VA'],
  758: ['AST', null],
  760: ['PST', 'CA'],
  762: ['EST', 'GA'],
  763: ['CST', 'MN'],
  765: ['EST', 'IN'],
  767: ['AST', null],
  769: ['CST', 'MS'],
  770: ['EST', 'GA'],
  772: ['EST', 'FL'],
  773: ['CST', 'IL'],
  774: ['EST', 'MA'],
  775: ['PST', 'NV'],
  778: ['PST', null],
  779: ['CST', 'IL'],
  780: ['MST', null],
  781: ['EST', 'MA'],
  782: ['AST', null],
  784: ['AST', null],
  785: ['MST', 'KS'],
  786: ['EST', 'FL'],
  787: ['AST', null],
  800: ['EST', null],
  801: ['MST', 'UT'],
  802: ['EST', 'VT'],
  803: ['EST', 'SC'],
  804: ['EST', 'VA'],
  805: ['PST', 'CA'],
  806: ['CST', 'TX'],
  807: ['CST', null],
  808: [null, 'HI'],
  809: ['EST', null],
  810: ['EST', 'MI'],
  812: ['CST', 'IN'],
  813: ['EST', 'FL'],
  814: ['EST', 'PA'],
  815: ['CST', 'IL'],
  816: ['CST', 'MO'],
  817: ['CST', 'TX'],
  818: ['PST', 'CA'],
  819: ['EST', null],
  820: ['PST', 'CA'],
  825: ['MST', null],
  828: ['EST', 'NC'],
  829: ['EST', null],
  830: ['CST', 'TX'],
  831: ['PST', 'CA'],
  832: ['CST', 'TX'],
  833: ['EST', null],
  838: ['EST', 'NY'],
  843: ['EST', 'SC'],
  844: ['EST', null],
  845: ['EST', 'NY'],
  847: ['CST', 'IL'],
  848: ['EST', 'NJ'],
  849: ['EST', null],
  850: ['CST', 'FL'],
  854: ['EST', 'SC'],
  855: ['EST', null],
  856: ['EST', 'NJ'],
  857: ['EST', 'MA'],
  858: ['PST', 'CA'],
  859: ['EST', 'KY'],
  860: ['EST', 'CT'],
  862: ['EST', 'NJ'],
  863: ['EST', 'FL'],
  864: ['EST', 'SC'],
  865: ['EST', 'TN'],
  866: ['EST', null],
  867: ['PST', null],
  868: ['AST', null],
  869: ['AST', null],
  870: ['CST', 'AR'],
  872: ['CST', 'IL'],
  873: ['EST', null],
  876: ['EST', null],
  877: ['EST', null],
  878: ['EST', 'PA'],
  888: ['EST', null],
  900: ['EST', null],
  901: ['CST', 'TN'],
  902: ['AST', null],
  903: ['CST', 'TX'],
  904: ['EST', 'FL'],
  905: ['EST', null],
  906: ['CST', 'MI'],
  907: [null, 'AK'],
  908: ['EST', 'NJ'],
  909: ['PST', 'CA'],
  910: ['EST', 'NC'],
  912: ['EST', 'GA'],
  913: ['CST', 'KS'],
  914: ['EST', 'NY'],
  915: ['MST', 'TX'],
  916: ['PST', 'CA'],
  917: ['EST', 'NY'],
  918: ['CST', 'OK'],
  919: ['EST', 'NC'],
  920: ['CST', 'WI'],
  925: ['PST', 'CA'],
  928: ['MST', 'AZ'],
  929: ['EST', 'NY'],
  930: ['EST', 'IN'],
  931: ['CST', 'TN'],
  934: ['EST', 'NY'],
  936: ['CST', 'TX'],
  937: ['EST', 'OH'],
  938: ['CST', 'AL'],
  939: ['AST', null],
  940: ['CST', 'TX'],
  941: ['EST', 'FL'],
  947: ['EST', 'MI'],
  949: ['PST', 'CA'],
  951: ['PST', 'CA'],
  952: ['CST', 'MN'],
  954: ['EST', 'FL'],
  956: ['CST', 'TX'],
  959: ['EST', 'CT'],
  970: ['MST', 'CO'],
  971: ['PST', 'OR'],
  972: ['CST', 'TX'],
  973: ['EST', 'NJ'],
  978: ['EST', 'MA'],
  979: ['CST', 'TX'],
  980: ['EST', 'NC'],
  984: ['EST', 'NC'],
  985: ['CST', 'LA'],
  986: ['PST', 'ID'],
  989: ['EST', 'MI'],
};

export default {
  data() {
    return {
      areaCodes,
    };
  },
  methods: {
    areaCodeTimzone(key) {
      if (!key) return null;
      return areaCodes[key] ? areaCodes[key][0] : null;
    },
    areaCodeState(key) {
      if (!key) return null;
      return areaCodes[key] ? areaCodes[key][1] : null;
    },
  },
};
