<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <LayoutCenteredTopBar />
      <div class="layout-container">
        <LayoutCenteredSidebar />
        <div class="layout-content">
          <div class="router-transitions container-fluid flex-grow-1 container-p-y">
            <router-view />
          </div>
          <div class="text-center text-lighter p-3">{{ appVersion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutCenteredTopBar from './LayoutCenteredTopBar';
import LayoutCenteredSidebar from './LayoutCenteredSidebar';

export default {
  name: 'LayoutFull',
  components: {
    LayoutCenteredTopBar,
    LayoutCenteredSidebar,
  },
  computed: {
    appVersion() {
      return document.documentElement.dataset.buildTimestampUtc;
    },
    breadcrumbs() {
      const { path } = this.$route;
      const [firstPathElement, ...otherPathElements] = path.split('/').filter(pathElement => pathElement);
      const parsedBreadcrumbs = [];
      if (firstPathElement) {
        parsedBreadcrumbs.push({ text: firstPathElement, to: { path: `/${firstPathElement}` } });
        if (otherPathElements && otherPathElements.length > 0) {
          parsedBreadcrumbs.push(otherPathElements.join(' '));
        }
      }
      return [{ text: 'Home', to: { path: '/' } }, ...parsedBreadcrumbs];
    },
  },
};
</script>
