<template>
  <div>
    <h1>Custom Notifications</h1>
    <div class="card mb-4">
      <h5 class="card-header">Add Custom Notification</h5>
      <div class="card-body">
        <ValidationObserver ref="observer">
          <form @submit.prevent="submit()">
            <div class="form-group">
              <label>Due Date*</label>
              <ValidationProvider name="Due Date" rules="required" v-slot="{ errors }">
                <div :class="{ 'flat-invalid': errors[0] }">
                  <flat-pickr
                    class="form-control"
                    id="dueDate"
                    :config="fltConfig"
                    v-model="date"
                    :errors="errors['dueDate']"
                    @input="newValue => handleInputChange('dueDate', newValue)"
                  />
                </div>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="grid--two-coluzmn">
              <ValidatedInput
                rules="required"
                label="Title"
                id="title"
                v-model="title"
                :errors="errorResolver('title')"
                @input="newValue => handleInputChange({ field: 'title', newValue })"
              />
            </div>
            <ValidatedInput
              label="Notification Body"
              id="body"
              rules="required"
              type="textarea"
              v-model="body"
              :errors="errorResolver('body')"
              @input="newValue => handleInputChange({ field: 'body', newValue })"
            />
            <button :disabled="submitDisabled" class="btn btn-primary" type="submit" @click="saveNotification">Save Notification</button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';
import { ApiClient } from '@/api/ApiClient';

export default {
  name: 'CustomNotification',
  components: {
    ValidatedInput,
  },
  data() {
    return {
      date: null,
      title: null,
      body: null,
      submitDisabled: false,
      fltConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:00',
        altInput: true,
        altFormat: 'm/d/Y H:i',
        allowInput: false,
      },
    };
  },
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
  },
  methods: {
    handleInputChange({ field, newValue }) {
      this.formFields[field] = newValue;
    },
    async saveNotification() {
      this.submitDisabled = true;
      const response = await ApiClient.notifications.custom.post({ body: { title: this.title, body: this.body, due_date: this.date } });
      this.submitDisabled = false;
      if (response.status == 200) {
        console.log(response);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
</style>
