<template>
  <div>
    <h5>Orders</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit">
        <b-form-radio v-model="periodType" name="periodType" value="list">Time period</b-form-radio>
        <b-select class="custom-select ml-3" v-if="periodType == 'list'" v-model="timePeriod" :options="timeOptions" />
        <span class="ml-3">OR</span>
        <b-form-radio v-model="periodType" name="periodType" value="custom" class="ml-3">Custom date range</b-form-radio>
        <flat-pickr class="form-control ml-3" v-if="periodType != 'list'" v-model="selectedDates" :config="fltConfig" />

        <b-select class="custom-select ml-5" v-model="filterType" :options="dataOptions" />
        
        <div class="ml-3">
          <multiselect
            class="d-inline-flex"
            v-model="orderStatus"
            track-by="value"
            label="label"
            placeholder="Order Status"
            :options="leadStatuses.filter(x=>x.value > 2)"
            :allow-empty="true"
            :multiple="true"
            :searchable="true"
            :show-labels="false"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
          >
            <span slot="noResult">None selected</span>
          </multiselect>
        </div>

        <button class="btn btn-primary ml-3" :disabled="loading" @click.stop="excelExport=false">Show Report</button>
        <button class="btn btn-primary ml-3" :disabled="loading" @click.stop="excelExport=true">Export</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <span v-if="empty">No records found</span>

    <b-card class="mb-4" v-if="!loading && items.length">
      <b-table striped hover :items="items" :fields="fields" caption-top>
        <template #table-caption>
          <span v-if="form.dispatchedStart">Dispatched from {{ form.dispatchedStart }} till {{ form.dispatchedEnd }}</span>
          <span v-if="form.createdStart">Created from {{ form.createdStart }} till {{ form.createdEnd }}</span>
        </template>
        <template #cell(userId)="data">
          <b-link v-if="data.value" :to="userLink(data.value)" target="_blank">{{ data.item.user ? data.item.user.name : '' }}</b-link>
        </template>
        <template #cell(id)="data">
          <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
        </template>
        <template #cell(balanceMethodId)="data">
          {{ paymentTypeLabel(data.value) }}
        </template>

        <template #custom-foot="data">
          <b-tr>
            <b-th v-for="field in data.fields" :key="field.key">{{ field.key == 'id' ? 'Totals' : totals[field.key] || '' }}</b-th>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import balanceMethodsMixin from '@/mixins/balanceMethods';
import leadStatusesMixin from '@/mixins/leadStatuses';



export default {
  name: 'ReportOrders',
  data() {
    return {
      excelExport: false,
      loading: false,
      loadingExport: false,
      empty: false,
      orderStatus: [],
      items: [],
      totals: {},
      periodType: 'list',
      filterType: 1,
      timePeriod: 1,
      selectedDates: null,
      form: {
        createdStart: null,
        createdEnd: null,
        dispatchedStart: null,
        dispatchedEnd: null,
        statuses: [],
      },
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
      },
      timeOptions: [
        { text: 'Current month', value: 1 },
        { text: 'Last month', value: 2 },
        { text: 'Current quarter', value: 3 },
        { text: 'Last quarter', value: 4 },
        { text: 'Current year', value: 5 },
        { text: 'All time', value: 6 },
      ],
      dataOptions: [
        { text: 'Created orders - during time frame', value: 1 },
        { text: 'Dispatched orders - during time frame', value: 2 },
      ],
      
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
/*        
        {
          key: 'userId',
          label: 'User',
          sortable: false,
        },
        {
          key: 'source',
          label: 'Source',
          sortable: true,
        },
*/        
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'tarrifs',
          label: 'Tarrif',
          sortable: true,
        },
        {
          key: 'deposit',
          label: 'Deposit',
          sortable: true,
        },
        {
          key: 'carrierPay',
          label: 'Carrier pay',
          sortable: true,
        },
        {
          key: 'brokerFee',
          label: 'Broker Fee',
          sortable: true,
        },
        {
          key: 'grossProfit',
          label: 'Gross Profit',
          sortable: true,
        },
        {
          key: 'ccPaymentSurcharge',
          label: 'CC Fee',
          sortable: true,
        },
        
        {
          key: 'customerPaidCompany',
          label: 'Deposit paid',
          sortable: true,
        },
        {
          key: 'pendingDeposit',
          label: 'Unpaid deposit',
          sortable: true,
        },
        {
          key: 'carrierOwesCompany',
          label: 'Carrier owes Nexus',
          sortable: true,
        },
        {
          key: 'companyOwesCarrier',
          label: 'Nexus owes Carrier',
          sortable: true,
        },
        {
          key: 'balanceMethodId',
          label: 'Balance method',
          sortable: true,
        },
        {
          key: 'orderAt',
          label: 'Order At',
          sortable: true,
        },
        {
          key: 'signedAt',
          label: 'Dispatched At',
          sortable: true,
        },
      ],
    };
  },
  mixins: [tableFields, permissionsMixin, balanceMethodsMixin, leadStatusesMixin],
  components: {
    LoadingIndicator,
    flatPickr,
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.fetchOrders();
    },
    async fetchOrders() {
      this.prepareDates();
      this.loading = true;

      if(this.excelExport){
        this.form.export = 1;
        const response = await ApiClient.reports.orders({ params: this.form, blobRespone: true, });
        delete this.form.export; 
      
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.csv');
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
      }
      else {
        const response = await ApiClient.reports.orders({ params: this.form });
        this.items = response.data.data.rows;
        this.totals = response.data.data.totals;
      }
      this.loading = false;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    orderLink(item) {
      if (item.leadTypeId == 3) return { name: 'OrderDetails', params: { id: item.id } };
      if (item.leadTypeId == 2) return { name: 'QuoteDetails', params: { id: item.id } };
      if (item.leadTypeId == 1) return { name: 'LeadDetails', params: { id: item.id } };
      return null;
    },
    prepareDates() {
      let parts = [];
      if (this.periodType == 'list') {
        if (this.timePeriod == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDates) return;
        parts = this.selectedDates.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }

      this.form.createdStart = null;
      this.form.createdEnd = null;
      this.form.dispatchedStart = null;
      this.form.dispatchedEnd = null;

      if (this.filterType == 1) {
        this.form.createdStart = parts[0];
        this.form.createdEnd = parts[1];
      } else {
        this.form.dispatchedStart = parts[0];
        this.form.dispatchedEnd = parts[1];
      }
      this.form.statuses = this.orderStatus.map(x => x.value).join(',');
    },

  },
};
</script>

<style lang="scss" scoped>
.text-danger a {
  color: inherit;
}
</style>
