export const leadStatuses = [
  { value: 1, label: 'New Lead' },
  { value: 2, label: 'New Quote	' },
  { value: 3, label: 'New Order	' },
  { value: 4, label: 'On Hold' },
  { value: 5, label: 'Canceled' },
  { value: 6, label: 'Posted' },
  { value: 7, label: 'Not Signed' },
  { value: 8, label: 'Dispatched' },
  { value: 9, label: 'Issues' },
  { value: 10, label: 'Completed' },
  { value: 11, label: 'Picked-Up' },
  { value: 12, label: 'Delivered' },
];

export default {
  data() {
    return {
      leadStatuses,
    };
  },
  methods: {
    leadStatusLabel(value) {
      return leadStatuses.find(pt => pt.value == value).label;
    },
  },
};
