<template>
  <div>
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit()">
        <div>
          <b-row>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">Customer information</h5>
                <div class="card-body">
                  <b-form-group label="Company Name"><b-input v-model="form.contact.company" type="text" rules="required" /></b-form-group>
                  <b-form-group label="First Name"><b-input v-model="form.contact.firstName" type="text" /></b-form-group>
                  <b-form-group label="Last Name"><b-input v-model="form.contact.lastName" type="text" /></b-form-group>
                  <b-form-group label="Email"><b-input v-model="form.contact.email" type="email" /></b-form-group>
                  <b-form-group label="Phone"><b-input v-model="form.contact.phone" type="text" /></b-form-group>
                  <b-form-group label="Phone 2"><b-input v-model="form.contact.phone2" type="text" /></b-form-group>
                  <b-form-group label="Cell Phone"><b-input v-model="form.contact.cellPhone" type="text" /></b-form-group>
                  <b-form-group label="Fax"><b-input v-model="form.contact.fax" type="text" /></b-form-group>
                  <b-form-group label="Contact"><b-input v-model="form.contact.contact" type="text" /></b-form-group>
                  <b-form-group label="Notes"><b-textarea v-model="form.notes" rows="3"></b-textarea></b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">Location Information</h5>
                <div class="card-body">
                  <b-form-group label="Address"><b-input v-model="form.location.address" type="text" /></b-form-group>
                  <b-form-group label="Address 2"><b-input v-model="form.location.address2" type="text" /></b-form-group>
                  <b-row>
                    <b-col cols="5"><b-form-group label="City"><b-input v-model="form.location.city" type="text" /></b-form-group></b-col>
                    <b-col><b-form-group label="Zip"><b-input v-model="form.location.zip" type="text" /></b-form-group></b-col>
                    <b-col><b-form-group label="State"><b-input v-model="form.location.state" type="text" /></b-form-group></b-col>
                    <b-col><b-form-group label="Country"><b-input v-model="form.location.country" type="text" /></b-form-group></b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <FormFieldsErrors :errors="errors" />
        </div>
        <button :disabled="loading" class="btn btn-primary" type="submit">Save</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import FormFieldsErrors from '@/components/shared/formFields/FormFieldsErrors';

const emptyForm = {
  id: null,
  notes: '',
  contact: {
    company: '',
    firstName: '',
    lastName: '',
    contact: '',
    contact2: '',
    phone: '',
    phone2: '',
    cellPhone: '',
    fax: '',
    email: '',
  },
  location: {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: 'USA',
  },
};

const datePicker = {
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'F j, Y',
  // minDate: 'today',
  wrap: true,
};

export default {
  name: 'CustomerEdit',
  created() {
    this.datePicker = datePicker;
  },
  data() {
    return {
      form: this.deepMergeForm(emptyForm, this.customer),
      errors: {},
      loading: false,
    };
  },
  props: {
    customer: {
      type: Object,
      default: null,
    },
  },
  components: { FormFieldsErrors },
  methods: {
    deepMergeForm(a, b) {
      if (!b) b = {};
      let start = Object.assign({}, a, b);
      for (let key of ['location', 'contact']) {
        if (!b[key]) start[key] = Object.assign({}, a[key]);
      }
      delete start.leads;
      return start;
    },
    async submit() {
      if (this.loading) return;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loading = true;
        let response = null;
        if (this.customer) {
          response = await ApiClient.customers.put({ id: this.customer.id, body: this.form });
        } else {
          response = await ApiClient.customers.post({ body: this.form });
        }
        this.loading = false;
        if (response.status === 200) {
          if (!this.customer) this.form = Object.assign({}, emptyForm);
          this.errors = {};
          const customer = response.data.data;
          this.$emit('save', customer);
          this.$router.push({ name: 'CustomerDetails', params: { id: customer.id } });
        } else if (response.status === 422) {
          this.errors = response.data.errors;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, { title: 'Error', variant: 'danger', solid: true });
    },
  },
  async mounted() {
    //console.log(this.form);
  },
};
</script>
