// Additional polyfills
import 'custom-event-polyfill';
import 'url-polyfill';

import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import globals from './globals';
import { confirmModal } from '@/mixins/methods';
import axios from 'axios';
import Echo from 'laravel-echo';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import './plugins/bootstrapVue';
import './plugins/CKEditor4';
// import './plugins/fontAwesomeIcon';
import './plugins/multiselect';
import './plugins/vue2GoogleMaps';
import './plugins/vueC3';
import './plugins/vueFlatpickr';
import './plugins/vuelidate';
import './plugins/vueMoment';
import './plugins/vuetrend';

import filters from './filters';
import { objectToCamelCase } from '@/api/caseHelpers';

// Set up socket.io client, global variable required by Laravel Echo
window.io = require('socket.io-client');

Vue.use(filters);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false;
Vue.config.theme = 'Soft';

Vue.prototype.$axios = axios;

// Global RTL flag
Vue.mixin({ data: globals, methods: confirmModal.methods });

new Vue({
  router,
  store,
  created() {
    const { dispatch, commit } = this.$store;

    // axios configs
    axios.defaults.baseURL = `${process.env.VUE_APP_NEXUS_URL}api`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
    axios.interceptors.response.use(
      response => {
        if (response.status === 200 && response.config.method !== 'get') {
          this.$bvToast.toast('Successfully done', {
            title: 'Action',
            variant: 'success',
            autoHideDelay: 3000,
            solid: true,
          });
        }
        return response;
      },
      error => {
        if (error.response.status === 401) {
          dispatch('auth/logout');
        } else if (error.response.status >= 400) {
          console.error(error.response.data.message);
          const errorMessage = error.response?.data?.message;
          this.$bvToast.toast(`Action failed: ${errorMessage}`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 3000,
            solid: true,
          });
        }
        return Promise.reject(error);
      }
    );
    // get user
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      const userData = JSON.parse(userInfo);
      commit('auth/setUserData', userData);
      dispatch('notifications/fetch');

      const params = {
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_NEXUS_ECHO_HOST,
        transports: ['websocket', 'polling'],
        auth: {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        },
        forceTLS: true,
      };
      Vue.prototype.$echo = new Echo(params);

      this.$echo.private(`users.${userData.user.id}`).listen('.NotificationCreated', e => {
        this.$store.commit('notifications/createNotification', objectToCamelCase(e.notification));
        const h = this.$createElement;
        const vNodesMsg = h('div', { class: ['d-inline-flex align-items-center px-2 pb-2'] }, [
          h('div', { class: ['notif-icon'] }, [
            h('div', { class: ['ui-icon big-icon fa fa-bell bg-white border-0 text-secondary rounded-circle'] },)
          ]),
          h('div', { class: ['notif-content ml-3'] }, [
            h('p', { class: ['notif-text m-0 font-weight-bold text-dark'] }, e.notification.title),
            h('p', { class: ['notif-text m-0 text-secondary'] }, e.notification.body)
          ]),
        ]);

        var notif = {
          title: `New Notification!`,
          autoHideDelay: 5000,
          appendToast: true,
          toastClass: `notif-toast shadow`,
        }

        if (e.notification.link) {
          if (e.notification.link.charAt(0) == '{') {
            notif.to = JSON.parse(e.notification.link);
          } else {
            notif.to = e.notification.link;
          }
        }

        this.$bvToast.toast([vNodesMsg], notif);
      }).listen('.UserUpdated', e => {
        let data = {};
        if(e.user) data.user = objectToCamelCase(e.user);
        if(e.permissions) data.permissions = objectToCamelCase(e.permissions);
        this.$store.commit('auth/setUserData', data);
      });

      this.$echo.private('leads.notes').listen('.LeadNoteChanged', e => {
        this.$store.commit('leads/createLeadNote', objectToCamelCase(e.note));
      });

    }
    // user configs
    dispatch('pagination/getPerPage');
    dispatch('app/loadPerPage');
  },
  render: h => h(App),
}).$mount('#app');
