/**
 * DEPRECATED
 *
 * Please use `ApiClient.js` from this folder that proveds same functonality, but also takes care of snake_case to camelCase conversion.
 */
import axios from 'axios';

export default {
  custom: {
    get(url, params = {}) {
      return axios.get(url, { params });
    },
  },
  emails: {
    post(id, payload) {
      return axios.post('/emails', payload);
    },
  },
  emailTemplates: {
    get(id, params) {
      if (id) {
        return axios.get(`/email-templates/${id}`, { params });
      }
      return axios.get(`/email-templates`, { params });
    },
    post(id, payload) {
      return axios.post('/email-templates', payload);
    },
    put(id, payload) {
      return axios.put(`/email-templates/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/email-templates/${id}`);
    },
    id: {
      preview: {
        get(id, leadId, params) {
          if (leadId) {
            return axios.get(`/email-templates/${id}/preview/${leadId}`, { params });
          }
          return axios.get(`/email-templates/${id}/preview`, { params });
        },
      },
    },
  },
  carriers: {
    get(id, params) {
      if (id) {
        return axios.get(`/carriers/${id}`, { params });
      }
      return axios.get(`/carriers`, { params });
    },
    post(id, payload) {
      return axios.post('/carriers', payload);
    },
    put(id, payload) {
      return axios.put(`/carriers/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/carriers/${id}`);
    },
  },
  cities: {
    get(id, params) {
      if (id) {
        return axios.get(`/cities/${id}`, { params });
      }
      return axios.get(`/cities`, { params });
    },
    post(id, payload) {
      return axios.post('/cities', payload);
    },
    put(id, payload) {
      return axios.put(`/cities/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/cities/${id}`);
    },
  },
  contacts: {
    get(id, params) {
      if (id) {
        return axios.get(`/contacts/${id}`, { params });
      }
      return axios.get(`/contacts`, { params });
    },
    post(id, payload) {
      return axios.post('/contacts', payload);
    },
    put(id, payload) {
      return axios.put(`/contacts/${id}`, payload);
    },
  },
  contactTypes: {
    get(id, params) {
      if (id) {
        return axios.get(`/contact-types/${id}`, params);
      }
      return axios.get('/contact-types', params);
    },
  },
  counties: {
    get(id, params) {
      if (id) {
        return axios.get(`/counties/${id}`, { params });
      }
      return axios.get(`/counties`, { params });
    },
    post(id, payload) {
      return axios.post('/counties', payload);
    },
    put(id, payload) {
      return axios.put(`/counties/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/counties/${id}`);
    },
  },
  customers: {
    get(id, params) {
      if (id) {
        return axios.get(`/customers/${id}`, { params });
      }
      return axios.get(`/customers`, { params });
    },
    post(id, payload) {
      return axios.post(`/customers`, payload);
    },
    put(id, payload) {
      return axios.put(`/customers/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/customers/${id}`);
    },
  },
  leads: {
    get(id, params) {
      if (id) {
        return axios.get(`/leads/${id}`, { params });
      }
      return axios.get('/leads', { params });
    },
    post(id, payload) {
      return axios.post(`/leads`, payload);
    },
    put(id, payload) {
      return axios.put(`/leads/${id}`, payload);
    },
    delete(id, payload) {
      return axios.delete(`/leads/${id}`, payload);
    },
    id: {
      dispatch: {
        post(id, carrierId) {
          return axios.post(`/leads/${id}/dispatch`, { carrier_id: carrierId });
        },
        delete(id) {
          return axios.delete(`/leads/${id}/dispatch`);
        },
      },
      offers: {
        get(id) {
          return axios.get(`/leads/${id}/offers`);
        },
        post(id, params) {
          return axios.post(`/leads/${id}/offers`, params);
        },
        delete(id) {
          return axios.delete(`/leads/${id}/offers`);
        },
      },
      claim: {
        post(id) {
          return axios.post(`/leads/${id}/claim`);
        },
      },
      reclaim: {
        post(id) {
          return axios.post(`/leads/${id}/reclaim`);
        },
      },
      history: {
        get(leadId, params) {
          return axios.get(`/leads/${leadId}/history`, { params });
        },
      },
      loadboards: {
        post(leadId, payload) {
          return axios.post(`/leads/${leadId}/loadboards`, payload);
        },
      },
      notes: {
        get(leadId, id, params) {
          if (id) {
            return axios.get(`/leads/${leadId}/notes/${id}`, { params });
          }
          return axios.get(`/leads/${leadId}/notes`, { params });
        },
        post(leadId, payload) {
          return axios.post(`/leads/${leadId}/notes`, payload);
        },
        put(leadId, id, payload) {
          return axios.put(`/leads/${leadId}/notes/${id}`, payload);
        },
        delete(leadId, id) {
          return axios.delete(`/leads/${leadId}/notes/${id}`);
        },
      },
      payments: {
        get(leadId, params) {
          return axios.get(`/leads/${leadId}/payments`, { params });
        },
        post(leadId, payload) {
          return axios.post(`/leads/${leadId}/payments`, payload);
        },
      },
      vehicles: {
        post(leadId, payload) {
          return axios.post(`/leads/${leadId}/vehicles`, payload);
        },
        put(leadId, id, payload) {
          return axios.put(`/leads/${leadId}/vehicles/${id}`, payload);
        },
        delete(leadId, id) {
          return axios.delete(`/leads/${leadId}/vehicles/${id}`);
        },
      },
    },
  },
  lead_notes: {
    get(id, params) {
      if (id) {
        return axios.get(`/lead_notes/${id}`, { params });
      }
    },
    post(id, payload) {
      return axios.post('/lead_notes', payload);
    },
  },
  leads_link_users: {
    get(id, params) {
      if (id) {
        return axios.get(`/leads_link_users/${id}`, params);
      }
      return axios.get('/leads_link_users', params);
    },
  },
  notifications: {
    get(id, params) {
      return axios.get('/notifications', { params });
    },
    put(id, payload) {
      return axios.put(`/notifications/${id}`, payload);
    },
    markRead: {
      post(id, payload) {
        return axios.post(`/notifications/mark-read`, payload);
      },
    },
  },
  payments: {
    get(id, params) {
      if (id) {
        return axios.get(`/payments/${id}`, { params });
      }
      return axios.get('/payments', { params });
    },
    put(id, payload) {
      return axios.put(`/payments/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/payments/${id}`);
    },
  },
  paymentTypes: {
    get(params) {
      return axios.get('/payment-types', { params });
    },
  },
  paymentDirections: {
    get(params) {
      return axios.get('/payment-directions', { params });
    },
  },
  reports: {
    sales: {
      get(id, params) {
        return axios.get('/reports/sales', { params });
      },
    },
  },
  states: {
    get(id, params) {
      if (id) {
        return axios.get(`/states/${id}`, { params });
      }
      return axios.get('/states', { params });
    },
    post(id, payload) {
      return axios.post('/states', payload);
    },
    put(id, payload) {
      return axios.put(`/states/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/states/${id}`);
    },
  },
  users: {
    get(id, params) {
      if (id) {
        return axios.get(`/users/${id}`, { params });
      }
      return axios.get('/users', { params });
    },
    post(id, payload) {
      if (id) {
        return axios.post(`/users/${id}`, payload);
      }
      return axios.post('/users', payload);
    },
    delete(id) {
      return axios.delete(`/users/${id}`);
    },
    id: {
      photo: {
        post(id, payload) {
          if (id) {
            return axios.post(`/users/${id}/photo`, payload);
          }
        },
        delete(id) {
          return axios.delete(`/users/${id}/photo`);
        },
      },
      sources: {
        get(id, params) {
          if (id) {
            return axios.get(`/users/${id}/sources`, { params });
          }
        },
        post(id, payload) {
          if (id) {
            return axios.post(`/users/${id}/sources`, payload);
          }
        },
      },
    },
    me: {
      get(id, params) {
        return axios.get('/users/me', { params });
      },
    },
    login: {
      post(id, payload) {
        return axios.post('/users/login', payload);
      },
    },
    logout: {
      post(id, payload) {
        return axios.post('/users/logout', payload);
      },
    },
  },
  vehicles: {
    get(id, params) {
      if (id) {
        return axios.get(`/vehicles/${id}`, { params });
      }
      return axios.get(`/vehicles`, { params });
    },
    post(id, payload) {
      if (id) {
        return axios.post(`/vehicles/${id}`, payload);
      }
      return axios.post('/vehicles', payload);
    },
    put(id, payload) {
      return axios.put(`/vehicles/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/vehicles/${id}`);
    },
    body: {
      get(id, params) {
        if (id) {
          return axios.get(`/vehicles/body/${id}`, { params });
        }
        return axios.get('/vehicles/body', { params });
      },
      post(id, payload) {
        if (id) {
          return axios.post(`/vehicles/body/${id}`, payload);
        }
        return axios.post(`/vehicles/body`, payload);
      },
    },
    make: {
      get(id, params) {
        if (id) {
          return axios.get(`/vehicles/make/${id}`, { params });
        }
        return axios.get('/vehicles/make', { params });
      },
      post(id, payload) {
        if (id) {
          return axios.post(`/vehicles/make/${id}`, payload);
        }
        return axios.post('/vehicles/make', payload);
      },
    },
    model: {
      get(id, params) {
        if (id) {
          return axios.get(`/vehicles/model/${id}`, { params });
        }
        return axios.get('/vehicles/model', { params });
      },
      post(id, payload) {
        if (id) {
          return axios.post(`/vehicles/model/${id}`, payload);
        }
        return axios.post('/vehicles/model', payload);
      },
    },
  },
  cdVehicleTypes: {
    get(id, params) {
      if (id) {
        return axios.get(`/cd_vehicle-types/${id}`, { params });
      }
      return axios.get(`/cd_vehicle-types`, { params });
    },
  },
  vehicleTypes: {
    get(id, params) {
      if (id) {
        return axios.get(`/vehicle-types/${id}`, { params });
      }
      return axios.get(`/vehicle-types`, { params });
    },
    post(id, payload) {
      return axios.post('/vehicle-types', payload);
    },
    put(id, payload) {
      return axios.put(`/vehicle-types/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/vehicle-types/${id}`);
    },
  },
  vehicleMakes: {
    get(id, params) {
      if (id) {
        return axios.get(`/vehicle-makes/${id}`, { params });
      }
      return axios.get(`/vehicle-makes`, { params });
    },
    post(id, payload) {
      return axios.post('/vehicle-makes', payload);
    },
    put(id, payload) {
      return axios.put(`/vehicle-makes/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/vehicle-makes/${id}`);
    },
  },
  tasks: {
    get(id, params) {
      return axios.get(`/tasks`, { params });
    },
    post(id, payload) {
      return axios.post('/tasks', payload);
    },
    put(id, payload) {
      return axios.put(`/tasks/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/tasks/${id}`);
    },
  },
  sources: {
    get(id, params) {
      return axios.get(`/sources`, { params });
    },
    post(id, payload) {
      return axios.post('/sources', payload);
    },
  },
  zips: {
    get(id, params) {
      if (id) {
        return axios.get(`/zips/${id}`, { params });
      }
      return axios.get(`/zips`, { params });
    },
    post(id, payload) {
      return axios.post('/zips', payload);
    },
    put(id, payload) {
      return axios.put(`/zips/${id}`, payload);
    },
    delete(id) {
      return axios.delete(`/zips/${id}`);
    },
  },
};
