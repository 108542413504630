import { MapElementFactory } from 'vue2-google-maps';

export default MapElementFactory({
  name: 'DirectionsRenderer',
  ctr: () => window.google.maps.DirectionsRenderer,
  events: [],
  mappedProps: {},
  props: {
    route: { type: Object },
  },
  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService();

    this.$watch(
      () => this.route,
      route => {
        const { origin, destination } = route;
        if (origin && destination) {
          directionsService.route(
            {
              origin: { query: origin },
              destination: { query: destination },
              travelMode: 'DRIVING',
            },
            (response, status) => {
              if (status === 'OK') {
                this.$emit('update-route-info', { route: response?.routes[0].legs[0] });
                directionsRenderer.setDirections(response);
              }
            }
          );
        }
      }
    );
  },
});
