<template>
  <div>
    <ul class="nav nav-pills mb-4">
      <li class="nav-item"><b-link :to="{ name: 'MarketRates'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRates' }">Market Rates</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'ActiveRoutes'}" :class="{ 'nav-link': true, active: this.$route.name === 'ActiveRoutes' }">Active routes</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'MarketRatesMap'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRatesMap' }">Location map</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'Calculator'}" :class="{ 'nav-link': true, active: this.$route.name === 'Calculator' }">Calculator</b-link></li>
    </ul>
    <div class="card mb-4">
      <div class="card-body">
        <LoadingIndicator v-if="loading" centered />

        <b-form inline v-if="!loading" class="mb-3" @submit.prevent="prevent">
          Origin state: <b-input v-model="origin" class="ml-1 mr-3" placeholder="All states" />
          Destination state: <b-input v-model="destination" class="ml-1" placeholder="All states" />
        </b-form>

        <b-table v-if="!loading" :items="displayItems" :fields="fields" striped bordered responsive class="card-table"></b-table>
      </div>
    </div>
  </div>
</template>


<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  name: 'ActiveRoutes',
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        { key: 'originState', label: 'Origin State', sortable: true },
        { key: 'destinationState', label: 'Destination State', sortable: true },
        { key: 'items', label: 'Listings', sortable: true },
        { key: 'pricePerMile', label: 'Price per mile', sortable: true },
      ],
      origin: null,
      destination: null,
    };
  },
  components: {
    LoadingIndicator,
  },
  computed: {
    displayItems(){
      let list = this.items;
      if(this.origin) list = list.filter(x => x.originState.toLowerCase().startsWith(this.origin.toLowerCase()));
      if(this.destination) list = list.filter(x => x.destinationState.toLowerCase().startsWith(this.destination.toLowerCase()));
      return list;
    }
  },
  async created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await ApiClient.marketRates.activeRoutes();
      this.items = response.data.data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.zip {
  width: 60px;
}
</style>
