export const balanceTermStartOptions = [
  { value: 1, label: 'pickup' },
  { value: 2, label: 'delivery' },
  { value: 3, label: 'receiving a signed Bill of Lading' },
];

export default {
  data() {
    return {
      balanceTermStartOptions,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      return balanceTermStartOptions.find(pt => pt.value == value).label;
    },
  },
};
