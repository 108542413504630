<template>
  <div class="sms-list">
    <LoadingIndicator v-if="loading" centered />
    <div v-if="!loading">
      <table v-if="sms.length > 0" class="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Sender</th>
            <th>Recipient</th>
            <th>Text</th>
            <th>Sent At</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sms" :key="item.id">
            <td>{{ item.user ? item.user.name : '' }}</td>
            <td>{{ item.phoneFrom }}</td>
            <td>{{ item.phone }}</td>
            <td class="pre">{{ item.text }}</td>
            <td>{{ item.sentAt | formatDateTime }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-info">No SMS have been sent yet.</div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  props: {
    leadlikeId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      sms: [],
      loading: false,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await ApiClient.sms.get({ params: { leadId: this.leadlikeId } });
      this.loading = false;
      this.sms = response.data.data;
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    leadlikeId: function () {
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.pre {
  white-space: pre-wrap;
}
</style>
