<template>
  <div class="card">
    <div class="card-header" v-if="!preselected">Emails</div>
    <div class="card-body">
      <div v-if="step === 'composing'">
        <div v-if="selectedTemplate">
          <div v-if="!preselected">
            <div class="emails-header">
              <div class="mr-2">{{ selectedTemplate.title }}</div>
              <button class="btn btn-secondary btn-sm" @click="selectTemplate(null)">Change template</button>
            </div>
            <LoadingIndicator v-if="loadingEmailPreview" centered />
            <div v-else>
              <div class="row">
                <div class="col-6"><ValidatedInput label="To" id="to" v-model="toAddress" /></div>
                <div class="col-6"><ValidatedInput label="Bcc" id="bcc" v-model="bccAddress" /></div>
              </div>
              <div class="row">
                <div class="col-6"><ValidatedInput label="From" id="from" v-model="fromAddress" /></div>
                <div class="col-6"><ValidatedInput label="From name" id="fromName" v-model="fromName" /></div>
              </div>
              <ValidatedInput label="Subject" id="subject" v-model="subject" />
              <ValidatedInput label="Plain Text Content" type="textarea" id="contentPlain" v-model="contentPlain" />
              <label for="contentHtml">HTML Content</label>
              <ckeditor id="contentHtml" v-model="contentHtml" :config="{ allowedContent: true, versionCheck: false }"></ckeditor>
              <div class="d-flex justify-content-end pt-4">
                <button class="btn btn-primary" @click="sendEmail">Send email</button>
              </div>
            </div>
          </div>
          <div v-else>
            <p>
              Are you sure you want to send <b>{{ selectedTemplate.title }}</b> to the customer?
            </p>
            <div class="d-flex justify-content-end pt-4">
              <button class="btn btn-warning" @click="$emit('clickclose');">No</button>
              <button class="btn btn-primary ml-2" @click="sendEmail">Yes</button>
            </div>
          </div>
        </div>
        <div v-else>
          <h4>Select an email template</h4>
          <LoadingIndicator v-if="loadingTemplate" centered />
          <ul v-else class="list-group">
            <li v-for="template in templates" :key="template.id" class="list-group-item list-group-item-action" @click="selectTemplate(template.id)">
              {{ template.title }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="step === 'sending'">
        <div class="status-message">
          <h2>Sending email...</h2>
          <LoadingIndicator centered />
        </div>
      </div>
      <div v-if="step === 'sent'">
        <div class="status-message">
          <h2><i class="far fa-envelope mr-2"></i> Email sent!</h2>
          <button class="btn btn-primary" @click="newEmail">Send another email</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import ValidatedInput from '@/components/shared/ValidatedInput';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  name: 'LeadlikeEmails',
  props: {
    leadQuoteOrder: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: String,
      default: null,
    },
    preselected: {
      type: String,
      default: null,
    },
  },
  components: { ValidatedInput, LoadingIndicator },
  data() {
    return {
      loadingTemplate: false,
      loadingEmailPreview: false,
      step: 'composing',
      selectedTemplateId: null,
      toAddress: '',
      bccAddress: '',
      fromAddress: '',
      fromName: '',
      subject: '',
      contentPlain: '',
      contentHtml: '',
    };
  },
  computed: {
    selectedTemplate() {
      return this.templates.find(t => t.id == this.selectedTemplateId);
    },
    templates() {
      return this.$store.getters['leads/getEmailTemplates'](this.leadQuoteOrder.leadTypeId);
    },
  },
  async created() {
    if (this.selected) {
      let template = this.templates.find(t => t.name == this.selected);
      if (template) this.selectTemplate(template.id);
    }
  },
  mounted() {
    if (this.preselected) {
      let template = this.templates.find(t => t.name == this.preselected);
      if (template) this.selectTemplate(template.id);
    }
  },
  methods: {
    async selectTemplate(id) {
      this.selectedTemplateId = id;
      if (this.selectedTemplateId) {
        this.loadingEmailPreview = true;
        const response = await ApiClient.emailTemplates.preview.get({
          id: this.selectedTemplateId,
          leadId: this.leadQuoteOrder.id,
        });
        this.loadingEmailPreview = false;
        const data = response.data.data;
        if (data) {
          this.toAddress = data.toAddress;
          this.bccAddress = data.bccAddress;
          this.fromName = data.fromName;
          this.fromAddress = data.fromAddress;
          this.subject = data.subject;
          this.contentPlain = data.contentPlain;
          this.contentHtml = data.contentHtml;
        }
      }
    },
    async sendEmail() {
      this.step = 'sending';
      const response = await ApiClient.emails.post({
        body: {
          leadId: this.leadQuoteOrder.id,
          toAddress: this.toAddress,
          bccAddress: this.bccAddress,
          fromName: this.fromName,
          fromAddress: this.fromAddress,
          subject: this.subject,
          contentPlain: this.contentPlain,
          contentHtml: this.contentHtml,
        },
      });
      if (response.status === 200) {
        this.step = 'sent';
      } else {
        this.step = 'composing';
      }
    },
    newEmail() {
      this.step = 'composing';
      this.selectedTemplateId = null;
      this.$emit('resetModal');
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  display: flex;
  align-items: center;
}
.emails-header {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1em;
}
.list-group-item-action {
  cursor: pointer;
}
.status-message {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
}
</style>
