<template>
  <span>
    <span v-if="location.city || location.state">
      {{ location.city }}
      {{ location.state }}
    </span>
    <span v-else> Unknown </span>
  </span>
</template>

<script>
export default {
  name: 'LeadlikeTableLocation',
  props: {
    location: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped></style>
