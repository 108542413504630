<template>
  <div class="card mb-4">
    <h5 class="card-header">History</h5>
    <div class="card-body">
      <LoadingIndicator v-if="historyLoading" centered />

      <!-- Table -->
      <b-table v-if="!historyLoading" :items="items" :fields="getTableFields(fields)" striped bordered responsive class="card-table">
        <template #cell(userId)="{ value }" v-if="viewPerm">
          <b-link v-if="value" :to="userLink(value)"> #{{ value }} </b-link>
        </template>

        <template #cell(userName)="{ value }">
          {{ value ? value : 'System' }}
        </template>

        <template #cell(key)="{ value }">
          {{
            value
              .split('.')
              .map(item => item.charAt(0).toUpperCase() + item.slice(1))
              .join(' ')
          }}
        </template>

        <template #cell(oldValue)="{ value }">
          {{ value }}
        </template>

        <template #cell(newValue)="{ value }">
          {{ value }}
        </template>

        <template #cell(createdAt)="{ value }">
          <div :title="value">{{ formatDateTime(value) }}</div>
        </template>
      </b-table>
      <!-- / Table -->
    </div>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'LeadlikeHistory',
  data() {
    return {
      historyLoading: false,
      items: [],
      fields: [{ key: 'userId' }, { key: 'userName' }, { key: 'key', label: 'Field' }, { key: 'oldValue' }, { key: 'newValue' }, { key: 'createdAt' }],
      viewPerm: this.tokenCan('users'),
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
  },
  props: {
    leadQuoteOrder: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.fetchLeadHistory();
  },
  methods: {
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    formatDateTime(date) {
      return new Date(date).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' });
    },
    async fetchLeadHistory() {
      this.historyLoading = true;
      const response = await ApiClient.leads.history({ id: this.leadQuoteOrder.id });
      this.items = response.data.data;
      this.historyLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
