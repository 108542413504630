<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Competition</h1>
      <div class="d-flex align-items-center">
          <div class="mr-2">Claimed By:</div> 
          <div>
            <UserDropdown @select="updateUserFilter" :value="userFilter" />
          </div>
        </div>
    </div>
    <LoadingIndicator v-if="loading" centered />
    <p v-if="empty">No matching data found</p>
    <b-tabs class="nav-tabs-top mb-4" v-if="!loading && items.length">
      <b-tab :title="`Leads (${leads.length})`">
        <div class="card-body">
          <b-dropdown id="dropdown-form" size="sm" ref="dropdown" variant="outline-secondary" class="mb-4">
            <template #button-content>
              Selected Priorities:
              <span>{{ selectedPriorities.length || 'None' }}</span>
            </template>
            <b-dropdown-form>
              <div class="mb-3 custom-control custom-checkbox">
                <input @click="setFilter(0)" class="custom-control-input" id="filter-no" type="checkbox" :checked="getFilter(0)" />
                <label for="filter-no" class="custom-control-label">
                  <div>No Priority</div>
                </label>
              </div>
              <div class="mb-3 custom-control custom-checkbox">
                <input @click="setFilter(1)" class="custom-control-input" id="filter-hot" type="checkbox" :checked="getFilter(1)" />
                <label for="filter-hot" class="custom-control-label">
                  <div>Hot</div>
                </label>
              </div>
              <div class="mb-3 custom-control custom-checkbox">
                <input @click="setFilter(2)" class="custom-control-input" id="filter-future" type="checkbox" :checked="getFilter(2)" />
                <label for="filter-future" class="custom-control-label">
                  <div>Future</div>
                </label>
              </div>
              <div class="mb-3 custom-control custom-checkbox">
                <input @click="setFilter(3)" class="custom-control-input" id="filter-booked" type="checkbox" :checked="getFilter(3)" />
                <label for="filter-booked" class="custom-control-label">
                  <div>Booked</div>
                </label>
              </div>
              <div class="mb-3 custom-control custom-checkbox">
                <input @click="setFilter(4)" class="custom-control-input" id="filter-bad" type="checkbox" :checked="getFilter(4)" />
                <label for="filter-bad" class="custom-control-label">
                  <div>Bad</div>
                </label>
              </div>
            </b-dropdown-form>
          </b-dropdown>
          <b-table striped hover :items="leads" :fields="fields">
            <template #cell(id)="data">
              <b-link v-if="data.value" :to="leadLink(data.value)">{{ data.value }}</b-link>
            </template>
            <template #cell(lastSeenAt)="data">
              {{ data.value | formatDateTime }}
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab :title="`Quotes (${quotes.length})`">
        <div class="card-body">
          <b-table striped hover :items="quotes" :fields="fields">
            <template #cell(id)="data">
              <b-link v-if="data.value" :to="quoteLink(data.value)">{{ data.value }}</b-link>
            </template>
            <template #cell(lastSeenAt)="data">
              {{ data.value | formatDateTime }}
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab :title="`Orders (${orders.length})`" active>
        <div class="card-body">
          <b-table striped hover :items="orders" :fields="fields">
            <template #cell(id)="data">
              <b-link v-if="data.value" :to="orderLink(data.value)">{{ data.value }}</b-link>
            </template>
            <template #cell(lastSeenAt)="data">
              {{ data.value | formatDateTime }}
            </template>
          </b-table>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import UserDropdown from '@/components/shared/UserDropdown';

export default {
  name: 'Competition',
  data() {
    return {
      loading: false,
      empty: false,
      items: [],
      selectedPriorities: [],
      fields: [
        {
          key: 'id',
          label: 'Order',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'carrierPay',
          label: 'Carrier Pay',
          sortable: true,
        },
        {
          key: 'cdPrice',
          label: 'CD Price',
          sortable: true,
        },
        {
          key: 'vehicle',
          label: 'Vehicle',
          sortable: true,
        },
        {
          key: 'cdVehicle',
          label: 'CD Vehicle',
          sortable: true,
        },
        {
          key: 'companyName',
          label: 'Company Name',
          sortable: true,
        },
        {
          key: 'originZip',
          label: 'Pickup',
          sortable: true,
        },
        {
          key: 'destinationZip',
          label: 'Delivery',
          sortable: true,
        },
        {
          key: 'lastSeenAt',
          label: 'Last seen at',
          sortable: true,
        },
      ],
    };
  },
  components: {
    LoadingIndicator,
    UserDropdown
  },
  computed: {
    userFilter() {
      return this.$store.state.leads.userFilter;
    },
    leads() {
      let lead = this.items.filter(x => x.leadTypeId == 1);
      let selecteds = this.selectedPriorities;
      if (this.selectedPriorities.length > 0) {
        return lead.filter(function (e) {
          return selecteds.includes(e.priority);
        });
      }
      return lead;
    },
    quotes() {
      return this.items.filter(x => x.leadTypeId == 2);
    },
    orders() {
      return this.items.filter(x => x.leadTypeId == 3);
    },
  },
  methods: {
    updateUserFilter(userId) {
      if (this.userFilter != userId) {
        this.$store.commit('leads/setUserFilter', userId);
        this.fetch();
      }
    },
    setFilter(type) {
      let currtypes = this.selectedPriorities;
      if (currtypes.includes(type)) {
        var index = currtypes.indexOf(type);
        if (index !== -1) {
          currtypes.splice(index, 1);
        }
      } else {
        currtypes.push(type);
      }
    },
    getFilter(type) {
      if (this.selectedPriorities.includes(type)) {
        return true;
      }
      return false;
    },
    async fetch() {
      this.loading = true;
      let opts = {
        userId: this.userFilter,
      };
      const response = await ApiClient.reports.competition({params: opts});
      this.loading = false;
      this.items = response.data.data;
      this.empty = !response.data.data.length;
    },
    orderLink(id) {
      return { name: 'OrderDetails', params: { id: id } };
    },
    quoteLink(id) {
      return { name: 'QuoteDetails', params: { id: id } };
    },
    leadLink(id) {
      return { name: 'QuoteDetails', params: { id: id } };
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
