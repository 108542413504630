<template>
  <b-modal :id="id" :title="`#${route.id}`" size="lg" body-class="p-0" static centered hide-footer>
    <GmapMap
      ref="gmapRef"
      :center="{ lat: 41.850033, lng: -87.6500523 }"
      :options="{ disableDefaultUI: true, minZoom: 4 }"
      map-type-id="roadmap"
      style="width: 100%; height: 450px"
    >
      <DirectionsRenderer travel-mode="DRIVING" :route="route" @update-route-info="handleUpdateRouteInfo" />
    </GmapMap>
    <b-row align-h="between" align-v="center" class="p-3">
      <b-col cols="auto" tag="h5" class="mb-0">
        <i class="mr-1 fas fa-lg fa-road"></i>
        {{ distance }}
      </b-col>

      <b-col cols="auto" tag="h5" class="mb-0 text-capitalize" v-html="route.infoHtml" />

      <b-col cols="auto" tag="h5" class="mb-0">
        <i class="mr-1 fas fa-lg fa-clock"></i>
        {{ days }}
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import DirectionsRenderer from './DirectionsRenderer';

export default {
  name: 'RouteMapModal',
  components: {
    DirectionsRenderer,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      distance: null,
      duration: null,
      days: '',
    };
  },
  methods: {
    handleUpdateRouteInfo({ route }) {
      const { distance, duration } = route;
      this.distance = distance?.text;
      this.duration = duration?.text;
      this.getDays(this.distance);
    },

    getDays(dist) {
      var miles = dist.replace(/\D/g, '');
      if (miles >= 0 && miles <= 500) {
        this.days = '1 day';
      }
      if (miles >= 501 && miles <= 1000) {
        this.days = '2-3 days';
      }
      if (miles >= 1001 && miles <= 1500) {
        this.days = '3-4 days';
      }
      if (miles >= 1501 && miles <= 2000) {
        this.days = '4-5 days';
      }
      if (miles >= 2001 && miles <= 2500) {
        this.days = '5-7 days';
      }
      if (miles >= 2501 && miles <= 3000) {
        this.days = '7-9 days';
      }
      if (miles >= 3001) {
        this.days = '10-12 days';
      }
    },
  },
};
</script>
