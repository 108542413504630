var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"nav nav-pills mb-4"},[_c('li',{staticClass:"nav-item"},[_c('b-link',{class:{ 'nav-link': true, active: this.$route.name === 'MarketRates' },attrs:{"to":{ name: 'MarketRates'}}},[_vm._v("Market Rates")])],1),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('b-link',{class:{ 'nav-link': true, active: this.$route.name === 'ActiveRoutes' },attrs:{"to":{ name: 'ActiveRoutes'}}},[_vm._v("Active routes")])],1),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('b-link',{class:{ 'nav-link': true, active: this.$route.name === 'MarketRatesMap' },attrs:{"to":{ name: 'MarketRatesMap'}}},[_vm._v("Location map")])],1),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('b-link',{class:{ 'nav-link': true, active: this.$route.name === 'Calculator' },attrs:{"to":{ name: 'Calculator'}}},[_vm._v("Calculator")])],1)]),_vm._v(" "),_c('h2',[_vm._v("Pickup locations")]),_vm._v(" "),_c('GmapMap',{ref:"pickupMapRef",staticStyle:{"width":"100%","height":"800px"},attrs:{"zoom":4,"center":{ lat: 41.850033, lng: -96.6500523 },"map-type-id":"roadmap","options":{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
    }}},[(_vm.pickupItems.length)?_c('HeatMap',{attrs:{"data":_vm.pickupData,"options":{}}}):_vm._e()],1),_vm._v(" "),_c('h2',{staticClass:"mt-3"},[_vm._v("Delivery locations")]),_vm._v(" "),_c('GmapMap',{ref:"deliveryMapRef",staticStyle:{"width":"100%","height":"800px"},attrs:{"zoom":4,"center":{ lat: 41.850033, lng: -96.6500523 },"map-type-id":"roadmap","options":{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
    }}},[(_vm.deliveryItems.length)?_c('HeatMap',{attrs:{"data":_vm.deliveryData,"options":{}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }