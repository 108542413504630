<template>
  <th scope="col" class="sortable-header" @click="updateSortQuery">
    {{ label }}
    <span :class="sortArrowClasses">
      <span v-if="isSortedByThisField && sortOptions.direction === 'ASC'"><i class="fas fa-arrow-up"></i> </span>
      <span v-else><i class="fas fa-arrow-down"></i></span>
    </span>
  </th>
</template>

<script>
export default {
  name: 'TableSortableHeader',
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    field: {
      type: String,
      default: '',
    },
  },
  computed: {
    sortArrowClasses() {
      return { 'sort-arrow': true, 'sort-arrow--active': this.isSortedByThisField };
    },
    isSortedByThisField() {
      return this.sortOptions.field === this.field;
    },
    sortOptions() {
      const { query } = this.$route;
      const sortQuery = query.sort;
      if (!sortQuery) {
        return {};
      } else if (sortQuery.startsWith('-')) {
        const field = sortQuery.slice(1);
        return { field, direction: 'DESC' };
      } else {
        const field = sortQuery;
        return { field, direction: 'ASC' };
      }
    },
  },
  methods: {
    updateSortQuery() {
      let value = this.field;
      if (this.isSortedByThisField && this.sortOptions.direction === 'ASC') {
        value = `-${value}`;
      }
      const oldQuery = this.$route.query;
      const query = Object.assign({}, oldQuery, { sort: value, page: 1 });
      this.$router.push({ query });
    },
  },
};
</script>

<style lang="scss" scoped>
.sortable-header {
  cursor: pointer;
}

.sort-arrow {
  padding: 0;
  border: 0;
  margin: 0;
  background: none;
  opacity: 0.4;
}

.sort-arrow--active {
  opacity: 1;
  color: var(--blue);
}
</style>
