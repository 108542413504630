<template>
  <b-avatar variant="primary" class="test-avatar" :size="size" :text="isSystem ? 'SYS' : text" :src="getImage()"> </b-avatar>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    isUrl: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    isSystem: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    photo: {
      type: String,
      required: false,
      default: () => {
        return 'default.png';
      },
    },
    text: {
      type: String,
      required: false,
      default: () => {
        return '';
      },
    },
    size: {
      type: [String, Number],
      required: false,
      default: () => {
        return '';
      },
    },
  },
  methods: {
    getImage() {
      const { isSystem, photo, isUrl, publicUrl } = this;
      const prefix = `${publicUrl}storage/agents`;

      if (isUrl) return photo;
      if (isSystem) return `${prefix}/system.png`;
      if (photo) return `${prefix}/${photo}`;
    },
  },
};
</script>
