<template>
  <div class="empty-state"><i class="far fa-sad-cry mr-2"></i> {{ message }}</div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    message: {
      type: String,
      default: 'No items found',
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 32px;
  opacity: 0.8;
}
</style>
