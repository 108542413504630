export const balanceTerms = [
  { value: 1, label: 'immediately' },
  { value: 2, label: '2 business days (Quick Pay)' },
  { value: 3, label: '5 business days' },
  { value: 4, label: '10 business days' },
  { value: 5, label: '15 business days' },
  { value: 6, label: '30 business days' },
];

export default {
  data() {
    return {
      balanceTerms,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      return balanceTerms.find(pt => pt.value == value).label;
    },
  },
};
