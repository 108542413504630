import { render, staticRenderFns } from "./LeadlikeHistory.vue?vue&type=template&id=7c9b456e&scoped=true&"
import script from "./LeadlikeHistory.vue?vue&type=script&lang=js&"
export * from "./LeadlikeHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c9b456e",
  null
  
)

export default component.exports