<template>
  <div>
    <ValidationObserver ref="observer">
      <form @submit.prevent="submit()">
        <div>
          <b-row>
            <b-col>
              <div class="card mb-4">
                <h5 class="card-header">User information</h5>
                <div class="card-body">
                  <b-form-group label="Username"><b-input v-model="form.username" type="text" rules="required" /></b-form-group>
                  <b-form-group label="Email"><b-input v-model="form.email" type="text" rules="required" /></b-form-group>
                  <b-form-group label="Name"><b-input v-model="form.name" type="text" rules="required" /></b-form-group>
                  <b-form-group label="Country"><b-input v-model="form.country" type="text" /></b-form-group>

                  <b-form-group label="Password"><b-input v-model="form.password" type="text" /></b-form-group>
                  <b-form-group label="Password Confirmation"><b-input v-model="form.passwordConfirmation" type="text" /></b-form-group>

                  <b-form-group label="Daily Limit"><b-input v-model="form.dailyLimit" type="text" /></b-form-group>
                  <b-form-group label="Fresh Limit"><b-input v-model="form.freshLimit" type="text" /></b-form-group>
                  <b-form-group label="Phone"><b-input v-model="form.phone" type="text" /></b-form-group>

                  <b-form-group label="RC Username"><b-input v-model="form.rcUsername" type="text" /></b-form-group>
                  <b-form-group label="RC Password"><b-input v-model="form.rcPassword" type="text" /></b-form-group>
                  <b-form-group label="RC Extension"><b-input v-model="form.rcExtension" type="text" /></b-form-group>

                  
                  <multiselect
                    v-model="userRoles"
                    label="label"
                    track-by="value"
                    placeholder="Select roles"
                    :options="roles"
                    :allow-empty="true"
                    :multiple="true"
                    :searchable="false"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                  >
                    <span slot="noResult">No roles found</span>
                  </multiselect>

                </div>
              </div>
            </b-col>
            
          </b-row>
      
          <FormFieldsErrors :errors="errors" />
        </div>
        <button :disabled="loading" class="btn btn-primary" type="submit">Save</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import FormFieldsErrors from '@/components/shared/formFields/FormFieldsErrors';

const emptyForm = {
  id: null,
  username: '',
  name: '',
  email: '',
  password: '',
  country: null,
  passwordConfirmation: '',
  dailyLimit: null,
  freshLimit: null,
  phone: '',
  rcUsername: '',
  rcPassword: '',
  rcExtension: ''
};

export default {
  name: 'UserEdit',
  created() {
  },
  data() {
    return {
      form: this.deepMergeForm(emptyForm, this.user),
      errors: {},
      loading: false,
      roles: [],
      userRoles: [],
    };
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  components: { FormFieldsErrors },
  methods: {
    async fetchRoles() {
      const response = await ApiClient.roles.get({});
      if (response.status == 200) {
        this.roles = response.data.data.map(u => ({ value: u.id, label: u.name }));
      }
    },
    deepMergeForm(a, b) {
      if (!b) b = {};
      let start = Object.assign({}, a, b);
      for (let key of []) {
        if (!b[key]) start[key] = Object.assign({}, a[key]);
      }
      return start;
    },
    async submit() {
      if (this.loading) return;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loading = true;
        let response = null;
        if(!this.form.country) this.form.country = null;
        if(!this.form.dailyLimit) this.form.dailyLimit = null;
        if(!this.form.freshLimit) this.form.freshLimit = null;
        this.form.roles = this.userRoles.map(u => ({ id: u.value }));

        if (this.user) {
          response = await ApiClient.users.put({ id: this.user.id, body: this.form });
        } else {
          response = await ApiClient.users.post({ body: this.form });
        }
        this.loading = false;
        if (response.status === 200) {
          if (!this.user) this.form = Object.assign({}, emptyForm);
          this.errors = {};
          const user = response.data.data;
          this.$emit('save', user);
          this.$router.push({ name: 'UserDetails', params: { id: user.id } });
        } else if (response.status === 422) {
          this.errors = response.data.errors;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, { title: 'Error', variant: 'danger', solid: true });
    },

  },
  async mounted() {
    if(this.user){
      this.userRoles = this.user.roles.map(u => ({ value: u.id, label: u.name }));
    }
    this.fetchRoles();
    //console.log(this.form);
  },
};
</script>
