import LayoutCentered from '@/layout/LayoutCentered';

export default [
  {
    // Layout 2
    path: '/sources',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'admin',
    },
    children: [
      {
        name: 'SourcesList',
        path: '',
        component: () => import('@/components/pages/sources/Sources'),
      },
    ],
  },
];
