<template>
  <div style="position:relative;">
    <div class="d-flex align-items-center">
      <input class="form-control" id="carrierSearch" v-model="searchTerm" placeholder="Search for carrier..." :disabled="carrier ? 'disabled' : undefined" autocomplete="off"  />
      <button class="btn btn-outline-secondary ml-3" v-if="selectedCarrierName" @click="reset">Edit</button>
      <LoadingIndicator v-if="searchStatus === 'searching'" centered />
    </div>
    <div v-if="searchStatus === 'done'" style="position:absolute;background:#fff;">
      <div v-if="carriers.length && !carrier" class="list-group">
        <div class="list-group-item list-group-item--clickable" v-for="carrier in carriers" :key="carrier.id">
          <div @click="closeCarriers(carrier)" :class="className(carrier)">{{ carrierOption(carrier) }}</div>
        </div>
      </div>
      <div v-else>
        <span v-if="!carriers.length  && !carrier"> No carriers match "{{ searchTerm }}"</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from './LoadingIndicator.vue';
import { debounce } from 'debounce';

const searchCarriers = debounce(async (searchTerm, callback) => {
  callback(await ApiClient.carriers.get({ params: { search: searchTerm } }));
}, 200);

export default {
  name: 'CarrierSelector',
  data() {
    return { carriers: [], searchTerm: '', selectedCarrier: null, searchStatus: 'initial', carrier: null };
  },
  props: {
    suggestions: {
      type: Number,
      default: 5,
    },
  },
  components: {
    LoadingIndicator,
  },
  computed: {
    selectedCarrierName() {
      return this.carrier?.contact?.company;
    },
  },
  methods: {
    reset() {
      this.$emit('input', null);
      this.searchTerm = '';
      this.searchStatus = 'initial';
      this.carrier = null;
    },
    closeCarriers(carrier) {
      if(!carrier.isActive || !carrier.canDispatch) return false;
      this.carrier = carrier;
      this.searchTerm = this.selectedCarrierName;
      this.carriers = [];
      this.$emit('input', carrier);
    },
    carrierOption(carrier) {
      return carrier?.contact.company + ' - ' + carrier?.location.city + ', ' + carrier?.location.state;
    },
    className(carrier) {
      return (!carrier.isActive || !carrier.canDispatch) ? 'text-muted' : '';
    },
  },
  watch: {
    async searchTerm(searchTerm) {
      const minimalSearchTermLength = 2;
      if (searchTerm.length > minimalSearchTermLength) {
        this.searchStatus = 'searching';
        searchCarriers(searchTerm, carriersResponse => {
          this.carriers = carriersResponse.data.data.slice(0, this.suggestions);
          this.searchStatus = 'done';
        });
      } else {
        this.searchStatus = 'initial';
        this.carriers = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item--clickable {
  cursor: pointer;
}

.list-group-item--clickable:hover {
  background-color: var(--light);
}

.selected-value {
  padding-right: 16px;
}

.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
}
</style>
