<template>
  <multiselect
    :value="value"
    :options="options"
    :label="label"
    :track-by="trackBy"
    :class="classList"
    :loading="isLoading"
    :options-limit="fetchLimit"
    :max-height="maxHeight"
    :placeholder="placeholder"
    :show-labels="showLabels"
    :allow-empty="allowEmpty"
    :internal-search="false"
    :show-no-result="false"
    searchable
    hide-selected
    @search-change="handleChangeQuery"
    @input="onChangeInput"
    class="test-multiselect"
  >
  </multiselect>
</template>

<script>
import _ from 'lodash';
import api from '@/api';

export default {
  name: 'AsyncMultiselect',
  props: {
    value: {
      validator: prop => typeof prop === 'object' || prop == null,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    fetchingOptions: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: () => {
        return 'text';
      },
    },
    trackBy: {
      type: String,
      required: false,
      default: () => {
        return 'value';
      },
    },
    classList: {
      type: String,
      required: false,
      default: () => {
        return '';
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'Type for getting list';
      },
    },
    fetchLimit: {
      type: Number,
      required: false,
      default: () => {
        return 50;
      },
    },
    maxHeight: {
      type: Number,
      required: false,
      default: () => {
        return 300;
      },
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    onUpdateOptions: {
      type: Function,
      required: true,
    },
    onChangeInput: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleChangeQuery: _.debounce(function (query) {
      if (!query) return;

      this.handleFetch({ query });
    }, 500),
    async handleFetch({ query }) {
      const { fetchLimit } = this;
      const { queryKey, endpoint } = this.fetchingOptions;
      const params = { [queryKey]: query, per_page: fetchLimit };

      try {
        this.isLoading = true;
        const res = await api[endpoint].get(null, params);
        this.onUpdateOptions({ data: res.data.data });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
