const transportTypes = [
  { value: '1', label: 'Open' },
  { value: '2', label: 'Enclosed' },
];

export default {
  data() {
    return {
      transportTypes,
    };
  },
  methods: {
    transportTypeLabel(value) {
      if (!value) {
        return '-';
      }
      return transportTypes.find(dt => dt.value == value)?.label || '';
    },
  },
};
