/**
 * Converts all atrtibutes of an object from camelCase to snake_case
 * @param {*} object
 * @returns
 */
export function objectToSnakeCase(object) {
  if (!object || typeof object !== 'object') {
    return object;
  }
  if (Array.isArray(object)) {
    return object.map(o => objectToSnakeCase(o));
  }
  return Object.keys(object).reduce((result, camelKey) => {
    const snakeKey = stringToSnakeCase(camelKey);
    result[snakeKey] = objectToSnakeCase(object[camelKey]);
    return result;
  }, {});
}

/**
 * Converts all atrtibutes of an object from snake_case to camelCase
 * @param {*} object
 * @returns
 */
export function objectToCamelCase(object) {
  if (!object || typeof object !== 'object') {
    return object;
  }
  if (Array.isArray(object)) {
    return object.map(o => objectToCamelCase(o));
  }
  return Object.keys(object).reduce((result, snakeKey) => {
    const camelKey = stringToCamelCase(snakeKey);
    result[camelKey] = objectToCamelCase(object[snakeKey]);
    return result;
  }, {});
}

export function stringToSnakeCase(string) {
  if(!string) return string;
  return string.split('').reduce((result, current) => {
    if (isUpperCase(current)) {
      result += `_`;
      result += current.toLowerCase();
    } else {
      result += current;
    }
    return result;
  }, '');
}

export function stringToCamelCase(string) {
  return string
    .split('_')
    .map((word, i) => (i === 0 ? word : capitalizeFirstLetter(word)))
    .join('');
}

function isUpperCase(string) {
  return string != string.toLowerCase() && string == string.toUpperCase();
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
