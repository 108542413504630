<template>
  <div class="mb-3">
    <div class="label">
      {{ title }}:
      <span class="font-weight-bold"><slot /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeadsCardPrice',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.8rem;
}
</style>
