import { ApiClient } from '@/api/ApiClient';

const state = {
  mentionableUsers: [],
  loadingMentionableUsers: false,
};

const getters = {
};

const actions = {
  async fetchMentionableUsers({ commit }) {
    commit('setLoadingMentionableUsers', true);
    const response = await ApiClient.users.dropdown({});
    commit('setMentionableUsers', response.data.data.map(u => ({ value: u.name + '; ', label: u.name})));
    commit('setLoadingMentionableUsers', false);
  },
};

const mutations = {
  setMentionableUsers(state, mentionableUsers) {
    state.mentionableUsers = mentionableUsers;
  },
  setLoadingMentionableUsers(state, value) {
    state.loadingMentionableUsers = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
