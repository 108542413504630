import LayoutCentered from '@/layout/LayoutCentered';
import Competition from '@/components/pages/competition/Competition';

export default [
  {
    path: '/competition',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'Competition',
        component: Competition,
      },
    ],
  },
];
