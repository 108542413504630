export const balanceMethods = [
  { value: 1, label: 'COD to Carrier' },
  { value: 2, label: 'COP to Carrier (On Pickup)' },
  { value: 3, label: 'Shipper Invoice' },
  { value: 4, label: 'QuickPay' },
];

export default {
  data() {
    return {
      balanceMethods,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      if (!value) return '-';
      return balanceMethods.find(pt => pt.value == value).label;
    },
  },
};
