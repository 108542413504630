export const balanceCodcopMethods = [
  { value: 1, label: 'Cash/Certified Funds' },
  { value: 2, label: 'Check' },
];

export default {
  data() {
    return {
      balanceCodcopMethods,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      return balanceCodcopMethods.find(pt => pt.value == value).label;
    },
  },
};
