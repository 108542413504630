export const deletedKeys = ['id', 'lead_id', 'updated_at', 'created_at'];
export const types = {
  date: [{ title: 'Estimated', id: 1 }],
  transport: [
    { title: 'Open', id: 1 },
    { title: 'Enclosed', id: 2 },
    { title: 'Driveaway', id: 3 },
  ],
};

//templates
export const contactList = ['pickup_contact', 'delivery_contact', 'dispatch_contact', 'customer', 'carrier'];
export const locationList = ['customer', 'pickup_location', 'delivery_location'];
export const contactKeys = ['company', 'first_name', 'last_name', 'contact', 'contact2', 'phone', 'phone2', 'cell_phone', 'fax', 'email'];
export const locationKeys = ['address', 'address2', 'city', 'state', 'zip'];

// Initial Forms
export const initialContact = {
  first_name: null,
  last_name: null,
  email: null,
  company: null,
};
export const initialLocation = {
  address: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
};
export const initialVehicle = {
  vehicle_type: null, //convert obj to id as vehicle_type_id on sending request
  vin: null,
  year: null,
  make: null,
  model: null,
  color: null,
  lot_number: null,
  plate_number: null,
  is_inoperable: false,
  price: null,
  deposit: null,
};
export const newVehicle = {
  vehicle_type: null,
  vehicle_make: null,
  year: null,
  type: null,
  body: null,
  model: null,
  original_model: null,
};
// this is mixed that includes states, cities, counties and zips forms.
export const initialLocations = {
  zip: null,
  name: null,
  state_id: null,
  city_id: null,
  county_id: null,
  surcharge_pick: null,
  surcharge_drop: null,
  latitude: null,
  longitude: null,
};
export const initialLeadPayment = {
  payment_type: null, //convert obj to id as payment_type_id on sending request
  payment_direction: null, //convert obj to id as payment_direction_id on sending request
  amount: null,
  date_received: null,
  notes: null,
};
