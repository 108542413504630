<template>
  <div>
    <div class="grid--three-column">
      <ValidatedInput
        mask="price"
        rules="required|min:2"
        label="Amount"
        id="amount"
        :value="formFields.amount"
        :errors="errorResolver('amount')"
        @input="newValue => handleInputChange({ field: 'amount', newValue })"
      />
      <ValidatedInput
        label="Surcharge"
        id="surcharge"
        :value="formFields.surcharge"
        :errors="errorResolver('surcharge')"
        @input="newValue => handleInputChange({ field: 'surcharge', newValue })"
      />
      <ValidatedInput
        type="date"
        label="Date received"
        id="dateReceived"
        :value="formFields.dateReceived"
        :errors="errorResolver('dateReceived')"
        @input="newValue => handleInputChange({ field: 'dateReceived', newValue })"
      />
    </div>
    <div class="grid--two-column">
      <ValidatedSelect
        label="Payment type"
        rules="required"
        id="paymentTypes"
        :options="paymentTypes"
        :value="formFields.paymentTypeId"
        :errors="errorResolver('paymentTypeId')"
        @input="newValue => handleInputChange({ field: 'paymentTypeId', newValue })"
      />
      <ValidatedSelect
        label="Payment directions"
        rules="required"
        id="paymentDirections"
        :options="paymentDirections"
        :value="formFields.paymentDirectionId"
        :errors="errorResolver('paymentDirectionId')"
        @input="newValue => handleInputChange({ field: 'paymentDirectionId', newValue })"
      />
    </div>
    <ValidatedInput
      type="textarea"
      label="Notes"
      id="notes"
      :value="formFields.notes"
      :errors="errorResolver('notes')"
      @input="newValue => handleInputChange({ field: 'notes', newValue })"
    />
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import paymentDirectionsMixin from '@/mixins/paymentDirections';
import paymentTypesMixin from '@/mixins/paymentTypes';

export default {
  name: 'FormFieldsPayment',
  components: {
    ValidatedInput,
    ValidatedSelect,
  },
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
  },
  mixins: [paymentDirectionsMixin, paymentTypesMixin],
  methods: {
    handleInputChange({ field, newValue }) {
      const newFormFields = Object.assign({}, this.formFields);
      newFormFields[field] = newValue;
      this.$emit('input', newFormFields);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
</style>
