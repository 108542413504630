import { objectToCamelCase } from './caseHelpers';

/**
 * Catches the error and if it looks like Axios API error with response
 * return an object instead so that it can be gracefully handled by front-end
 */
export function handleApiError(error) {
  const { response } = error;
  if (response) {
    return {
      status: response.status,
      data: objectToCamelCase(response.data),
    };
  } else {
    throw error;
  }
}
