<template>
  <div class="bar">
    <label>
      Per page
      <b-form-select size="sm" :value="perPage" @change="handlePerPageChange" :options="[20, 50, 100, 250, 500]" />
    </label>

    <div class="page-switcher">
      <b-btn size="sm" :disabled="currentPage <= 1 || isDisabled" @click="onPageChange(currentPage - 1)">
        <span class="fas fa-angle-left"></span>
      </b-btn>

      <div class="page-indicator">
        <label
          >Page
          <b-form-input size="sm" class="page-input" :disabled="isDisabled" v-model="pageInput" @keyup.enter="handleInputChange" @blur="handleInputChange" />
        </label>
      </div>

      <b-btn size="sm" :disabled="!hasNextPage || isDisabled" @click="onPageChange(currentPage + 1)">
        <span class="fas fa-angle-right"></span>
      </b-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageInput: this.currentPage,
    };
  },
  computed: {
    ...mapState({
      perPage: state => state.app.perPage,
    }),
  },
  methods: {
    handleInputChange() {
      if (this.currentPage !== this.pageInput) {
        this.onPageChange(this.pageInput);
      }
    },
    handlePerPageChange(newValue) {
      this.$store.dispatch('app/setPerPage', newValue);
    },
  },
  watch: {
    currentPage(newValue) {
      this.pageInput = newValue;
    },
  },
};
</script>

<style scoped>
label {
  display: flex;
  align-items: center;
  margin: 0;
  border-radius: 0.125rem;
  padding: 0 8px;
  cursor: pointer;
  white-space: nowrap;
  background-color: white;
}

input,
select {
  text-align: center;
  margin-left: 4px;
}

.bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-indicator {
  background-color: white;
  padding: 0 8px;
}

.page-input {
  width: 32px;
}
</style>
