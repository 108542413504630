import api from '@/api';

const state = {
  items: [],
  query: {
    page: 1,
    per_page: 10,
  },
  configs: { from: 0, to: 0, isNextActive: false, isPrevActive: false, lastUpdatedAt: null },
};

const getters = {
  items: state => {
    return state.items;
  },
  query: state => {
    return state.query;
  },
  configs: state => {
    return state.configs;
  },
};

const actions = {
  async fetchData({ state, commit }, endpoint) {
    const res = await api[endpoint].get(null, state.query);
    const { data, next_page_url, prev_page_url, from, to } = res.data;

    commit('setItems', data);
    commit('setConfigs', { from, to, next_page_url, prev_page_url });
  },
  getPerPage({ commit }) {
    const per_page = localStorage.getItem('per_page');

    if (per_page) commit('setQuery', { key: 'per_page', value: per_page });
  },
  updateQuery({ commit }, { key, value }) {
    commit('setQuery', { key, value });

    if (key === 'per_page') localStorage.setItem(key, value);
  },
};

const mutations = {
  resetPagination(state) {
    state.items = [];
    state.query = { page: 1, per_page: state.query.per_page };
    state.configs = { from: 0, to: 0, isNextActive: false, isPrevActive: false, lastUpdatedAt: new Date() };
  },
  setQuery(state, { key, value }) {
    state.query = { ...state.query, [key]: value };
  },
  resetQuery(state, values) {
    state.query = { page: 1, per_page: state.query.per_page, ...values };
  },
  setItems(state, data) {
    state.items = data;
  },
  setConfigs(state, { from, to, next_page_url, prev_page_url }) {
    state.configs = {
      from,
      to,
      isNextActive: Boolean(next_page_url),
      isPrevActive: Boolean(prev_page_url),
      lastUpdatedAt: new Date(),
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
