import LayoutCentered from '@/layout/LayoutCentered';
import CustomNotification from '@/components/pages/notifications/CustomNotification';

export default [
  {
    // Layout 2
    path: '/notifications',
    component: LayoutCentered,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        component: () => import('@/components/pages/notifications/Notifications'),
      },
      {
        component: CustomNotification,
        name: 'CustomNotification',
        path: 'custom',
      },
    ],
  },
];
