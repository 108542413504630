import { ApiClient } from '@/api/ApiClient';
import Vue from 'vue';

const state = {
  items: [],
  isLoading: false,
  hasNextPage: true,
  lastUpdatedAt: null,
  randomLinkBase: 'https://www.nexusautotransport.com/quote/',
  googleSearchLinkBase: 'https://www.google.com/search?tbm=isch&q=',
  leads: { lead: 1, quote: 2, order: 3 },
  leadStatuses: {
    leads: 1,
    quotes: 2,
    orders: 3,
    hold: 4,
    canceled: 5,
    'posted-cd': 6,
    'not-signed': 7,
    dispatched: 8,
    issues: 9,
    completed: 10,
  },
  userFilter: null,
  searchStats: null,
  emailTemplates: [],
  smsTemplates: [],
  leadNotes: {},
};

const getters = {
  getSmsTemplates: state => leadTypeId => {
    if (!leadTypeId) return state.smsTemplates;
    return state.smsTemplates.filter(x => x.leadTypeId == leadTypeId || x.leadTypeId === null);
  },
  getEmailTemplates: state => leadTypeId => {
    if (!leadTypeId) return state.emailTemplates;
    return state.emailTemplates.filter(x => x.leadTypeId == leadTypeId || x.leadTypeId === null);
  },
};

const actions = {
  async fetch({ commit }, options = {}) {
    commit('setLoading', true);
    let response;
    if (options.search || options.leadList) {
      response = await ApiClient.leads.get({ params: options });
    } else {
      response = await ApiClient.liveLeads({ params: options });
    }
    commit('setLeadsItems', response.data);
    commit('setLoading', false);
  },
  async update({ commit }, { leadId, updatedAttributes }) {
    const response = await ApiClient.leads.put({ id: leadId, body: updatedAttributes });
    if (response.status === 200) {
      commit('updateLead', { leadId, attributes: response.data.data });
    }
    return response;
  },
  async claim({ commit }, { leadId }) {
    const response = await ApiClient.leads.claim({ id: leadId });
    const lead = response.data.data;
    commit('updateLead', { leadId: lead.id, attributes: lead });
  },
  async reclaim({ commit }, { leadId }) {
    try {
      await ApiClient.leads.reclaim({ id: leadId });
      commit('updateLead', { leadId, attributes: { leadReclaimsCount: 1 } });
    } catch (e) {
      console.error(e);
    }
  },
  async reflow({ commit }, { leadId }) {
    const response = await ApiClient.leads.reflow({ id: leadId });
    const lead = response.data.data;
    commit('updateLead', { leadId: lead.id, attributes: lead });
  },
  async updatePriority({ commit }, { leadId, priority }) {
    commit('updateLead', { leadId, attributes: { priority } });
    const response = await ApiClient.leads.put({ id: leadId, body: { priority } });
    const lead = response.data.data;
    commit('updateLead', { leadId: lead.id, attributes: lead });
  },

  async fetchTemplates({ commit }) {
    let responses = await Promise.all([ApiClient.emailTemplates.get({}), ApiClient.smsTemplates.get({})]);
    if (responses[0].data.data) {
      commit('setEmailTemplates', responses[0].data.data);
    }
    if (responses[1].data.data) {
      commit('setSmsTemplates', responses[1].data.data);
    }
  },
};

const mutations = {
  setLeadsItems(state, leads) {
    const { data, nextPageUrl, search } = leads;
    state.items = data;
    state.hasNextPage = !!nextPageUrl;
    state.searchStats = search;
    state.lastUpdatedAt = new Date();
  },
  setEmailTemplates(state, templates) {
    state.emailTemplates = templates;
  },
  setSmsTemplates(state, templates) {
    state.smsTemplates = templates;
  },
  setLoading(state, value) {
    state.isLoading = value;
  },
  updateLead(state, { leadId, attributes }) {
    const matchingLead = state.items.find(lead => lead.id === leadId);
    if (matchingLead) Object.assign(matchingLead, attributes);
  },
  createLead(state, lead) {
    const matchingLead = state.items.find(item => item.id === lead.id);
    if (matchingLead || state.searchStats) return; // don't add to list if there is search in progress
    state.items.unshift(lead);
  },
  clearLeadsItems(state) {
    state.items = [];
    state.configs = {};
    state.searchStats = null;
    state.lastUpdatedAt = new Date();
  },
  setUserFilter(state, userFilter) {
    state.userFilter = userFilter;
  },

  unsetLeadNotes(state, { leadId }) {
    Vue.delete(state.leadNotes, leadId);
    //delete state.leadNotes[leadId];
  },
  setLeadNotes(state, { leadId, notes }) {
    Vue.set(state.leadNotes, leadId, notes);
  },
  createLeadNote(state, note) {
    // notes not loaded, not interested in updating list
    if(!state.leadNotes[note.leadId]) return;
    /*
    if(!state.leadNotes[note.leadId]){
      Vue.set(state.leadNotes, note.leadId, []);
    }
    */
    const matchingNote = state.leadNotes[note.leadId].find(x => x.id === note.id);
    if (matchingNote) Object.assign(matchingNote, note);
    else state.leadNotes[note.leadId].unshift(note);
    
  },
  updateLeadNote(state, note) {
    if(!state.leadNotes[note.leadId]) return;
    const matchingNote = state.leadNotes[note.leadId].find(x => x.id === note.id);
    if (matchingNote) Object.assign(matchingNote, note);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
