import { ApiClient } from '@/api/ApiClient';

const state = {
  items: [],
};

const getters = {
  unread: state => {
    return state.items.filter(item => !item.isRead);
  },
};

const actions = {
  async fetch({ commit }) {
    const response = await ApiClient.notifications.get();
    commit('setNotifications', response.data.data);
  },
  async update({ commit }, { id, updatedAttributes }) {
    const response = await ApiClient.notifications.put({ id, body: updatedAttributes });
    if (response.status === 200) {
      commit('updateNotification', { id, attributes: response.data.data });
    }
    return response;
  },
  async markRead({ commit }, params) {
    const response = await ApiClient.notifications.markRead({ body: params });
    if (response.status === 200) {
      for (let id of params.rows) {
        commit('updateNotification', { id, attributes: { isRead: params.isRead } });
      }
    }
    return response;
  },
};

const mutations = {
  setNotifications(state, notifications) {
    state.items = notifications;
  },
  updateNotification(state, { id, attributes }) {
    const matchingNotification = state.items.find(item => item.id === id);
    if (matchingNotification) Object.assign(matchingNotification, attributes);
  },
  createNotification(state, notification) {
    if (state.items.find(item => item.id === notification.id)) return;
    state.items.unshift(notification);
  },
  clearNotifications(state) {
    state.items = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
