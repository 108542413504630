<template>
  <div>
    <div v-for="(error, index) in errors" :key="index">
      <div v-if="!value[error.type] && value.$dirty" class="form-error-message">
        {{ getValidationErrorMessage(error.type, label, getParamValue(error)) }}
      </div>
    </div>
  </div>
</template>

<script>
import errorMessages from '@/mixins/errorMessages';

export default {
  name: 'FormError',
  mixins: [errorMessages],
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: () => 'Field',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getParamValue(error) {
      const { value } = this;

      if (!error.param) return undefined;

      return Number.isInteger(error.param) ? error.param : value.$params[error.type][error.param];
    },
  },
};
</script>
