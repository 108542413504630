<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="onClose">
      <div class="modal-dialog modal-md modal-dialog-centered" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <span>Dispatching to carrier {{ offer.carrier.contact.company }}</span>
            <button @click="onClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="observer">
              <form @submit.prevent="submit()">
                <CarrierSelector :balance-fields="order" :offer="offer" />
                <ValidatedSelect
                  rules="required"
                  label="Dispatch Method"
                  id="dispatchMethod"
                  v-model="dispatchMethodId"
                  :options="dispatchMethods"
                />
                <button class="btn btn-primary" type="submit">Dispatch</button>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import CarrierSelector from './CarrierSelector.vue';
import { ApiClient } from '@/api/ApiClient';
import dispatchMethodsMixin from '@/mixins/dispatchMethods';
import ValidatedSelect from '@/components/shared/ValidatedSelect';

export default {
  components: {
    CarrierSelector,
    ValidatedSelect
  },
  name: 'DispatchModal',
  data() {
    return {
      dispatchMethodOptions: [
        { value: null, text: '-' },
      ],
      dispatchMethodId: this.order.dispatchMethodId || 1,
    };
  },
  props: {
    onClose: {
      type: Function,
      default: () => { },
    },
    order: {
      type: Object,
      default: () => { },
    },
    offer: {
      type: Object,
      default: () => { },
    }
  },
  mixins: [dispatchMethodsMixin],
  methods: {
    async submit() {
      this.saving = true;
      const requestLeadParams = this.order;
      const response = await ApiClient.leads.put({ id: this.order.id, body: requestLeadParams });
      if (response.status === 200) {
        this.dispatchOrder();
      }
      this.saving = false;
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      });
    },
    async dispatchOrder() {
      if (this.requestInProgress) return;
      this.requestInProgress = true;
      const response = await ApiClient.leads.dispatch.post({
        leadId: this.order.id,
        body: { 
          carrierId: this.offer.carrierId,
          dispatchMethodId: this.dispatchMethodId,
        },
      });
      this.requestInProgress = false;

      if (response.status === 200) {
        this.errors = {};
        this.$emit('refresh');
        this.onClose();
      } else if (response.status === 400) {
        this.errors = { generalError: response.data.message };
      } else if (response.status === 422) {
        this.errors = response.data.errors;
      }
    },
  },
  created() {
    this.dispatchMethodOptions = this.dispatchMethodOptions.concat(this.dispatchMethods.map(x => {x.text = x.label; return x}));
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}

.modal-body {
  background-color: #fafafa;
}
</style>
