<template>
  <div :class="layout">
    <div class="contact-fields-group">
      <ValidatedInput
        :rules="isReq"
        :label="label + ' First name'"
        text-transform="capitalize"
        id="firstName"
        :value="formFields.firstName"
        @input="newValue => handleInputChange({ field: 'firstName', newValue })"
        :errors="errorResolver('firstName')"
      />
      <ValidatedInput
        label="Last name"
        id="lastName"
        text-transform="capitalize"
        :value="formFields.lastName"
        @input="newValue => handleInputChange({ field: 'lastName', newValue })"
        :errors="errorResolver('lastName')"
      />
    </div>
    <div class="contact-fields-group">
      <ValidatedInput
        label="Company"
        id="company"
        text-transform="capitalize"
        :value="formFields.company"
        @input="newValue => handleInputChange({ field: 'company', newValue })"
        :errors="errorResolver('company')"
      />
    </div>
    <div class="contact-fields-group">
      <ValidatedInput
        label="Phone"
        id="phone"
        mask="phone"
        :value="formFields.phone"
        @input="newValue => handleInputChange({ field: 'phone', newValue })"
        :errors="errorResolver('phone')"
      />
      <ValidatedInput
        label="Email"
        id="email"
        mask="email"
        :value="formFields.email"
        @input="newValue => handleInputChange({ field: 'email', newValue })"
        :errors="errorResolver('email')"
      />
    </div>
    <div v-if="formFields.phone2 == '' || formFields.phone2 === undefined" :class="secondaryButtonClass">
      <button type="button" class="btn btn-secondary btn-sm" @click="showPhone(label.toLowerCase(), 'secondary')">Add Phone #</button>
    </div>
    <div class="contact-fields-group">
      <div :class="secondaryInputClass">
        <ValidatedInput
          label="Secondary phone"
          id="phone2"
          mask="phone"
          :value="formFields.phone2"
          @input="newValue => handleInputChange({ field: 'phone2', newValue })"
          :errors="errorResolver('phone2')"
        />
      </div>
      <div v-if="formFields.cellPhone == '' || formFields.cellPhone === undefined" :class="cellButtonClass">
        <button type="button" class="btn btn-secondary btn-sm" @click="showPhone(label.toLowerCase(), 'cell')">Add Phone #</button>
      </div>
      <div :class="cellInputClass">
        <ValidatedInput
          label="Cell phone"
          id="cellPhone"
          mask="phone"
          :value="formFields.cellPhone"
          @input="newValue => handleInputChange({ field: 'cellPhone', newValue })"
          :errors="errorResolver('cellPhone')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';

export default {
  components: {
    ValidatedInput,
  },
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
    layout: {
      type: String,
      default: 'horizontal',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    isReq() {
      if (this.formFields.company) {
        return '';
      }
      return this.label == 'Customer' ? 'required' : '';
    },
    secondaryButtonClass() {
      return 'secondary-button ' + this.label.toLowerCase();
    },
    secondaryInputClass() {
      if (this.formFields.phone2 != '' && this.formFields.phone2 !== undefined) {
        return 'secondary ' + this.label.toLowerCase();
      }
      return 'hidden secondary ' + this.label.toLowerCase();
    },
    cellButtonClass() {
      if (this.formFields.phone2 != '' && this.formFields.phone2 !== undefined) {
        return 'cell-button ' + this.label.toLowerCase();
      }
      return 'hidden cell-button ' + this.label.toLowerCase();
    },
    cellInputClass() {
      if (this.formFields.cellPhone != '' && this.formFields.cellPhone !== undefined) {
        return 'cell ' + this.label.toLowerCase();
      }
      return 'hidden cell ' + this.label.toLowerCase();
    },
  },
  methods: {
    handleInputChange({ field, newValue }) {
      const newFormFields = Object.assign({}, this.formFields);
      newFormFields[field] = newValue;
      this.$emit('input', newFormFields, {field, newValue});
    },
    showPhone(group, phone) {
      var btn = document.getElementsByClassName(phone + '-button ' + group);
      var inpt = document.getElementsByClassName(phone + ' ' + group);
      btn[0].classList.add('hidden');
      inpt[0].classList.remove('hidden');
      if (phone == 'secondary') {
        var cellBtn = document.getElementsByClassName('cell-button ' + group);
        cellBtn[0].classList.remove('hidden');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
}

.vertical .contact-fields-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}

.hidden {
  display: none;
}
</style>
