<template>
  <div class="alert alert-danger" v-if="errorsFlatList.length">
    <ul class="mb-0">
      <li v-for="error in errorsFlatList" :key="error">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    errorsFlatList() {
      if (this.errors) {
        return Object.values(this.errors);
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
