<template>
  <span :title="moveDate | moment('MM/DD/YYYY')">
    {{ moveDate | moment('from', 'now') }}
  </span>
</template>

<script>
export default {
  name: 'LeadsCardMoveDate',
  props: {
    moveDate: {
      type: String,
      default: '2021-04-29',
    },
  },
};
</script>

<style lang="scss" scoped></style>
