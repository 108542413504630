<template>
  <multiselect
    v-model="selected"
    label="label"
    track-by="value"
    placeholder="Type to search user"
    :options="users"
    :allow-empty="true"
    :multiple="multiple"
    :searchable="true"
    :show-labels="false"
    :loading="loading"
    :internal-search="true"
    :clear-on-select="false"
    :close-on-select="true"
    @input="handleInput"
  >
    <span slot="noResult">No users found</span>
  </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>

<script>
import { ApiClient } from '@/api/ApiClient';
import Multiselect from 'vue-multiselect';

export default {
  name: 'UserDropdown',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    showEmpty: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Number, Array, String],
      default: null,
    },
  },
  components: { Multiselect },
  data() {
    return {
      selected: null,
      users: [],
      loading: true,
    };
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      const response = await ApiClient.users.dropdown({});

      if (response.status == 200) {
        let list = [{ value: this.$store.state.auth.user.id, label: this.$store.state.auth.user.name }];
        if (this.showEmpty) list.push({ value: null, label: 'All users' });
        this.users = list.concat(response.data.data.filter(x => x.id != this.$store.state.auth.user.id).map(u => ({ value: u.id, label: u.name })));
        if (this.multiple) {
          this.selected = this.users.filter(x => this.value.includes(x.value));
        } else {
          this.selected = this.users.find(x => this.value == x.value);
        }
        //console.log(this.value, this.selected);
        this.loading = false;
      }
    },
    handleInput() {
      this.$emit('select', this.multiple ? this.selected?.map(x => x.value) : this.selected?.value);
    },
  },
  async mounted() {
    await this.fetchUsers();
  },
};
</script>
