<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="onClose">
      <div class="modal-dialog modal-md modal-dialog-centered" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="onClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body">
            <div v-for="vehicle in vehicles" :key="vehicle.id">
              <LeadsCardVehicleMake :vehicle="vehicle" />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import LeadsCardVehicleMake from '@/components/pages/leads/LeadsCardVehicleMake';

export default {
  components: {
    LeadsCardVehicleMake,
  },
  name: 'VehicleListModal',
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    vehicles: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}

.modal-body {
  background-color: #fafafa;
}
</style>
