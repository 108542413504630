import { mapState } from 'vuex';
import PageLinks from '@/components/shared/pagination/PageLinks';

/**
 * This is a pagination mixin.
 * It is named pageLinks to avoid clash with the old pagination.
 * Prefer this one.
 *
 * HOW TO USE?
 * 1. Make sure there is a method named fetch() that load the paginated items
 * 2. Add page links like this:
 *
 * <PageLinks
 *    :current-page="currentPage"
 *    :has-next-page="items.length === perPage"
 *    :on-page-change="updatePageUrlParam"
 *    :is-disabled="loading"
 * />
 *
 * 3. Add `this.perPage` and `this.currentPage` to your API query
 */
export default {
  components: {
    PageLinks,
  },
  computed: {
    ...mapState({
      perPage: state => state.app.perPage,
    }),
    currentPage() {
      const { page = 1 } = this.$route.query;
      return parseInt(page);
    },
  },
  methods: {
    updatePageUrlParam(page) {
      const newQuery = Object.assign({}, this.$route.query, { page: page });
      this.$router.push({ query: newQuery });
    },
  },
  watch: {
    perPage() {
      if (this.$route.query.page != 1) {
        const newQuery = Object.assign({}, this.$route.query, { page: 1 });
        this.$router.push({ query: newQuery });
      } else {
        this.fetch();
      }
    },
  },
};
