import { handleCatchWindowClose } from '@/helpers/methods';
import api from '@/api';

const state = {
  sales: {
    mode: false,
    interval: null,
    countdown: 180,
  },
  notifications: [],
  perPage: 50,
};

const getters = {
  salesTimer: state => {
    const { countdown } = state.sales;
    const mins = Math.floor(countdown / 60);
    const secs = countdown % 60;
    const prefixSecs = secs < 10 ? '0' : '';
    return `${mins}:${prefixSecs}${secs}`;
  },
  unreadNotifications: state => {
    return state.notifications.filter(item => !item.is_read);
  },
  isAnyNotificationsChecked: state => {
    return state.notifications.some(item => item.isChecked);
  },
};

const actions = {
  async getNotifications({ commit }) {
    const { data } = await api.notifications.get();
    const notifications = data.data.map(item => ({ ...item, isChecked: false }));
    commit('setNotifications', notifications);
  },
  async markAsReadNotifications({ state, dispatch }) {
    const ids = state.notifications.filter(n => n.isChecked && !n.is_read).map(n => n.id);
    if (ids.length > 0) {
      await api.notifications.markRead.post(null, { ids });
      dispatch('getNotifications');
    }
  },
  startSales({ state, commit, dispatch }) {
    window.addEventListener('beforeunload', handleCatchWindowClose);
    commit('setSales', {
      interval: setInterval(() => {
        if (state.sales.countdown > 0) {
          commit('setSales', { countdown: state.sales.countdown - 1 });
        } else {
          dispatch('resetSales');
        }
      }, 1 * 1000),
    });
  },
  resetSales({ state, commit }) {
    clearInterval(state.sales.interval);
    window.removeEventListener('beforeunload', handleCatchWindowClose);
    commit('setSales', { mode: false, interval: null, countdown: 180 });
  },
  setPerPage({ state, commit }, value) {
    if (state.perPage !== value) {
      localStorage.setItem('per_page', value);
      commit('setPerPage', value);
    }
  },
  loadPerPage({ state, commit }) {
    const storedValue = localStorage.getItem('per_page', value);
    const value = storedValue ? parseInt(storedValue) : 20;
    if (state.perPage !== value) {
      commit('setPerPage', value);
    }
  },
};

const mutations = {
  setSales(state, sales) {
    state.sales = { ...state.sales, ...sales };
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  changeNotification(state, { id, key, value }) {
    state.notifications = state.notifications.map(item => (item.id === id ? { ...item, [key]: value } : item));
  },
  setPerPage(state, value) {
    state.perPage = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
