<template>
  <div>
    <div class="card mb-4">
      <h5 class="card-header">Attachments</h5>
      <div class="card-body">
        <div class="attachments-list">
          <LoadingIndicator v-if="loading" centered />
          <div v-if="!loading">
            <div v-for="item in attachments" :key="item.id">
              <div v-if="isImage(item.filename)"><img :src="item.publicUrl" class="img" /></div>
              <div v-if="isPdf(item.filename)">
                <object :data="item.publicUrl" type="application/pdf" width="100%" height="500px">
                  <p>Unable to display PDF file. <a :href="item.publicUrl">Download</a> instead.</p>
                </object>
              </div>
            </div>
            <table v-if="attachments.length > 0" class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Filename</th>
                  <th>Size</th>
                  <th>User</th>
                  <th width="200">Created at</th>
                  <th width="200"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in attachments" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.filename }}</td>
                  <td>{{ formatFileSize(item.filesize) }}</td>
                  <td>{{ item.user ? item.user.name : 'System' }}</td>
                  <td>{{ item.createdAt | formatDateTime }}</td>
                  <td>
                    <button class="btn btn-outline-secondary btn-sm mr-3" @click="fetchAttachment(item)">download</button>
                    <button class="btn btn-outline-danger btn-sm" @click="deleteAttachment(item)">delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-info">No attachments have been added yet.</div>
          </div>

          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form class="mt-4" @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider name="Name" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group id="example-input-group-1" label="Name" label-for="example-input-1">
                  <b-form-input
                    id="example-input-1"
                    name="example-input-1"
                    v-model="attachment.name"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider name="Food" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group id="example-input-group-2" label="File" label-for="example-input-2">
                  <b-file id="example-input-2" name="example-input-2" v-model="attachment.file" :state="getValidationState(validationContext)" aria-describedby="input-2-live-feedback" />
                  <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <LoadingIndicator v-if="saving" /><b-btn variant="primary" :disabled="saving" type="submit">{{ attachment.id ? 'Update' : 'Create' }} attachment</b-btn>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';

export default {
  name: 'CarrierAttachments',
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      attachments: [],
      attachment: {
        id: null,
        name: null,
        file: null,
      },
      loading: false,
      saving: false,
      deleting: false,
    };
  },
  methods: {
    isImage(filename){
      let ext = filename.substring(filename.lastIndexOf('.')+1, filename.length).toLowerCase() || filename.toLowerCase();
      if(['png','jpg','jpeg'].includes(ext)) return true;
      return false;
    },
    isPdf(filename){
      let ext = filename.substring(filename.lastIndexOf('.')+1, filename.length).toLowerCase() || filename.toLowerCase();
      if(['pdf'].includes(ext)) return true;
      return false;
    },
    onSubmit() {
      this.saveAttachment();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    formatFileSize(x = 0, dec = 2) {
      if (x < 1000) return x + ' Bytes';
      x = ('0'.repeat(((x += '').length * 2) % 3) + x).match(/.{3}/g);
      return Number(x[0]) + '.' + x[1].substring(0, dec) + ' ' + '  kMGTPEZY'[x.length] + 'B';
    },
    async saveAttachment() {
      this.saving = true;

      const data = new FormData();
      data.append('file', this.attachment.file, this.attachment.file.name);
      if (this.attachment.name) data.append('name', this.attachment.name);

      const response = await ApiClient.carriers.attachments.post({
        carrierId: this.carrier.id,
        body: data,
      });
      this.saving = false;
      if (response.status === 200) {
        const newAttachment = response.data.data;
        this.attachments.push(newAttachment);
      }
    },
    async deleteAttachment(attachment) {
      this.deleting = true;
      await ApiClient.carriers.attachments.delete({
        carrierId: this.carrier.id,
        id: attachment.id,
      });
      this.deleting = false;
      this.attachments = this.attachments.filter(x => x.id != attachment.id);
    },
    async fetchAttachment(attachment) {
      let response = await ApiClient.carriers.attachments.get({
        carrierId: this.carrier.id,
        id: attachment.id,
        blobRespone: true,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.filename);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
    },
    async showAttachment(attachment) {
      const link = document.createElement('a');
      link.href = attachment.publicUrl;
      link.setAttribute('target', '_blank');
      link.click();
    },
  },
  watch: {
    carrier: {
      immediate: true,
      async handler(carrier) {
        this.loading = true;
        const response = await ApiClient.carriers.attachments.get({ carrierId:carrier.id });
        this.loading = false;
        this.attachments = response.data.data;
      },
    },
  },
  mounted() {
    console.log("id",this.id);
  },
};
</script>

<style lang="scss" scoped>
.img {
  max-width: 100%;
}
</style>
