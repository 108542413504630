<template>
  <div>
    <h5>Cancellation Report</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit">
        <b-form-radio v-model="periodType" name="periodType" value="list">Time period</b-form-radio>
        <b-select class="custom-select ml-3" v-if="periodType == 'list'" v-model="timePeriod" :options="timeOptions" />
        <span class="ml-3">OR</span>
        <b-form-radio v-model="periodType" name="periodType" value="custom" class="ml-3">Custom date range</b-form-radio>
        <flat-pickr class="form-control ml-3" v-if="periodType != 'list'" v-model="selectedDates" :config="fltConfig" />


        <button class="btn btn-primary ml-3">Show Report</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <span v-if="empty">No records found</span>

    <b-card class="mb-4" v-if="!loading && items.length">
      <b-table striped hover :items="items" :fields="fields" caption-top>
        <template #table-caption>
          <span v-if="form.createdStart">Created from {{ form.createdStart }} till {{ form.createdEnd }}</span>
        </template>


        <template #cell(userId)="data">
          <b-link v-if="data.value" :to="userLink(data.value)" target="_blank">{{ data.item.user ? data.item.user.name : '' }}</b-link>
        </template>

        <template #cell(canceledCount)="data">
          {{ data.value }} <span class="ml-1">{{ p(data.value, data.item.ordersCount) }}</span>
        </template>

        <template #cell(details)="data">
          <table v-if="data.value.length">
            <tbody>
              <tr v-for="item in data.value" :key="item.cancelReasonId">
                <td>{{ item.cancelReasonName }}</td>
                <td>{{ item.cancelReasonCount }} <span class="ml-1">{{ p(item.cancelReasonCount, data.item.canceledCount) }}</span></td>
              </tr>
            </tbody>
          </table>
        </template>

        <template #custom-foot="data">
          <b-tr>
            <b-th v-for="field in data.fields" :key="field.key">{{ field.key == 'userId' ? 'Totals' : totals[field.key] }}</b-th>
          </b-tr>
        </template>

        <template #cell(show_details)="row">
          <b-btn size="xs" variant="secondary" @click.prevent="toggleDetails(row)" v-if="row.item.canceledCount > 0" >
            {{ row.detailsShowing ? 'Hide' : 'Zoom' }}
          </b-btn>
        </template>
        <template #row-details="row">
          <b-card>
            <LoadingIndicator v-if="row.item.loading" centered />
            <b-table v-if="row.item.rows.length" striped hover :items="row.item.rows" :fields="detailsFields" class="mb-0">
              <template #cell(id)="data">
                <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
              </template>
            </b-table>
          </b-card>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';

export default {
  name: 'ReportCancellation',
  data() {
    return {
      loading: false,
      empty: false,
      items: [],
      totals: {},
      periodType: 'list',
      timePeriod: 1,
      selectedDates: null,
      form: {
        createdStart: null,
        createdEnd: null,
      },
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
      },
      timeOptions: [
        { text: 'Current month', value: 1 },
        { text: 'Last month', value: 2 },
        { text: 'Current quarter', value: 3 },
        { text: 'Last quarter', value: 4 },
        { text: 'Current year', value: 5 },
        { text: 'All time', value: 6 },
      ],
      fields: [
        {
          key: 'userId',
          label: 'User',
          sortable: false,
        },
        {
          key: 'ordersCount',
          label: 'Orders',
          sortable: true,
        },
        {
          key: 'canceledCount',
          label: 'Cancelled',
          sortable: true,
        },
        {
          key: 'details',
          label: 'Details',
          sortable: true,
        },
        {
          key: 'show_details',
          label: '',
          sortable: false,
        },
      ],
      detailsFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'source',
          label: 'Source',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'tarrifs',
          label: 'Tarrif',
          sortable: true,
        },
        {
          key: 'deposit',
          label: 'Deposit',
          sortable: true,
        },
        {
          key: 'carrierPay',
          label: 'Carrier pay',
          sortable: true,
        },
        {
          key: 'signedAt',
          label: 'Dispatched At',
          sortable: true,
        },
      ],
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
    flatPickr,
  },
  methods: {
    p(x, y) {
      if (!y || y/1 < 1) return '';
      if (!x || x/1 < 1) return '';
      return '(' + Math.round((x * 100) / y) + '%)';
    },
    async submit(e) {
      e.preventDefault();
      this.fetchCancellationReport();
    },
    toggleDetails(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        this.fetchCancellationReportDetails(row.item);
      }
    },
    async fetchCancellationReport() {
      this.prepareDates();
      this.loading = true;
      const response = await ApiClient.reports.cancellation({ params: this.form });
      this.loading = false;

      this.items = response.data.data.rows.map(x => {
        x.rows = [];
        x.loading = false;
        return x;
      });
      this.totals = response.data.data.totals;
      this.empty = !this.items.length;
    },
    async fetchCancellationReportDetails(item) {
      this.prepareDates();
      item.loading = true;
      let params = Object.assign({ userId: item.userId }, this.form);
      const response = await ApiClient.reports.cancellationDetails({ params });
      item.loading = false;
      item.rows = response.data.data.rows;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    orderLink(item) {
      if (item.leadTypeId == 3) return { name: 'OrderDetails', params: { id: item.id } };
      if (item.leadTypeId == 2) return { name: 'QuoteDetails', params: { id: item.id } };
      if (item.leadTypeId == 1) return { name: 'LeadDetails', params: { id: item.id } };
      return null;
    },
    prepareDates() {
      let parts = [];
      if (this.periodType == 'list') {
        if (this.timePeriod == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDates) return;
        parts = this.selectedDates.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }

      this.form.createdStart = parts[0];
      this.form.createdEnd = parts[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger a {
  color: inherit;
}
</style>
