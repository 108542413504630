<template>
  <div class="card mb-4">
    <h5 class="card-header">Logs</h5>
    <div class="card-body">
      <LoadingIndicator v-if="logsLoading" centered />

      <table class="table" v-if="!logsLoading && items.length">
        <thead class="table">
          <tr>
            <th scope="col">Order Id</th>
            <th scope="col">Event</th>
            <th scope="col">Ip</th>
            <th scope="col">Country</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="px-1">
              <router-link v-if="item.leadId" :to="{ name: 'OrderDetails', params: { id: item.leadId } }">#{{ item.leadId }}</router-link>
            </td>
            <td>{{ item.event }}</td>
            <td>{{ item.ip }}</td>
            <td>{{ item.countryName }}</td>
            <td>{{ item.createdAt | formatDateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'CarrierOrders',
  data() {
    return {
      logsLoading: false,
      items: [],
    };
  },
  mixins: [permissionsMixin],
  components: {
    LoadingIndicator,
  },
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    this.fetchCarrierLogs();
  },
  methods: {
    async fetchCarrierLogs() {
      this.logsLoading = true;
      const response = await ApiClient.carriers.logs({ id: this.carrier.id });
      this.items = response.data.data;
      this.logsLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
