<template>
  <div class="card mb-4">
    <h5 class="card-header">History</h5>
    <div class="card-body">
      <LoadingIndicator v-if="historyLoading" centered />

      <!-- Table -->
      <b-table v-if="!historyLoading" :items="items" :fields="getTableFields(fields)" striped bordered responsive class="card-table">
        <template #cell(userId)="{ value }">
          <b-link :to="userLink(value)"> #{{ value }} </b-link>
        </template>

        <template #cell(userName)="{ value }">
          {{ value }}
        </template>

        <template #cell(key)="{ value }">
          {{
            value
              .split('.')
              .map(item => item.charAt(0).toUpperCase() + item.slice(1))
              .join(' ')
          }}
        </template>

        <template #cell(oldValue)="{ value }">
          {{ value }}
        </template>

        <template #cell(newValue)="{ value }">
          {{ value }}
        </template>

        <template #cell(createdAt)="{ value }">
          <div>{{ value | formatDateTime }}</div>
        </template>
      </b-table>
      <!-- / Table -->
    </div>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'CarrierHistory',
  data() {
    return {
      historyLoading: false,
      items: [],
      fields: [{ key: 'userId' }, { key: 'userName' }, { key: 'key', label: 'Field' }, { key: 'oldValue' }, { key: 'newValue' }, { key: 'createdAt' }],
      viewPerm: null,
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
  },
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    this.fetchCarrierHistory();
  },
  methods: {
    userLink(id) {
      if (this.viewPerm === null) this.viewPerm = this.tokenCan('users');
      return this.viewPerm && id ? { path: '/user/details', query: { id } } : {};
    },
    async fetchCarrierHistory() {
      this.historyLoading = true;
      const response = await ApiClient.carriers.history({ id: this.carrier.id });
      this.items = response.data.data;
      this.historyLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
