<template>
  <div>
    <div class="grid--three-column">
      <ValidatedInput
        rules="required"
        label="Card Number"
        mask="cc"
        id="cardNumber"
        :value="formFields.cardNumber"
        :errors="errorResolver('cardNumber')"
        @input="newValue => handleInputChange({ field: 'cardNumber', newValue })"
      />
      <ValidatedInput
        rules="required"
        label="Expiry Date"
        id="expirationDate"
        mask="exp"
        :value="formFields.expirationDate"
        :errors="errorResolver('expirationDate')"
        @input="newValue => handleInputChange({ field: 'expirationDate', newValue })"
      />
      <ValidatedInput
        rules="required"
        label="CVV"
        mask="cvv"
        id="cvv"
        :value="formFields.cardCode"
        :errors="errorResolver('cardCode')"
        @input="newValue => handleInputChange({ field: 'cardCode', newValue })"
      />
    </div>
    <div class="grid--two-column">
      <ValidatedInput
        rules="required"
        label="Name on Card"
        id="cardholderName"
        :value="formFields.cardholderName"
        :errors="errorResolver('cardholderName')"
        @input="newValue => handleInputChange({ field: 'cardholderName', newValue })"
      />
      <ValidatedInput
        rules="required"
        label="Address"
        id="address"
        :value="formFields.address"
        :errors="errorResolver('address')"
        @input="newValue => handleInputChange({ field: 'address', newValue })"
      />
    </div>
    <div class="grid--three-column">
      <ValidatedInput
        rules="required"
        label="City"
        id="city"
        :value="formFields.city"
        :errors="errorResolver('city')"
        @input="newValue => handleInputChange({ field: 'city', newValue })"
      />
      <ValidatedInput
        rules="required"
        label="Zip"
        id="zip"
        :value="formFields.zip"
        :errors="errorResolver('zip')"
        @input="newValue => handleInputChange({ field: 'zip', newValue })"
      />
      <ValidatedInput
        rules="required"
        label="Country"
        id="country"
        :value="formFields.country || 'US'"
        :errors="errorResolver('country')"
        @input="newValue => handleInputChange({ field: 'country', newValue })"
      />
    </div>
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';

export default {
  name: 'FormFieldsCreditCard',
  components: {
    ValidatedInput,
  },
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
  },
  methods: {
    handleInputChange({ field, newValue }) {
      const newFormFields = Object.assign({}, this.formFields);
      newFormFields[field] = newValue;
      this.$emit('input', newFormFields);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
</style>
