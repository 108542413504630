export default {
  methods: {
    hasPermission(value) {
      const permissions = this.$store.state.auth.permissions;
      return permissions.admin || permissions[value];
    },
    isCurrentUser(id) {
      return this.$store.state.auth.user.id == id;
    },
    tokenCan(x) {
      return this.hasPermission(x);
    },
    tokenCanAny(x) {
      for (let y of x) {
        if (this.hasPermission(y)) return true;
      }
    },
    tokenCanAll(x) {
      for (let y of x) {
        if (!this.hasPermission(y)) return false;
      }
      return true;
    },
  },
};
