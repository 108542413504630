import api from '@/api';
import axios from 'axios';

const state = {
  user: null,
  permissions: {},
};

const getters = {
  isLogged: state => Boolean(state.user),
  isAdmin: () => true,

  isMyUser: state => userId => {
    return userId && state.user.id == userId;
  },
  tokenCan: state => x => {
    return state.permissions[x] || state.permissions.admin;
  },
  tokenCanAny: (state, getters) => x => {
    for (let y of x) {
      if (getters.tokenCan(y)) return true;
    }
    return false;
  },
  tokenCanAll: (state, getters) => x => {
    for (let y of x) {
      if (!getters.tokenCan(y)) return false;
    }
    return true;
  },
};

const actions = {
  login({ commit }, credentials) {
    return api.users.login.post(null, credentials).then(({ data }) => {
      commit('setUserData', data.data);
      commit('leads/setUserFilter', data.data.user.id, { root: true });
    });
  },
  logout({ commit }) {
    return api.users.logout
      .post(null, null)
      .then(() => {
        commit('clearUserData');
      })
      .catch(() => {
        commit('clearUserData');
      });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserData(state, authData) {
    state.user = authData.user;
    const permissions = Object.create(null);
    for (let x of authData.permissions) permissions[x] = true;
    state.permissions = permissions;
    localStorage.setItem('user', JSON.stringify(authData));
    if(authData.token){
      axios.defaults.headers.common.Authorization = `Bearer ${authData.token}`;
    }
  },
  clearUserData(state) {
    state.user = null;
    localStorage.removeItem('user');
    location.reload();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
