export const paymentTypes = [
  { value: 1, label: 'Credit card' },
  { value: 2, label: 'PayPal' },
  { value: 3, label: 'Cashiers check' },
  { value: 4, label: 'Money order' },
  { value: 5, label: 'Company check' },
  { value: 6, label: 'Comcheck' },
  { value: 7, label: 'Electronic transfer' },
  { value: 8, label: 'Cash' },
  { value: 9, label: 'Personal check' },
  { value: 10, label: 'Other' },
];

export default {
  data() {
    return {
      paymentTypes,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      return paymentTypes.find(pt => pt.value == value).label;
    },
  },
};
