<template>
  <div>
    <div class="grid--two-column">
      <ValidatedInput
        mask="price"
        rules="required|min:2"
        label="Amount"
        id="amount"
        :value="formFields.amount"
        :errors="errorResolver('amount')"
        @input="newValue => handleInputChange({ field: 'amount', newValue })"
      />
      <div class="form-grup">
        <label for="cc">Payment type</label>
        <input id="cc" type="text" class="form-control" readonly value="Credit Card" />
      </div>
    </div>
    <ValidatedInput
      type="textarea"
      label="Notes"
      id="notes"
      :value="formFields.notes"
      :errors="errorResolver('notes')"
      @input="newValue => handleInputChange({ field: 'notes', newValue })"
    />
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';
import paymentDirectionsMixin from '@/mixins/paymentDirections';
import paymentTypesMixin from '@/mixins/paymentTypes';

export default {
  name: 'FormFieldsProfilePayment',
  components: {
    ValidatedInput,
  },
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
  },
  mixins: [paymentDirectionsMixin, paymentTypesMixin],
  methods: {
    handleInputChange({ field, newValue }) {
      const newFormFields = Object.assign({}, this.formFields);
      newFormFields[field] = newValue;
      this.$emit('input', newFormFields);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
</style>
