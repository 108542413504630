<template>
  <div>
    <OrderOffers :order="order" :errors="errors" @refresh="$emit('refresh')" />
    <div class="card mb-4">
      <h5 class="card-header">Manual Dispatch</h5>
      <div class="card-body">
        <ValidationObserver ref="observer">
          <form @submit.prevent="submit()">
            <CarrierSelector :balance-fields="order" v-model="selectedCarrierId" :preselected="carrierId" />
            <FormFieldsErrors :errors="errors" />

            <LoadingIndicator v-if="requestInProgress" />
            <div v-else class="mb-4">

              <ValidatedSelect
                rules="required"
                label="Dispatch Method"
                id="dispatchMethod"
                v-model="order.dispatchMethodId"
                :options="dispatchMethods"
              />
              <button v-if="!order.carrierId" :disabled="saving" class="btn btn-primary" type="submit">Dispatch</button>
            </div>
          </form>
        </ValidationObserver>
        <button class="btn btn-primary mb-4" @click="updateBalance">Update Order</button>
        <div v-if="order.carrierId">
          <h2 class="mt-2">Dispatched to {{ order.carrier.contact ? order.carrier.contact.company : 'Carrier information missing!' }}</h2>
          <p>Signed at: <span v-if="order.signedAt">{{order.signedAt | formatDateTime}}</span><span v-if="!order.signedAt">Not Signed</span></p>
          <div v-if="order.signedAt">
            <LoadingIndicator v-if="resendRequestInProgress" />
            <div class="grid--two-column" v-else>
              <ValidatedInput :id="toAddress" v-model="toAddress" label="Email" :errors="errors.toAddress" />
              <button class="btn btn-info" @click="updateBalanceAndSend">Resend dispatch sheet</button>
            </div>
          </div>
          <LoadingIndicator v-if="requestInProgress" />
          <button v-else class="btn btn-danger my-3" @click="revokeOrderDispatch">Revoke dispatch</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import ValidatedInput from '@/components/shared/ValidatedInput';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import CarrierSelector from '@/components/shared/CarrierSelector';
import FormFieldsErrors from '@/components/shared/formFields/FormFieldsErrors';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import { apiRequest } from '@/api/apiRequest';
import OrderOffers from '@/components/shared/OrderOffers';
import dispatchMethodsMixin from '@/mixins/dispatchMethods';


export default {
  name: 'OrderDispatch',
  data() {
    return {
      errors: {},
      toAddress: this.order.carrierId ? this.order.carrier.contact.email : '',
      selectedCarrierId:  null,
      requestInProgress: false,
      resendRequestInProgress: false,
      saving: false,
      balanceFields: null,
      dispatchMethodOptions: [
        { value: null, text: '-' },
      ],
    };
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  mixins: [dispatchMethodsMixin],
  components: { CarrierSelector, FormFieldsErrors, LoadingIndicator, ValidatedInput, ValidatedSelect, OrderOffers },
  computed: {
    carrierId(){
      return this.$route.query.carrierId ? parseInt(this.$route.query.carrierId) : null;
    }
  },
  methods: {
    async submit() {
      this.saving = true;
      const isValid = await this.$refs.observer.validate();
      if (isValid && this.selectedCarrierId) {
        const requestLeadParams = this.order;
        const response = await ApiClient.leads.put({ id: this.order.id, body: requestLeadParams });
        if (response.status === 200) {
          this.dispatchOrder();
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
      this.saving = false;
    },
    async updateBalanceAndSend() {
      let res = await this.updateBalance();
      if (res && this.order.signedAt) {
        this.resendDispatchSheet();
      }
    },
    async updateBalance() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const requestLeadParams = this.order;
        const response = await ApiClient.leads.put({ id: this.order.id, body: requestLeadParams });
        if (response.status === 200) {
          this.$emit('refresh');
          return true;
        }
      } else {
        this.handleSendErrorToast({ message: 'Required field(s) missing.' });
      }
      return false;
    },
    handleSendErrorToast({ message }) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      });
    },
    async dispatchOrder() {
      if (this.requestInProgress) return;
      this.requestInProgress = true;
      const response = await ApiClient.leads.dispatch.post({
        leadId: this.order.id,
        body: { 
          carrierId: this.selectedCarrierId,
          dispatchMethodId: this.order.dispatchMethodId
        },
      });
      this.requestInProgress = false;

      if (response.status === 200) {
        this.errors = {};
        this.$emit('refresh');
      } else if (response.status === 400) {
        this.errors = { generalError: response.data.message };
      } else if (response.status === 422) {
        this.errors = response.data.errors;
      }
    },
    async revokeOrderDispatch() {
      if (this.requestInProgress) return;
      this.requestInProgress = true;
      const response = await ApiClient.leads.dispatch.delete({ leadId: this.order.id });
      this.requestInProgress = false;

      if (response.status === 200) {
        this.errors = {};
        this.selectedCarrierId = null;
        this.$emit('refresh');
      } else if (response.status === 400) {
        this.errors = { generalError: response.data.message };
      }
    },
    async resendDispatchSheet() {
      if (this.resendRequestInProgress) return;
      this.resendRequestInProgress = true;
      const response = await ApiClient.leads.dispatch.resend({ leadId: this.order.id, body: { toAddress: this.toAddress } });
      this.resendRequestInProgress = false;

      if (response.status === 200) {
        this.errors = {};
      } else if (response.status === 400) {
        this.errors = { generalError: response.data.message };
      }
    },
    defaultPaymentOptions() {
      if (this.balanceFields.balanceTermStart == null && this.balanceFields.balanceTerm == null && this.balanceFields.balancePaymentMethod == null) {
        if (this.balanceFields.carrierOwesCompany > 0) {
          this.order.balanceTermStartId = 2;
          this.order.balanceTermId = 1;
          this.order.balancePaymentMethodId = 2;
        }
        if (this.balanceFields.companyOwesCarrier > 0) {
          this.order.balanceTermStartId = 3;
          this.order.balanceTermId = 3;
          this.order.balancePaymentMethodId = 3;
        }
      }

      if (this.order.dispatchMethodId == null){
        this.order.dispatchMethodId = 1;
      }
    },
  },
  created() {
    this.dispatchMethodOptions = this.dispatchMethodOptions.concat(this.dispatchMethods.map(x => {x.text = x.label; return x}));
  },
  watch: {
    order: {
      immediate: true,
      async handler({ id }) {
        const response = await apiRequest({ path: `/leads/${id}/balance` });
        this.balanceFields = response.data.data;
        this.defaultPaymentOptions();
      },
    },
  },
};
</script>
