import LayoutBlank from '@/layout/LayoutBlank';

export default [
  {
    // Blank layout
    path: '/auth',
    component: LayoutBlank,
    meta: {
      auth: false,
    },
    children: [
      {
        path: 'login',
        component: () => import('@/components/pages/auth/AuthenticationLoginV2'),
      },
    ],
  },
];
