<template>
  <li class="nav-item">
    <router-link :class="classes" :to="destination">{{ label }}</router-link>
  </li>
</template>

<script>
export default {
  name: 'TabLink',
  props: {
    routeName: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    label: { type: String, required: true },
  },
  computed: {
    destination() {
      return { name: this.routeName, params: { id: this.id } };
    },
    classes() {
      return { 'nav-link': true, active: this.$route.name === this.routeName };
    },
  },
};
</script>

<style lang="scss" scoped></style>
