import { types, deletedKeys } from './variables';

export function handleCatchWindowClose(e) {
  e.preventDefault();
  e.returnValue = ''; // for Chrome
}

export function getParsedTemplateCodes({ arr, keys }) {
  return arr.flatMap(item =>
    keys.map(key => ({
      id: Math.floor(Math.random() * 10000) + 14,
      value: `[${item}.${key}]`,
      text: `${getCapitalize(item)} ${getCapitalize(key)}`,
    }))
  );
}

export function getCapitalize(text) {
  const textArr = text.split('_');
  const resultArr = textArr.map(item => `${item.charAt(0).toUpperCase() + item.slice(1)}`);

  return resultArr.join(' ');
}

export function getParsedField({ value, key }) {
  return value
    ? value[key]
        .split(';')
        .filter(item => item)
        .map(item => ({ [key]: item }))
    : [];
}

export function getSerializedField({ value, key }) {
  return value
    ? value
        .map(item => item[key])
        .filter(item => item)
        .join(';')
    : null;
}

export function getStaticType(key, id) {
  return types[key].find(transport => transport.id === id);
}

export function getCleanObjectFromKeys(obj, keys = [], isCleanedNullish = false) {
  if (!obj) return null;
  else if (typeof obj !== 'object') return obj;

  const allKeys = keys.length > 0 ? keys : deletedKeys;
  const objKeys = Object.keys(obj);

  return objKeys.reduce((acc, key) => {
    if (allKeys.includes(key) || (isCleanedNullish && !obj[key])) {
      return acc;
    } else {
      return { ...acc, [key]: obj[key] };
    }
  }, {});
}

// https://stackoverflow.com/questions/52367849/remove-empty-null-values-from-nested-object-es6-clean-nested-objects
export function getCleanObject(object) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      getCleanObject(v);
    }
    if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v.length === 0) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else if (!(v instanceof Date)) {
        delete object[k];
      }
    }
  });
  return object;
}

// regexs
export const getDecimalRegex = max => new RegExp(`^[+-]?\\d{1,${max}}(\\.\\d{1,2})?$`);
