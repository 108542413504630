<template>
  <div class="card mb-4">
    <h5 class="card-header">Reclaims</h5>
    <div class="card-body">
      <LoadingIndicator v-if="loading" centered />
      <span v-if="!loading && !items.length">No reclaims found</span>
      <table class="table" v-if="!loading && items.length">
        <thead class="table">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Type</th>
            <th scope="col">User</th>
            <th scope="col">Reclaimed at</th>
            <th scope="col">Note</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <b-link :to="orderLink(item)" target="_blank">#{{ item.leadId }}</b-link>
            </td>
            <td>
              <span v-if="item.lead.leadTypeId == 1">Lead</span>
              <span v-if="item.lead.leadTypeId == 2">Quote</span>
              <span v-if="item.lead.leadTypeId == 3">Order</span>
            </td>
            <td>
              <b-link :to="userLink(item.userId)" target="_blank">{{ item.user ? item.user.name : '-' }}</b-link>
            </td>
            <td>{{ item.createdAt | formatDateTime }}</td>
            <td><b-input v-model="item.note" type="text" class="mr-3 sm" size="sm" /></td>
            <td>
              <b-btn size="xs" variant="primary" @click.prevent="submit(item, 1)" class="mr-3">Approve</b-btn>
              <b-btn size="xs" variant="danger" @click.prevent="submit(item, 0)">Decline</b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';

export default {
  name: 'Reclaims',
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  mixins: [permissionsMixin],
  components: {
    LoadingIndicator,
  },
  async created() {
    this.fetchReclaims();
  },
  methods: {
    async fetchReclaims() {
      this.loading = true;
      const response = await ApiClient.leadReclaims.get({});
      console.log(response.data);
      this.items = response.data.data;
      this.loading = false;
    },
    userLink(id) {
      return id ? { path: '/user/details', query: { id } } : {};
    },
    orderLink(item) {
      
      if (item.lead.leadTypeId == 3) return { name: 'OrderDetails', params: { id: item.leadId } };
      if (item.lead.leadTypeId == 2) return { name: 'QuoteDetails', params: { id: item.leadId } };
      if (item.lead.leadTypeId == 1) return { name: 'LeadDetails', params: { id: item.leadId } };
      return null;
    },
    async submit(item, isApproved) {
      let form = {
        isApproved,
        note: item.note,
      };
      let response = await ApiClient.leadReclaims.put({ id: item.id, body: form });
      if (response.status === 200) {
        this.items = this.items.filter(x => x.id != item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
