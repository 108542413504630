import LayoutCentered from '@/layout/LayoutCentered';

export default [
  {
    // Layout 2
    path: '/referrers',
    component: LayoutCentered,
    meta: {
      auth: true,
      requirePermission: 'admin',
    },
    children: [
      {
        path: '',
        name: 'Referrers',
        component: () => import('@/components/pages/referrers/ReferrersList'),
      },
      {
        path: 'create',
        component: () => import('@/components/pages/referrers/ReferrersCreate'),
      },
      {
        path: ':id/edit',
        component: () => import('@/components/pages/referrers/ReferrersEdit'),
      },
    ],
  },
];
